<template>
	<div>
		<v-card v-for="(item, index) in ListInfo" :key="`1-${index}`">
			<v-fab-transition>
				<v-btn
					color="#262626"
					elevation="0"
					icon
					fab
					small
					absolute
					top
					right
					@click.stop="
						$store.commit('setDialogInfo_i', false);
						$store.commit('setCount', 1);
						closeModal();
					"
				>
					<v-icon color="#4c4c4c">mdi-close-circle</v-icon>
				</v-btn>
			</v-fab-transition>
			<v-carousel
				v-model="modal"
				:height="gridImageHeight"
				hide-delimiters
				hide-delimiter-background
			>
				<div v-for="(item, index) in ListInfo" :key="`2-${index}`">
					<v-carousel-item v-if="item.item_thumbnail_0">
						<v-row class="fill-height" align="center" justify="center">
							<v-img
								:height="gridImageHeight"
								contain
								v-bind:src="item.item_thumbnail_0"
								class="mt-5"
							></v-img> </v-row
					></v-carousel-item>
				</div>
				<div v-for="(item, index) in ListInfo" :key="`3-${index}`">
					<v-carousel-item v-if="item.item_thumbnail_1"
						><v-row class="fill-height" align="center" justify="center">
							<v-img
								:height="gridImageHeight"
								contain
								v-bind:src="item.item_thumbnail_1"
								class="mt-5"
							></v-img></v-row
					></v-carousel-item>
				</div>
				<div v-for="(item, index) in ListInfo" :key="`4-${index}`">
					<v-carousel-item v-if="item.item_thumbnail_2"
						><v-row class="fill-height" align="center" justify="center">
							<v-img
								:height="gridImageHeight"
								contain
								v-bind:src="item.item_thumbnail_2"
								class="mt-5"
							></v-img></v-row
					></v-carousel-item>
				</div>
			</v-carousel>
			<v-divider class="mt-5"></v-divider>
			<v-card-text>
				<v-card-title class="text-h5 text-md-h4 font-weight-medium"
					>{{ item.model }}
					<span v-if="item.size">&nbsp;{{ item.size }}m</span></v-card-title
				>
				<v-card-subtitle
					v-if="item.manufacturer"
					class="mt-0 mb-0 pt-0 pb-0 subtitle-2 text-md-subtitle-1 font-weight-regular"
					>Maker / {{ item.manufacturer }}</v-card-subtitle
				>
				<v-card-subtitle
					v-if="item.model_number"
					class="mt-0 mb-0 pt-0 pb-0 subtitle-2 text-md-subtitle-1 font-weight-regular"
					>Model number / {{ item.model_number }}</v-card-subtitle
				>
				<v-card-subtitle
					v-if="cableLabelHideFunc"
					class="mt-0 mb-0 pt-0 pb-0 subtitle-2 text-md-subtitle-1 font-weight-regular"
					>Cable length / {{ item.size }}m</v-card-subtitle
				>
				<div v-if="item.description.length">
					<v-card-subtitle
						class="mt-5 mb-0 pt-0 pb-0 subtitle-2 text-md-subtitle-1 font-weight-regular"
						>付属品</v-card-subtitle
					>
					<v-chip-group class="pl-4" column>
						<v-chip
							v-for="item in item.description"
							:key="item.description"
							class="subtitle-2 text-md-subtitle-1 font-weight-regular"
						>
							{{ item }}
						</v-chip>
					</v-chip-group>
				</div>
			</v-card-text>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header>
						<v-card-subtitle class="font-weight-regular pa-1"
							>在庫状況カレンダー</v-card-subtitle
						>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-sheet>
							<v-toolbar flat class="pa-0 ma-0 mb-5">
								<v-btn
									fab
									text
									small
									color="grey darken-2"
									@click="$refs.calendar[index].prev(), prev()"
								>
									<v-icon small> mdi-chevron-left </v-icon>
								</v-btn>
								<v-spacer></v-spacer>
								<!-- <v-btn outlined small class="ma-4" @click="setToday">
									Today
								</v-btn> -->
								<v-toolbar-title
									class="subtitle-2 text-md-subtitle-1 font-weight-regular"
									>{{ datetitle }}</v-toolbar-title
								>
								<v-spacer></v-spacer>
								<v-btn
									fab
									text
									small
									color="grey darken-2"
									@click="$refs.calendar[index].next(), next()"
								>
									<v-icon small> mdi-chevron-right </v-icon>
								</v-btn>
							</v-toolbar>
						</v-sheet>
						<div class="mb-5">
							<v-sheet :height="calendarHeight">
								<v-calendar
									ref="calendar"
									v-model="value"
									type="month"
									locale="ja-jp"
									class="ma-0 pa-0"
									color="white"
									:day-format="
										(timestamp) => new Date(timestamp.date).getDate()
									"
									:month-format="
										(timestamp) =>
											new Date(timestamp.date).getMonth() + 1 + ' /'
									"
									:events="resultmonthCal"
									:event-height="20"
									@click:event="showEvent"
								>
									<template v-slot:event="{ event, start }">
										<div>
											<v-menu
												v-model="selectedOpen[event.start]"
												:close-on-content-click="false"
												:activator="selectedElement"
												offset-x
												max-width="400"
												min-width="0"
												style="background-color: none"
											>
												<v-simple-table v-if="event.rentUser">
													<template v-slot:default>
														<thead>
															<tr>
																<th class="text-left">借りている人</th>
																<th class="text-left">個数</th>
															</tr>
														</thead>
														<tbody>
															<tr
																v-for="rentUser in event.rentUser"
																:key="rentUser.index"
															>
																<td>{{ rentUser.user }}</td>
																<td>{{ rentUser.used }}</td>
															</tr>
														</tbody>
													</template>
												</v-simple-table>
												<template v-slot:activator="{ on, attrs }">
													<v-card
														v-if="start"
														color="grey lighten-2"
														elevation="0"
														outlined
														class="transparentCard ma-0 pa-0"
														v-bind="attrs"
														v-on="on"
													>
														<v-card-text
															class="pa-0"
															v-html="event.name"
															align="center"
															style="font-size: 1.5em"
														></v-card-text>
													</v-card>
												</template>
											</v-menu>
										</div>

										<!-- <v-card
											v-if="start"
											color="grey lighten-2"
											elevation="0"
											outlined
											class="transparentCard ma-0 pa-0"
										>
											<v-card-text
												class="pa-0"
												v-html="event.name"
												align="center"
												style="font-size: 1.5em;"
											></v-card-text>
										</v-card> -->
									</template>
								</v-calendar>
							</v-sheet>
						</div>
						<v-row>
							<v-col cols="12" md="4" class="text-center">
								<v-btn
									color="transparent"
									elevation="0"
									v-on:click="decrement(item)"
									>-</v-btn
								>
								<span class="countNum">{{ count }}</span>
								<v-btn
									color="transparent"
									elevation="0"
									v-on:click="increment(item)"
									>+</v-btn
								>
							</v-col>
							<v-col cols="12" md="8" class="text-center">
								<v-btn
									color="black"
									elevation="0"
									class="addBtn rounded-0"
									block
									@click="
										addToList(item);
										$emit('hideModal');
									"
									>Add to List</v-btn
								>
							</v-col>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card>
	</div>
</template>
<script>
	import firebase from "firebase";
	import moment from "moment";
	import merge from "deepmerge";
	import _ from "lodash";
	export default {
		name: "Infomation",
		props: { ListInfo: Array },
		computed: {
			dateVanira() {
				return new Date(this.value);
			},
			addcartItems() {
				return this.$store.getters.addcartItems;
			},
			count() {
				return this.$store.getters.getCount;
			},
			cart() {
				return this.$store.getters.addcartItems;
			},
			datesSave() {
				return this.$store.getters.getDates;
			},
			//ログイン処理
			user() {
				return this.$store.getters.user;
			},
			userStatus() {
				// ログインするとtrue
				return this.$store.getters.isSignedIn;
			},
			//v-date-picker
			dateRangeText() {
				return this.dates.join(" ~ ");
			},
			descriptionSave() {
				const filter = Object.entries(this.ListInfo)
					.map((item) => item[1])
					.map((item) => item["description"]);
				return filter.toString().split(",");
			},
			datetitle() {
				return moment(this.value).format("yyyy年 M月"); // 表示用文字列を返す
			},
			datemoment() {
				return moment(this.value).format("YYYY-MM-DD");
			},
			dbCalendar() {
				return this.$store.getters.getCalendar;
			},
			dbCalendarFilter() {
				return Object.entries(this.dbCalendar)
					.map((item) => item[1])
					.filter((item) => item.id === this.props_list_info);
			},
			props_list_info() {
				return this.ListInfo[0].id;
			},
			dialogInfo() {
				return this.$store.getters.getDialogInfo;
			},
			dialogInfo_i() {
				return this.$store.getters.getDialogInfo_i;
			},
			exList() {
				return this.$store.getters.getexList;
			},
			Notification_AddItem() {
				return this.$store.getters.get_Notification_AddItem;
			},
			saveCalItemsFilter() {
				return Object.entries(this.saveCalItems).map((item) => item[1]);
			},
			cableLabelHideFunc() {
				var cableValue = this.ListInfo[0].size;
				if (cableValue == null || cableValue == undefined || !cableValue) {
					this.cableLabelFalse();
					return this.cableLabelHide;
				} else {
					this.cableLabelTrue();
					return this.cableLabelHide;
				}
			},
			routerPathName() {
				return this.ListInfo[0].model
					.replace(/[^a-zA-Z0-9ぁ-んーァ-ヶーァ-ヶー一-龠]/gi, "")
					.toLowerCase();
			},

			gridImageHeight() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 250;
					case "sm":
						return 250;
					case "md":
						return 250;
					case "lg":
						return 350;
					case "xl":
						return 350;
					default:
						return 250;
				}
			},

			carouselImageHeight() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 350;
					case "sm":
						return 350;
					case "md":
						return 350;
					case "lg":
						return 450;
					case "xl":
						return 450;
					default:
						return 350;
				}
			},

			calendarHeight() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 450;
					case "sm":
						return 450;
					case "md":
						return 450;
					case "lg":
						return 500;
					case "xl":
						return 500;
					default:
						return 450;
				}
			},
			localParam: {
				get: function () {
					return this.ListInfo;
				},
				set: function (ListInfo) {
					this.$emit("input", ListInfo); // おやでは @input に書いたメソッドがよばれる。引数にvalue
				},
			},
		},
		methods: {
			showEvent({ nativeEvent, event }) {
				const open = () => {
					this.selectedEvent = event;
					this.selectedElement = nativeEvent.target;
					requestAnimationFrame(() =>
						requestAnimationFrame(() => (this.selectedOpen = true))
					);
				};

				if (this.selectedOpen) {
					this.selectedOpen = false;
					requestAnimationFrame(() => requestAnimationFrame(() => open()));
				} else {
					open();
				}

				nativeEvent.stopPropagation();
			},
			addToList(item) {
				this.$store.commit("addToCart", item);
				this.$store.commit("rentDates", this.dates);
				this.$store.commit("setDialogInfo_i", false);
				this.$store.commit("setexList", 0);
				this.$store.commit("set_Notification_AddItem", true);
				this.$store.commit("setCount", 1);
				this.$router.push({
					path: "/rent",
				});
			},
			hideModal() {
				this.$emit("close");
			},
			submitDB() {
				var db = firebase.firestore();
				db.collection("rentals")
					.doc()
					.set({
						projects: this.projects,
						user: this.user.displayName,
						Items: firebase.firestore.FieldValue.arrayUnion(
							...this.addcartItems
						),
						status: false,
					})
					.then(() => {
						// console.log("成功");
						return (this.outputApply = "true");
					})
					.catch(() => {
						// error
						// console.log("失敗");
						return (this.outputApply = "false");
					});
			},
			increment(item) {
				if (this.count < item.stock) {
					this.$store.dispatch("increment");
				}
			},
			decrement() {
				if (this.count > 1) {
					this.$store.dispatch("decrement");
				}
			},

			prev() {
				// this.$refs.calendar.prev();

				const resultmonth = [];

				const resultday = this.datemoment.split("-");
				const startresult = resultday[0] + "-" + resultday[1] + "-01";
				const endresult = moment(
					resultday[0] + "-" + resultday[1],
					"YYYY-MM"
				).daysInMonth();

				var begin_month = moment(startresult).format("YYYY-MM-DD");
				var end_month = moment(
					resultday[0] + "-" + resultday[1] + "-" + endresult
				).format("YYYY-MM-DD");

				this.startresult = begin_month;
				this.endresult = end_month;

				for (
					var d = new Date(this.startresult);
					d <= new Date(this.endresult);
					d.setDate(d.getDate() + 1)
				) {
					let item = {
						model: this.ListInfo[0].model,
						id: "1",
						start: moment(d).format("YYYY-MM-DD"),
						end: moment(d).format("YYYY-MM-DD"),
						name: this.ListInfo[0].stock + "/" + this.ListInfo[0].stock,
						status: false,
					};
					resultmonth.push(item);
				}
				this.resultmonth = resultmonth;

				var resultmonthCal = _.merge(
					_.keyBy(this.resultmonth, "start"),
					_.keyBy(this.saveCalValues, "start")
				);

				this.resultmonthCal = _.values(resultmonthCal);

				// console.log(this.resultmonthCal);
				// this.resultmonthCal = _.merge(this.resultmonth, this.saveCalValues);

				// console.log(_.merge(this.resultmonth, this.saveCalValues));
				// console.log(_(merge(this.saveCalValues, this.resultmonth)));
			},
			next() {
				// this.$refs.calendar.next();

				const resultmonth = [];

				const resultday = this.datemoment.split("-");
				const startresult = resultday[0] + "-" + resultday[1] + "-01";
				const endresult = moment(
					resultday[0] + "-" + resultday[1],
					"YYYY-MM"
				).daysInMonth();

				var begin_month = moment(startresult).format("YYYY-MM-DD");
				var end_month = moment(
					resultday[0] + "-" + resultday[1] + "-" + endresult
				).format("YYYY-MM-DD");

				this.startresult = begin_month;
				this.endresult = end_month;

				for (
					var d = new Date(this.startresult);
					d <= new Date(this.endresult);
					d.setDate(d.getDate() + 1)
				) {
					let item = {
						model: this.ListInfo[0].model,
						start: moment(d).format("YYYY-MM-DD"),
						end: moment(d).format("YYYY-MM-DD"),
						name: this.ListInfo[0].stock + "/" + this.ListInfo[0].stock,
						status: false,
					};
					resultmonth.push(item);
				}
				this.resultmonth = resultmonth;

				var resultmonthCal = _.merge(
					_.keyBy(this.resultmonth, "start"),
					_.keyBy(this.saveCalValues, "start")
				);

				this.resultmonthCal = _.values(resultmonthCal);

				// console.log(this.resultmonthCal);
				// this.resultmonthCal = _.merge(this.resultmonth, this.saveCalValues);

				// console.log(_.merge(this.resultmonth, this.saveCalValues));
				// console.log(merge(this.saveCalValues, this.resultmonth));
			},
			setToday() {
				this.value = moment().format("yyyy-MM-DD");
				// console.log(this.value);
			},

			cableLabelTrue() {
				this.cableLabelHide = true;
			},

			cableLabelFalse() {
				this.cableLabelHide = false;
			},
			closeModal() {
				this.localParam = [];
				console.log("test");
				this.$router.push({
					path: "/rent",
				});
			},
		},
		updated() {
			setTimeout(() => {
				this.$store.commit("set_Notification_AddItem", false);
			}, 10000);
		},
		mounted() {
			this.items = this.addcartItems;
			console.log(this.$isMobile());
			if (this.$isMobile()) {
				if (!this.ListInfo[0].size) {
					this.$router.push({
						path: "/rent/info",
						query: { id: this.ListInfo[0].id, model: this.routerPathName },
						hash: "#auto",
					});
				} else if (this.ListInfo[0].size) {
					this.$router.push({
						path: "/rent/info",
						query: {
							id: this.ListInfo[0].id,
							model: this.routerPathName,
							length: this.ListInfo[0].size,
						},
						hash: "#auto",
					});
				}
				var urlHash = this.$route.hash;
				console.log(urlHash);
			} else if (!this.$isMobile()) {
				if (!this.ListInfo[0].size) {
					this.$router.push({
						path: "/rent/info",
						query: { id: this.ListInfo[0].id, model: this.routerPathName },
					});
				} else if (this.ListInfo[0].size) {
					this.$router.push({
						path: "/rent/info",
						query: {
							id: this.ListInfo[0].id,
							model: this.routerPathName,
							length: this.ListInfo[0].size,
						},
					});
				}
			}
			// const resultmonth = [];
			this.$store.commit("set_qrReadID", this.$route.query.id);

			var db = firebase.firestore();

			db.collection("calendar")
				.where("model", "==", this.ListInfo[0].model)
				.where("status", "==", false)
				.get()
				.then((querysnapshot) => {
					querysnapshot.forEach((doc) => {
						this.dbitems.push(doc.data());
						const saveDBItems = this.dbitems;
						const savepushData = [];

						for (let i = 0; i < saveDBItems.length; i++) {
							var startDate = new Date(saveDBItems[i].start);
							var endDate = new Date(saveDBItems[i].end);

							// var termDay = (endDate - startDate) / 86400000;
							// console.log(termDay);

							for (
								var d = startDate;
								d <= endDate;
								d.setDate(d.getDate() + 1)
							) {
								let item = {
									start: moment(d).format("YYYY-MM-DD"),
									end: moment(d).format("YYYY-MM-DD"),
								};
								this.saveDateItems.push(item);
								savepushData.push(merge(saveDBItems[i], item));
							}
						}
						this.saveCalItems = savepushData;
					});

					// lodash
					var output = _(this.saveCalItems)
						.groupBy("start")
						.map((objs, key) => ({
							start: key,
							name:
								String(this.ListInfo[0].stock - _.sumBy(objs, "name")) +
								"/" +
								String(this.ListInfo[0].stock),
							stock: this.ListInfo[0].stock,
							used: _.map(objs, "name"),
							user: _.map(objs, "user"),
							rentUser: _.map(objs, (item) => ({
								used: item.name,
								user: item.user,
							})),
						}))
						.value();

					this.outputArr = output;

					this.saveCalincrement = _.merge(
						_.keyBy(this.saveCalItems, "start"),
						_.keyBy(this.outputArr, "start")
					);
					var values = _.values(this.saveCalincrement);
					this.saveCalValues = values;

					const resultmonth = [];

					const resultday = this.datemoment.split("-");
					const startresult = resultday[0] + "-" + resultday[1] + "-01";
					const endresult = moment(
						resultday[0] + "-" + resultday[1],
						"YYYY-MM"
					).daysInMonth();

					var begin_month = moment(startresult).format("YYYY-MM-DD");
					var end_month = moment(
						resultday[0] + "-" + resultday[1] + "-" + endresult
					).format("YYYY-MM-DD");

					this.startresult = begin_month;
					this.endresult = end_month;

					for (
						var d = new Date(this.startresult);
						d <= new Date(this.endresult);
						d.setDate(d.getDate() + 1)
					) {
						let item = {
							model: this.ListInfo[0].model,
							start: moment(d).format("YYYY-MM-DD"),
							end: moment(d).format("YYYY-MM-DD"),
							name: this.ListInfo[0].stock + "/" + this.ListInfo[0].stock,
							status: false,
						};
						resultmonth.push(item);
					}
					this.resultmonth = resultmonth;

					var resultmonthCal = _.merge(
						_.keyBy(this.resultmonth, "start"),
						_.keyBy(this.saveCalValues, "start")
					);

					this.resultmonthCal = _.values(resultmonthCal);
				});
		},
		created() {
			this.$store.dispatch("getCalendarDate");
		},
		data: () => ({
			dbitems: [],
			modal: 0,
			colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
			events: [
				{ name: "1", start: "2021-09-01", end: "2021-09-05" },
				{ name: "5", start: "2021-09-20", end: "2021-09-25" },
			],
			value: moment().format("yyyy-MM-DD"),
			dateformat: moment().format("yyyy-MM-DD"),
			closeDialog: false,
			headers: [
				{
					text: "model",
					align: "start",
					sortable: false,
					value: "model",
				},
				{
					text: "manufacturer",
					value: "manufacturer",
				},
				{
					text: "size",
					value: "size",
				},
				{
					text: "category",
					value: "category",
				},
				{
					text: "location_repo",
					value: "location_repo",
				},
				{
					text: "location_box",
					value: "location_box",
				},
				{
					text: "quantity",
					value: "quantity",
				},
			],
			// prop_list_info: this.ListInfo,
			items: null,
			//v-date-pickerの範囲データ
			dates: ["", ""],

			//Firestoreに送信するデータ
			checkout_date: "",
			return_date: "",
			// rentuser: "",
			quantity: "",
			//プロジェクト名
			projects: "",

			saveDBItems: [],
			saveCalItems: [],
			saveDateItems: [],
			outputArr: [],
			outputArr2: [],
			outputArr3: [],

			saveCalValues: [],

			resultmonth: [],

			resultmonthCal: [],

			startresult: "",
			endresult: "",

			selectedOpen: false,
			selectedElement: null,

			cableLabelHide: false,

			selectedChips: false,
		}),
	};
</script>
<style>
	.h1 {
		color: rgba(0, 0, 0, 0.6);
	}
	div.v-picker__title {
		color: #262626;
		background-color: transparent;
	}
	div.v-picker v-card v-picker--date theme--light {
		background-color: transparent;
	}

	div.v-calendar-weekly__day.v-present {
		background-color: #ffccbc;
	}
	button.v-btn.v-btn--fab.v-btn--has-bg.v-btn--round.theme--light.v-size--small.white {
		background-color: #ffccbc !important;
	}
	span.countNum {
		font-weight: bold;
	}
	button.addBtn span {
		color: #fff;
	}
	.v-btn--fab.v-size--small.v-btn--absolute.v-btn--top {
		top: 0px;
	}

	div.v-date-picker-table .v-btn.v-btn--active {
		color: #262626;
	}
	.titleColorInfo {
		background-color: #ffffff;
		color: #fff;
	}
	.v-application .transparent--text {
		color: black !important;
		caret-color: black !important;
	}
	.rentCal {
		pointer-events: none;
	}
	.transparentCard {
		background-color: transparent !important;
		border-color: transparent !important;
	}
</style>
<style scoped>
	.v-menu__content {
		background-color: transparent;
		box-shadow: none;
	}

	.v-chip--clickable {
		pointer-events: none;
	}
</style>
