<template>
	<div>
		<MenuWrap />
	</div>
</template>

<script>
	import MenuWrap from "../components/MenuWrap";

	export default {
		name: "Menu",

		components: {
			MenuWrap,
		},
		beforeRouteLeave(to, from, next) {
			this.$store.commit("setmenuDialog", false);
			next();
		},
	};
</script>
<style>
	div.row {
		backgroun-color: #000;
	}
</style>
