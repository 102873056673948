<template>
	<div>
		<LoadingWrap />
	</div>
</template>

<script>
	import LoadingWrap from "../components/LoadingWrap";

	export default {
		name: "Loading",

		components: {
			LoadingWrap,
		},
	};
</script>
