<template>
	<v-app>
		<Nav />
		<v-container bg fill-height grid-list-md text-xs-center>
			<v-layout row wrap>
				<v-flex>
					<v-main>
						<router-view />
					</v-main>
				</v-flex>
			</v-layout>
		</v-container>
	</v-app>
</template>

<script>
	import Nav from "../components/Nav.vue";
	// import Footer from "../components/Footer.vue";

	export default {
		name: "AppTable",
		components: {
			Nav,
			// Footer,
		},
		data() {
			return {};
		},
		methods: {},
		computed: {},
		created() {},
	};
</script>
<style scoped>
	.theme--light.v-application {
		background: #e6e6e6 !important;
	}
</style>
