import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Loading from "../views/Loading.vue";
import Login from "../views/Login.vue";
import Rent from "../views/Rent.vue";
import Infomation from "../components/Infomation.vue";
import Return from "../views/Return.vue";
import Admin from "../views/Admin.vue";
import AdminUpdate from "../views/AdminUpdate.vue";
import AdminUploader from "../views/AdminUploader.vue";
import AdminReturn from "../views/AdminReturn.vue";
import Menu from "../views/Menu.vue";
import store from "./../store";
// import firebase from "./../firebase";
import AppSingle from "../layouts/AppSingle.vue";
import AppTable from "../layouts/AppTable.vue";
import AppMenu from "../layouts/AppMenu.vue";
import Main from "../layouts/Main.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/loading",
		name: "Loading",
		component: Loading,
	},
	{
		path: "/",
		name: "AppSingle",
		component: AppSingle,
	},
	{
		path: "/apptable",
		component: AppTable,
		children: [
			{
				path: "/return",
				name: "Return",
				component: Return,
				meta: { requiresAuth: true },
			},
			{
				path: "/adminupdate",
				name: "AdminUpdate",
				component: AdminUpdate,
				meta: { requiresAuth: true },
			},
			{
				path: "/adminreturn",
				name: "AdminReturn",
				component: AdminReturn,
				meta: { requiresAuth: true },
			},
		],
	},
	{
		path: "/appmenu",
		component: AppMenu,
		children: [
			{
				path: "/menu",
				name: "Menu",
				component: Menu,
				meta: { requiresAuth: true },
			},
			{
				path: "/admin",
				name: "Admin",
				component: Admin,
				meta: { requiresAuth: true },
			},
			{
				path: "/adminuploader",
				name: "AdminUploader",
				component: AdminUploader,
				meta: { requiresAuth: true },
			},
		],
	},
	{
		path: "/",
		component: Main,
		children: [
			{
				path: "/login",
				name: "Login",
				component: Login,
			},
			// {
			// 	path: "/menu",
			// 	name: "Menu",
			// 	component: Menu,
			// },
			{
				path: "/rent",
				name: "Rent",
				component: Rent,
				meta: { requiresAuth: true },
				children: [{ path: ":info", component: Infomation }],
			},
		],
	},
	{
		path: "/info",
		redirect: "/rent/info",
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!store.state.status) {
			next({
				path: "/",
				query: { redirect: to.fullPath },
			});
		} else {
			next();
		}
	} else {
		next(); // nextを常に呼び出すようにしておかないとループしてしまう
	}
});

export default router;
