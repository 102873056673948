<template>
	<div>
		<v-container
			style="max-width: 960px; position: relative;"
			v-on:keydown.enter="onKeyDown"
			class="pa-0"
		>
			<v-card color="transparent" flat>
				<v-row class=" text-center" justify="center" align-content="center">
					<v-col cols="12" md="4" sm="12" xs="12">
						<v-card
							to="/rent"
							elevation="0"
							color="#262626"
							class="card py-md-15 py-sm-12 py-5"
							><v-icon :size="iconSize" color="white"
								>mdi-calendar-month-outline</v-icon
							>
							<v-card-title
								style="color:#fff;"
								class="justify-center ma-0 pt-2 pa-0 text-md-h5 text-h6"
								>RESERVE</v-card-title
							></v-card
						>
					</v-col>
					<v-col cols="12" md="4" sm="12" xs="12">
						<v-card
							to="/return"
							elevation="0"
							color="#262626"
							class="card py-md-15 py-sm-12 py-5"
							><v-icon :size="iconSize" color="white"
								>mdi-package-variant-closed</v-icon
							>
							<v-card-title
								style="color:#fff;"
								class="justify-center ma-0 pt-2 pa-0 text-md-h5 text-h6"
								>RETURN</v-card-title
							></v-card
						>
					</v-col>
					<v-tooltip right>
						<template v-slot:activator="{ on, attrs }">
							<v-col cols="12" md="4" sm="12" xs="12">
								<v-card
									elevation="0"
									color="#262626"
									class="card disable py-md-15 py-sm-12 py-5"
									v-bind="attrs"
									v-on="on"
									disabled
									><v-icon :size="iconSize" color="white"
										>mdi-clipboard-text-outline</v-icon
									>
									<v-card-title
										style="color:#fff;"
										class="justify-center ma-0 pt-2 pa-0 text-md-h5 text-h6"
										>PICKUP</v-card-title
									></v-card
								>
							</v-col>
						</template>
						<span>Coming Soon...</span>
					</v-tooltip>
				</v-row>
				<v-dialog v-model="menuDialog" width="500">
					<Menu />
				</v-dialog>
			</v-card>
		</v-container>
	</div>
</template>

<script>
	import Menu from "./common/Menu";
	export default {
		name: "MenuWrap",
		components: {
			Menu,
		},
		computed: {
			menuDialog: {
				get: function() {
					return this.$store.getters.getmenuDialog;
				},
			},
			iconSize() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 25;
					case "sm":
						return 25;
					case "md":
						return 50;
					default:
						return 50;
				}
			},
			// breakpointName() {
			// 	return this.$vuetify.breakpoint.xsOnly; // ex: 'lg'
			// },
		},
		methods: {
			onKeyDown(event) {
				if (event.ctrlKey) {
					if (event.altKey) {
						if (event.which === 53) {
							this.$store.commit("setmenuDialog", true);
						}
					}
				}
			},
		},
		mounted() {
			document.addEventListener("keydown", this.onKeyDown);
		},
		beforeDestroy() {
			document.removeEventListener("keydown", this.onKeyDown);
		},
		data: () => ({
			dialog: false,
			// menuDialog: false,
		}),
	};
</script>
<style>
	.menuOffset {
		justify-content: center;
		align-items: center;
	}
	.menuRow {
		flex-wrap: nowrap;
	}
	.disable {
		opacity: 0.5;
	}
	.card.v-card {
		border-radius: 0px;
	}

	.v-sheet.v-card {
		border-radius: 0px;
	}
	@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
	.v-application {
		font-family: "DINNextLTPro", "Noto Sans JP", sans-serif !important;
	}
	.v-application .v-application-wrap {
		font-family: "DINNextLTPro", "Noto Sans JP", sans-serif !important;
	}
	.v-application .title {
		font-family: "DINNextLTPro", "Noto Sans JP", sans-serif !important;
	}
	.v-application .text-h5 {
		font-family: "DINNextLTPro", "Noto Sans JP", sans-serif !important;
	}
	.v-application .text-h6 {
		font-family: "DINNextLTPro", "Noto Sans JP", sans-serif !important;
	}
</style>

<style scoped>
	@media (min-width: 600px) {
		.v-application .py-sm-12 {
			padding-top: 48px !important;
			padding-bottom: 48px !important;
		}
	}
</style>
