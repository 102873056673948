<template>
	<v-container class="pa-0 ma-0">
		<v-card flat width="100vw">
			<div v-show="numbershow">
				{{ selectedRows.length }}
			</div>

			<v-tabs color="black">
				<v-tab>
					<v-icon left> mdi-account </v-icon>
					Lending
				</v-tab>
				<v-tab>
					<v-icon left> mdi-trash-can-outline </v-icon>
					Done
				</v-tab>

				<v-tab-item>
					<v-form ref="form" v-model="valid">
						<v-card flat>
							<v-data-table
								v-if="$vuetify.breakpoint.mdAndUp"
								:headers="headers"
								:items="docID"
								:items-per-page="20"
								item-key="id+name"
								elevation="0"
								:sort-by="['checkout_date']"
								:sort-desc="true"
								:footer-props="{ 'items-per-page-text': '' }"
							>
								<template v-slot:item.iteminfo="{ item }">
									<v-btn
										outlined
										color="#262626"
										style="text-transform: none"
										v-bind="attrs"
										v-on="on"
										small
										class="rounded-0"
										@click="clickRowInfoSave(item)"
										>Info</v-btn
									>
								</template>
								<template v-slot:item.changedates="{ item }">
									<v-btn
										outlined
										color="#262626"
										style="text-transform: none"
										small
										class="rounded-0"
										@click="clickRowInfo(item), toggle()"
										>Due date</v-btn
									>
								</template>
								<template v-slot:item.complete="{ item }">
									<v-dialog
										v-model="dialog2[item]"
										max-width="1600px"
										@click:outside="closeDialog"
										content-class="returnDialog"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												outlined
												color="#262626"
												style="text-transform: none"
												small
												@click="clickReturnInfo(item)"
												v-bind="attrs"
												v-on="on"
												class="rounded-0"
												>Return</v-btn
											>
										</template>
										<v-card flat>
											<v-card-title
												class="text-h6 text-md-h5 pt-2 pt-md-4 pb-2 pb-md-4 titleColor"
											>
												返却をする
											</v-card-title>
											<!-- <v-btn @click="testMethods(item)"></v-btn>

											<v-checkbox @change="testMethods1()"></v-checkbox> -->

											<v-card-text>
												<v-data-table
													v-if="$vuetify.breakpoint.mdAndUp"
													:headers="headers2"
													:items="docID_Items"
													item-key="model"
													v-model="selectedRows"
													show-select
													:single-select="true"
													class="elevation-0"
													:footer-props="{ 'items-per-page-text': '' }"
												>
													<template v-slot:item.category="{ item }">
														{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}
													</template>
													<template v-slot:item.location_repo="{ item }">
														{{
															item.location_repo
																| replace("tech_room", "Tech Room")
																| replace("stock_room", "Stock Room")
														}}
													</template>
													<template v-slot:item.location_box="{ item }">
														{{
															item.location_box
																| replace("a1light", "A-1-Light")
																| replace("a1camera", "A-1-Camera")
																| replace("a1lens", "A-1-Lens")
																| replace("a2camera", "A-2-Camera")
																| replace("a3case", "A-3-Case")
																| replace("a41", "A-4-1")
																| replace("a42", "A-4-2")
																| replace("a43", "A-4-3")
																| replace("b1jib", "B-1-Jib")
																| replace("b1light", "B-1-Light")
																| replace("b2sdi", "B-2-SDI")
																| replace("b24", "B-2-4")
																| replace("b25", "B-2-5")
																| replace("b31", "B-3-1")
																| replace("b32", "B-3-2")
																| replace("b33", "B-3-3")
																| replace("b34", "B-3-4")
																| replace("b35", "B-3-5")
																| replace("c1display", "C-1-Display")
																| replace("c21", "C-2-1")
																| replace("c22", "C-2-2")
																| replace("c23", "C-2-3")
																| replace("c24", "C-2-4")
																| replace("c25", "C-2-5")
																| replace("c31", "C-3-1")
																| replace("c32", "C-3-2")
																| replace("c33", "C-3-3")
																| replace("c34", "C-3-4")
																| replace("c35", "C-3-5")
														}}
													</template>
													<template v-slot:item.statuschange="{ item, index }">
														<v-btn
															fab
															x-small
															outlined
															color="#262626"
															class="statusBtn rounded-0"
															slot="activator"
															label="fromDate"
															v-on:click="clickItemStatus(item, index)"
															value="item.id"
														>
															<v-icon>mdi-exclamation</v-icon>
														</v-btn>
													</template>
													<template v-slot:item.data-table-select="{ item }">
														<v-checkbox
															@click="testMethods1(item)"
															v-model="item.checked"
															:ripple="false"
														></v-checkbox>
													</template>
													<!-- <template v-slot:item.dontreturn="{ item }">
														<v-btn
															fab
															x-small
															outlined
															color="#262626"
															class="statusBtn"
															slot="activator"
															label="fromDate"
															v-on:click="clickItemDontReturn(item)"
															value="item.id"
														>
															<v-icon>mdi-calendar-arrow-right</v-icon>
														</v-btn>
													</template> -->
												</v-data-table>
											</v-card-text>
											<v-divider></v-divider>
											<v-card class="mx-auto">
												<v-card-actions>
													<v-spacer></v-spacer>
													<v-dialog v-model="dialog" width="500">
														<template v-slot:activator="{ on, attrs }">
															<!-- <v-btn
																outlined
																color="#262626"
																style="text-transform: none"
																v-bind="attrs"
																v-on="on"
																@click="click"
																:disabled="!valid"
															>
																RETURN
															</v-btn> -->
															<div v-show="returnBtn">
																<v-btn
																	outlined
																	color="#262626"
																	style="text-transform: none"
																	v-bind="attrs"
																	v-on="on"
																	@click="click"
																	class="rounded-0"
																>
																	RETURN
																</v-btn>
															</div>

															<div v-show="!returnBtn">
																<v-btn
																	outlined
																	color="#262626"
																	style="text-transform: none"
																	v-bind="attrs"
																	v-on="on"
																	@click="click"
																	:disabled="!vForm"
																	class="rounded-0"
																>
																	RETURN
																</v-btn>
															</div>
														</template>
														<v-card>
															<v-card-title class="text-h5 titleColor">
																返却者を確認する
															</v-card-title>

															<v-card-text>
																<v-text-field
																	label="返却の確認をした人の名前を記入してください"
																	v-model="checkuser"
																	class="mt-5"
																	:rules="[
																		(v) => !!v || '必ず入力してください',
																	]"
																	required
																></v-text-field>
															</v-card-text>
															<v-divider></v-divider>

															<v-card-actions>
																<v-spacer></v-spacer>
																<v-btn
																	outlined
																	color="black"
																	style="text-transform: none"
																	@click="dialog = false"
																	class="rounded-0"
																>
																	CLOSE
																</v-btn>
																<v-btn
																	outlined
																	color="black"
																	style="text-transform: none"
																	@click="clickRow(item)"
																	:disabled="!valid"
																	class="rounded-0"
																>
																	RETURN
																</v-btn>
															</v-card-actions>
														</v-card>
													</v-dialog>
												</v-card-actions>
											</v-card>
										</v-card>
									</v-dialog>
								</template>
							</v-data-table>

							<v-list-item-group
								color="white"
								v-if="!$vuetify.breakpoint.mdAndUp"
							>
								<v-list-item
									v-for="(item, i) in docID"
									:key="i"
									:ripple="false"
									:inactive="false"
								>
									<v-row>
										<v-list-item-content>
											<v-divider></v-divider>
											<v-col cols="6" md="3">
												<div class="mobileList">
													<v-list-item-subtitle class="text-subtitle-2"
														>Project</v-list-item-subtitle
													>
													<v-list-item-subtitle
														class="text-subtitle-1"
														v-text="item.projects"
													></v-list-item-subtitle>
												</div>
												<div class="py-1"></div>
												<div class="mobileList">
													<v-list-item-subtitle class="text-subtitle-2"
														>User</v-list-item-subtitle
													>
													<v-list-item-subtitle
														class="text-subtitle-1"
														v-text="item.user"
													></v-list-item-subtitle>
												</div>
											</v-col>
											<v-col cols="6" md="3">
												<div class="mobileList">
													<v-list-item-subtitle class="text-subtitle-2"
														>Checkout Date</v-list-item-subtitle
													>
													<v-list-item-subtitle
														class="text-subtitle-1"
														v-text="item.checkout_date"
													></v-list-item-subtitle>
												</div>
												<div class="py-1"></div>
												<div class="mobileList">
													<v-list-item-subtitle class="text-subtitle-2"
														>Return Date</v-list-item-subtitle
													>
													<v-list-item-subtitle
														class="text-subtitle-1"
														v-text="item.return_date"
													></v-list-item-subtitle>
												</div>
											</v-col>
											<v-col cols="4">
												<v-btn
													outlined
													color="#262626"
													style="text-transform: none"
													v-bind="attrs"
													v-on="on"
													small
													block
													class="rounded-0"
													@click="clickRowInfoSave(item)"
													>Info</v-btn
												>
											</v-col>
											<v-col cols="4">
												<v-btn
													outlined
													color="#262626"
													style="text-transform: none"
													small
													block
													class="rounded-0"
													@click="clickRowInfo(item)"
													>Due date</v-btn
												>
											</v-col>

											<v-dialog
												v-model="dialog2[item]"
												max-width="1600px"
												@click:outside="closeDialog"
												content-class="nestDialog"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-col cols="4">
														<v-btn
															outlined
															color="#262626"
															style="text-transform: none"
															small
															block
															@click="clickReturnInfo(item)"
															v-bind="attrs"
															v-on="on"
															class="rounded-0"
															>Return</v-btn
														>
													</v-col>
												</template>
												<v-card flat class="pa-0">
													<v-card-title
														class="text-h6 text-md-h5 pt-2 pt-md-4 pb-2 pb-md-4 titleColor"
													>
														返却をする
													</v-card-title>
													<!-- <v-btn @click="testMethods(item)"></v-btn>

											<v-checkbox @change="testMethods1()"></v-checkbox> -->

													<v-card-text class="pa-0">
														<!-- {{ docID_Items }} -->
														<v-list-item-group
															color="white"
															v-if="!$vuetify.breakpoint.mdAndUp"
														>
															<v-list-item
																v-for="(item, index) in docID_Items"
																:key="index"
																:ripple="false"
																:inactive="false"
																class="returnDialogList"
															>
																<v-row>
																	<v-list-item-content
																		class="returnDialogListContent"
																	>
																		<v-divider></v-divider>
																		<v-col cols="6" md="3">
																			<div class="mobileList">
																				<v-list-item-subtitle
																					class="text-subtitle-2"
																					>Category</v-list-item-subtitle
																				>
																				<v-list-item-subtitle
																					class="text-subtitle-1"
																					>{{
																						item.category
																							| replace("camera", "Camera")
																							| replace("lens", "Lens")
																							| replace(
																								"recorder",
																								"Recorder / Monitor"
																							)
																							| replace("media", "Media")
																							| replace(
																								"tripod",
																								"Tripod/Gimbal/Jib"
																							)
																							| replace("light", "Light")
																							| replace("switcher", "Switcher")
																							| replace(
																								"video-cable",
																								"Video cable"
																							)
																							| replace(
																								"audio-cable",
																								"Audio cable"
																							)
																							| replace(
																								"lan-cable",
																								"LAN cable"
																							)
																							| replace(
																								"power-cable",
																								"Power cable"
																							)
																							| replace(
																								"other-cable",
																								"Cable (Other)"
																							)
																							| replace("labo", "Labo")
																					}}</v-list-item-subtitle
																				>
																			</div>
																			<div class="py-1"></div>
																			<div
																				v-if="item.manufacturer"
																				class="mobileList"
																			>
																				<v-list-item-subtitle
																					class="text-subtitle-2"
																					>Manufacturer</v-list-item-subtitle
																				>
																				<v-list-item-subtitle
																					class="text-subtitle-1"
																					v-text="item.manufacturer"
																				></v-list-item-subtitle>
																			</div>
																			<div class="py-1"></div>
																			<div class="mobileList">
																				<v-list-item-subtitle
																					class="text-subtitle-2"
																					>Model</v-list-item-subtitle
																				>
																				<v-list-item-subtitle
																					class="text-subtitle-1"
																					v-text="item.model"
																				></v-list-item-subtitle
																				><span v-if="item.size"
																					>{{ item.size }}m</span
																				>
																			</div>
																			<div class="py-1"></div>
																			<div
																				v-if="item.model_number"
																				class="mobileList"
																			>
																				<v-list-item-subtitle
																					class="text-subtitle-2"
																					>Model number</v-list-item-subtitle
																				>
																				<v-list-item-subtitle
																					class="text-subtitle-1"
																					v-text="item.model_number"
																				></v-list-item-subtitle>
																			</div>
																		</v-col>
																		<v-col cols="6" md="3">
																			<div class="mobileList">
																				<v-list-item-subtitle
																					class="text-subtitle-2"
																					>Location</v-list-item-subtitle
																				>
																				<v-list-item-subtitle
																					class="text-subtitle-1"
																					>{{
																						item.location_repo
																							| replace(
																								"tech_room",
																								"Tech Room"
																							)
																							| replace(
																								"stock_room",
																								"Stock Room"
																							)
																					}}</v-list-item-subtitle
																				>
																			</div>
																			<div class="py-1"></div>
																			<div class="mobileList">
																				<v-list-item-subtitle
																					class="text-subtitle-2"
																					>Box</v-list-item-subtitle
																				>
																				<v-list-item-subtitle
																					class="text-subtitle-1"
																					>{{
																						item.location_box
																							| replace("a1light", "A-1-Light")
																							| replace(
																								"a1camera",
																								"A-1-Camera"
																							)
																							| replace("a1lens", "A-1-Lens")
																							| replace(
																								"a2camera",
																								"A-2-Camera"
																							)
																							| replace("a3case", "A-3-Case")
																							| replace("a41", "A-4-1")
																							| replace("a42", "A-4-2")
																							| replace("a43", "A-4-3")
																							| replace("b1jib", "B-1-Jib")
																							| replace("b1light", "B-1-Light")
																							| replace("b2sdi", "B-2-SDI")
																							| replace("b24", "B-2-4")
																							| replace("b25", "B-2-5")
																							| replace("b31", "B-3-1")
																							| replace("b32", "B-3-2")
																							| replace("b33", "B-3-3")
																							| replace("b34", "B-3-4")
																							| replace("b35", "B-3-5")
																							| replace(
																								"c1display",
																								"C-1-Display"
																							)
																							| replace("c21", "C-2-1")
																							| replace("c22", "C-2-2")
																							| replace("c23", "C-2-3")
																							| replace("c24", "C-2-4")
																							| replace("c25", "C-2-5")
																							| replace("c31", "C-3-1")
																							| replace("c32", "C-3-2")
																							| replace("c33", "C-3-3")
																							| replace("c34", "C-3-4")
																							| replace("c35", "C-3-5")
																					}}</v-list-item-subtitle
																				>
																			</div>
																			<div class="py-1"></div>
																			<div v-if="item.size" class="mobileList">
																				<v-list-item-subtitle
																					class="text-subtitle-2"
																					>Cable length</v-list-item-subtitle
																				>
																				<v-list-item-subtitle
																					class="text-subtitle-1"
																					>{{
																						item.size
																					}}m</v-list-item-subtitle
																				>
																			</div>
																			<div class="py-1"></div>
																			<div class="mobileList">
																				<v-list-item-subtitle
																					class="text-subtitle-2"
																					>Amount</v-list-item-subtitle
																				>
																				<v-list-item-subtitle
																					class="text-subtitle-1"
																					v-text="item.quantity"
																				></v-list-item-subtitle>
																			</div>
																		</v-col>
																		<v-divider></v-divider>
																		<v-col cols="6">
																			<v-checkbox
																				@click="testMethods1(item)"
																				v-model="item.checked"
																				:ripple="false"
																				label="Check"
																				class="mobileCheckbox"
																			></v-checkbox>
																		</v-col>
																		<v-col cols="6">
																			<v-btn
																				fab
																				x-small
																				outlined
																				color="#262626"
																				class="statusBtn rounded-0"
																				slot="activator"
																				label="fromDate"
																				v-on:click="
																					clickItemStatus(item, index)
																				"
																				value="item.id"
																				block
																			>
																				<v-icon>mdi-exclamation</v-icon>
																			</v-btn>
																		</v-col>
																	</v-list-item-content>
																</v-row>
															</v-list-item>
														</v-list-item-group>
													</v-card-text>
													<v-divider></v-divider>
													<v-card class="mx-auto">
														<v-card-actions>
															<v-spacer></v-spacer>
															<v-dialog v-model="dialog" width="500">
																<template v-slot:activator="{ on, attrs }">
																	<!-- <v-btn
																outlined
																color="#262626"
																style="text-transform: none"
																v-bind="attrs"
																v-on="on"
																@click="click"
																:disabled="!valid"
															>
																RETURN
															</v-btn> -->
																	<div v-show="returnBtn">
																		<v-btn
																			outlined
																			color="#262626"
																			style="text-transform: none"
																			v-bind="attrs"
																			v-on="on"
																			@click="click"
																			class="rounded-0"
																		>
																			RETURN
																		</v-btn>
																	</div>

																	<div v-show="!returnBtn">
																		<v-btn
																			outlined
																			color="#262626"
																			style="text-transform: none"
																			v-bind="attrs"
																			v-on="on"
																			@click="click"
																			:disabled="!vForm"
																			class="rounded-0"
																		>
																			RETURN
																		</v-btn>
																	</div>
																</template>
																<v-card>
																	<v-card-title class="text-h5 titleColor">
																		返却者を確認する
																	</v-card-title>

																	<v-card-text>
																		<v-text-field
																			label="返却の確認をした人の名前を記入してください"
																			v-model="checkuser"
																			class="mt-5"
																			:rules="[
																				(v) => !!v || '必ず入力してください',
																			]"
																			required
																		></v-text-field>
																	</v-card-text>
																	<v-divider></v-divider>

																	<v-card-actions>
																		<v-spacer></v-spacer>
																		<v-btn
																			outlined
																			color="black"
																			style="text-transform: none"
																			@click="dialog = false"
																			class="rounded-0"
																		>
																			CLOSE
																		</v-btn>
																		<v-btn
																			outlined
																			color="black"
																			style="text-transform: none"
																			@click="clickRow(item)"
																			:disabled="!valid"
																			class="rounded-0"
																		>
																			RETURN
																		</v-btn>
																	</v-card-actions>
																</v-card>
															</v-dialog>
														</v-card-actions>
													</v-card>
												</v-card>
											</v-dialog>
										</v-list-item-content>
									</v-row>
								</v-list-item>
							</v-list-item-group>
						</v-card>
					</v-form>
				</v-tab-item>
				<v-tab-item>
					<v-card flat>
						<v-data-table
							v-if="$vuetify.breakpoint.mdAndUp"
							:headers="headers_archives"
							:items="docID_Archives"
							:items-per-page="20"
							item-key="name"
							class="elevation-0"
							:sort-by="['checkout_date']"
							:sort-desc="true"
							:footer-props="{ 'items-per-page-text': '' }"
						>
							<template v-slot:item.iteminfo="{ item }">
								<v-btn
									outlined
									color="#262626"
									style="text-transform: none"
									small
									@click="clickDoneInfo(item)"
									class="rounded-0"
									>Info</v-btn
								>
							</template>
						</v-data-table>
						<v-list-item-group
							color="white"
							v-if="!$vuetify.breakpoint.mdAndUp"
						>
							<v-list-item
								v-for="(item, i) in docID_Archives"
								:key="i"
								:ripple="false"
								:inactive="false"
							>
								<v-row>
									<v-list-item-content>
										<v-divider></v-divider>
										<v-col cols="6" md="3">
											<div class="mobileList">
												<v-list-item-subtitle class="text-subtitle-2"
													>Project</v-list-item-subtitle
												>
												<v-list-item-subtitle
													class="text-subtitle-1"
													v-text="item.projects"
												></v-list-item-subtitle>
											</div>
											<div class="py-1"></div>
											<div class="mobileList">
												<v-list-item-subtitle class="text-subtitle-2"
													>Checkout date</v-list-item-subtitle
												>
												<v-list-item-subtitle
													class="text-subtitle-1"
													v-text="item.checkout_date"
												></v-list-item-subtitle>
											</div>
											<div class="py-1"></div>
											<div class="mobileList">
												<v-list-item-subtitle class="text-subtitle-2"
													>Return date</v-list-item-subtitle
												>
												<v-list-item-subtitle
													class="text-subtitle-1"
													v-text="item.return_date"
												></v-list-item-subtitle>
											</div>
										</v-col>
										<v-col cols="6" md="3">
											<div class="mobileList">
												<v-list-item-subtitle class="text-subtitle-2"
													>User</v-list-item-subtitle
												>
												<v-list-item-subtitle
													class="text-subtitle-1"
													v-text="item.user"
												></v-list-item-subtitle>
											</div>
											<div class="py-1"></div>
											<div class="mobileList">
												<v-list-item-subtitle class="text-subtitle-2"
													>Check user(latest)</v-list-item-subtitle
												>
												<v-list-item-subtitle
													class="text-subtitle-1"
													v-text="item.checkuser"
												></v-list-item-subtitle>
											</div>
										</v-col>
										<v-divider></v-divider>
										<v-col>
											<v-btn
												outlined
												color="#262626"
												style="text-transform: none"
												small
												@click="clickDoneInfo(item)"
												block
												class="rounded-0"
												>Info</v-btn
											>
										</v-col>
									</v-list-item-content>
								</v-row>
							</v-list-item>
						</v-list-item-group>
					</v-card>
				</v-tab-item>
			</v-tabs>
		</v-card>
		<v-dialog
			v-model="dialogInfo_done_info"
			max-width="1024px"
			border-radius="0px"
			@click:outside="$store.commit('set_dialogInfo_done_info', false)"
			style="border-radius: 0px"
			content-class="checkoutDialog nestDialog"
		>
			<Done :ListInfo="ListInfo" :ListInfo_A="ListInfo_A" />
		</v-dialog>
		<v-dialog
			v-model="dialogInfo_return_info"
			max-width="1024px"
			border-radius="0px"
			@click:outside="$store.commit('set_dialogInfo_return_info', false)"
			style="border-radius: 0px"
			content-class="checkoutDialog"
		>
			<ReturnInfo :ListInfo="ListInfo" :ListInfo_A="ListInfo_A" />
		</v-dialog>
		<v-dialog
			v-model="dialogInfo_return_due"
			max-width="1024px"
			border-radius="0px"
			@click:outside="
				$store.commit('set_dialogInfo_return_due', false), resetForm
			"
			style="border-radius: 0px"
			content-class="checkoutDialog"
		>
			<ReturnDue :key="key" :ListInfo="ListInfo" :ListInfo_A="ListInfo_A" />
		</v-dialog>
		<v-dialog
			v-model="dialogInfo_return_return"
			max-width="400px"
			border-radius="0px"
			@click:outside="$store.commit('set_dialogInfo_return_return', false)"
			style="border-radius: 0px"
			content-class=" menuStyle ma-0 pa-4"
		>
			<ReturnMenu
				v-if="dialogInfo_return_return"
				:ListStatus="ListStatus"
				@sendrowClicked="rowClicked"
				@addStatus="addStatusMethod"
		/></v-dialog>
	</v-container>
</template>

<script>
	import firebase from "firebase";
	import Firebase from "./../firebase";
	import axios from "axios";
	import _ from "lodash";
	// import { FacebookAuthProvider } from "@firebase/auth-types";
	// const SteinStore = require("stein-js-client");
	// import emailjs from "emailjs-com";
	// const SteinStore = require("stein-js-client");
	// import Table_Dialog from "./Table_Dialog";
	// import Done from "./common/Done";

	export default {
		components: {
			Done: () => import("./common/Done.vue"),
			ReturnInfo: () => import("./common/ReturnInfo.vue"),
			ReturnDue: () => import("./common/ReturnDue.vue"),
			ReturnMenu: () => import("./common/ReturnMenu.vue"),
		},
		name: "Table_Checkout",
		filters: {
			replace: function (st, rep, repWith) {
				const result = st.split(rep).join(repWith);
				return result;
			},
		},
		data: () => ({
			attrs: null,
			on: null,
			click: null,

			show: false,
			//Header List
			headers2: [
				// { text: "ID", value: "id" },
				{ text: "Category", value: "category" },
				{ text: "Model", value: "model" },
				{ text: "Manufacturer", value: "manufacturer" },
				{ text: "Location", value: "location_repo" },
				{ text: "Box", value: "location_box" },
				{ text: "Cable length", value: "size" },
				{ text: "Model number", value: "model_number" },
				{ text: "Amount", value: "quantity", sortable: false },
				{
					text: "Report",
					value: "statuschange",
					sortable: false,
				},
			],
			headers3: [
				{ text: "Model", value: "model" },
				{ text: "Manufacturer", value: "manufacturer" },
				{ text: "Cable length", value: "size" },
				{ text: "Number", value: "quantity", sortable: false },
			],
			//firestore settings
			docID: [],
			docID_Archives: [],
			docID_Items: [],
			docID_Items_Info: [],

			dialog_returnInfo: false,

			ItemUn: [],

			snackbar: false,
			text: "",

			snackbar2: false,
			text2: "",

			timeout: 1000,

			dialog: false,
			dialog2: false,

			statusDiscription: "",

			valid: false,
			valid2: false,

			checkbox: false,

			checkboxvalid: false,

			statusArr: [],

			dates: [],
			date: "",

			selected: [],
			selectedRows: [],
			selectedItems: [],
			selectedId: [],
			selectedRowsPush: [],
			dbResult: [],
			dbResultFilter: [],
			dbSelected: [],

			checkReturn: false,

			checkuser: "",

			menu: false,

			ListInfo: [],
			ListInfo_A: [],

			ListStatus: [],

			saveValue: [],
			pushAlert: [],
			sendAlert: null,

			isStyleTrue: true,

			returnBtn: false,

			numbershow: false,

			selectedDoc: [],
			// saveitems: [],

			headers: [
				{
					text: "Project",
					align: "start",
					sortable: false,
					value: "projects",
					width: "20%",
				},
				{ text: "Checkout date", value: "checkout_date", width: "10%" },
				{ text: "Return date", value: "return_date", width: "10%" },
				{ text: "User", value: "user", width: "15%" },
				{ text: "Info", value: "iteminfo", sortable: false, width: "10%" },
				{
					text: "Due date",
					value: "changedates",
					sortable: false,
					width: "10%",
				},
				{ text: "Return", value: "complete", sortable: false, width: "10%" },
			],

			headers_archives: [
				{
					text: "Project",
					align: "start",
					sortable: false,
					value: "projects",
					width: "20%",
				},
				{ text: "Checkout date", value: "checkout_date", width: "10%" },
				{ text: "Return date", value: "return_date", width: "10%" },
				{ text: "User", value: "user", width: "15%" },
				{ text: "Check user (latest)", value: "checkuser", width: "15%" },
				{ text: "Info", value: "iteminfo", sortable: false, width: "10%" },
				{ text: "", value: "dummy", sortable: false, width: "10%" },
			],

			loading: true,
			errored: false,
			error: null,
			todos: [],

			vForm: false,

			key: 0,
		}),
		created: function () {
			this.selectedRows = 0;
			Firebase.onAuth();
			var db = firebase.firestore();

			db.collection("rentals")
				.where("status", "==", false)
				// .where("user", "==", this.user.displayName)
				.onSnapshot((snapshot) => {
					snapshot.docChanges().forEach((change) => {
						let docID = change.doc.data();
						docID.id = change.doc.id;
						this.docID.push(docID);
					});
				});

			db.collection("rentals")
				.where("status", "==", true)
				// .where("user", "==", this.user.displayName)
				.onSnapshot((snapshot) => {
					snapshot.docChanges().forEach((change) => {
						// 変更後のデータが取得できる
						let docID_Archives = change.doc.data();
						docID_Archives.id = change.doc.id;
						this.docID_Archives.push(docID_Archives);
						// console.log("change: ", change.doc.data());
					});
				});
		},

		methods: {
			toggle() {
				this.key = this.key ? 0 : 1;
			},
			// testFirestore() {
			// 	var db = firebase.firestore();
			// 	db.collection("rental")
			// 		.where("id", "==", saveID)
			// 		.get()
			// 		.then((querySnapshot) => {
			// 			querySnapshot.forEach((doc) =>
			// 				doc.ref.update({
			// 					status: true,
			// 				})
			// 			);
			// 		});
			// },
			testMethods1(item) {
				var rowsItem = this.selectedRowsPush;
				var checkStatus = item.checked;

				var trueLength = this.statusLengthTrue.length;

				// console.log(trueLength);

				this.dbSelected = this.docID_Items.indexOf(item);

				// this.dbResult = this.docID[this.dbSelected].Items;

				if (trueLength >= 1) {
					this.vForm = true;
					// console.log(trueLength);
				} else if (trueLength == 0) {
					this.vForm = false;
					// console.log(trueLength);
				}

				if (checkStatus) {
					rowsItem.splice(this.dbSelected, 0, item);
					rowsItem[this.dbSelected].returnstatus = true;
					// console.log(rowsItem);
				} else if (!checkStatus) {
					rowsItem.splice(this.dbSelected, 1);
					rowsItem[this.dbSelected].returnstatus = false;
					// console.log(rowsItem);
				}

				// this.selectedRowsPush.push(item);
				// this.selectedRowsPush.splice(selected, 0, item);
				// console.log(this.selectedRowsPush);
			},
			// testMethods() {
			// 	var mergeResult = _.merge(
			// 		_.keyBy(this.selectedRowsPush, "model"),
			// 		_.keyBy(this.dbResult, "model")
			// 	);

			// 	var itemLength = this.dbResult.length;
			// 	var selectLength = this.selectedRowsPush.length;

			// 	const saveItems = this.docID[this.dbSelected].Items;

			// 	console.log(itemLength, selectLength);
			// 	console.log(mergeResult);
			// 	console.log(saveItems);
			// },
			closeDialog() {
				this.selectedRows = [];
				this.vForm = false;
			},
			// onCheckboxClicked: function(e) {
			// 	console.log(e);
			// },
			// checkboxMethod(item) {
			// 	console.log(item);
			// },
			rowClick(item, row) {
				row.select(!row.isSelected);

				let selectedRows = [];
				selectedRows.push(this.selectedRows);
				// console.log(selectedRows);
			},
			testBtn() {
				var paramsAlertArr = [];

				var params = {
					text: "機材の異常をお知らせします",
					blocks: [
						{
							type: "header",
							text: {
								type: "plain_text",
								text: "機材の異常をお知らせします",
								emoji: true,
							},
						},
						{
							type: "divider",
						},
						{
							type: "section",
							text: {
								type: "plain_text",
								text: "報告された日時",
								emoji: true,
							},
						},
						{
							type: "context",
							elements: [
								{
									type: "plain_text",
									text: "test",
									emoji: true,
								},
							],
						},
						{
							type: "section",
							text: {
								type: "plain_text",
								text: "案件名",
								emoji: true,
							},
						},
						{
							type: "context",
							elements: [
								{
									type: "plain_text",
									text: "test",
									emoji: true,
								},
							],
						},
						{
							type: "section",
							text: {
								type: "plain_text",
								text: "報告者",
								emoji: true,
							},
						},
						{
							type: "context",
							elements: [
								{
									type: "plain_text",
									text: "test",
									emoji: true,
								},
							],
						},
					],
				};

				for (let i = 0; i < 5; i++) {
					var paramsAlert = [
						{
							type: "section",
							text: {
								type: "plain_text",
								text: "異常が報告された機材",
								emoji: true,
							},
						},
						{
							type: "context",
							elements: [
								{
									type: "plain_text",
									text: i,
									emoji: true,
								},
							],
						},
						{
							type: "context",
							elements: [
								{
									type: "plain_text",
									text: i,
									emoji: true,
								},
							],
						},
					];
					paramsAlertArr.push(paramsAlert);
				}

				// var paramsArr = _.concat(params, paramsAlertArr);
				var paramsArrflat = _.flatten(paramsAlertArr);

				params.blocks.push(...paramsArrflat);

				// console.log(params);
				// console.log(paramsArrflat);
			},

			toggleShow() {
				this.show = !this.show;
			},
			toggleShowOptions() {
				this.showOptions = !this.showOptions;
			},
			addContent() {
				this.contentLength += 1;
			},
			// テーブル行にクラス設定
			rowClicked(value, value1) {
				// const selected = this.docID_Items.indexOf();
				// const saveitems = this.docID_Items[selected + 1];
				let saveAlert = this.pushAlert;

				saveAlert.push(value1);

				this.sendAlert = saveAlert.flat();
				// console.log(this.sendAlert);

				// this.docID_Items.splice(saveitems, 1);
				// this.selectedRows.splice(saveitems, 1);
			},

			rowClicked1(item) {
				// console.log(item);
				this.toggleSelection(item.model);
				// console.log(item);
				if (this.docID_Items.length === this.selectedRows.length) {
					this.returnBtn = true;
				} else {
					this.returnBtn = false;
				}
			},
			// toggleSelection(keyID) {
			// 	if (this.selectedRows.includes(keyID)) {
			// 		this.selectedRows = this.selectedRows.filter(
			// 			(selectedKeyID) => selectedKeyID !== keyID
			// 		);
			// 	} else {
			// 		this.selectedRows.push(keyID);
			// 	}
			// },

			clickRow: function (item) {
				const selected = this.docID.indexOf(item);
				const saveID = this.docID[selected].id;
				const saveitems_item = this.docID_Items_Info;
				// const saveItems = this.docID[selected].Items;
				// const length = this.docID[selected].Items.length;
				const pushItems = this.selectedRowsPush;

				var itemLength = this.dbResult.length;
				var selectLength = this.selectedRowsPush.length;

				for (let i = 0; i < selectLength; i++) {
					console.log(pushItems[i]);
					console.log(this.checkuser);
					pushItems[i].report_check = this.checkuser;
					// console.log(statusLength);
				}

				var mergeResult = _.merge(
					_.keyBy(this.dbResult, "model"),
					_.keyBy(this.selectedRowsPush, "model")
				);
				console.log(mergeResult);

				var mergeResultSet = _.values(mergeResult);

				console.log(this.selectedRowsPush);
				console.log(mergeResultSet);

				var statusLength = [];

				for (let i = 0; i < mergeResultSet.length; i++) {
					var statusCount = mergeResultSet[i].checked;
					statusLength.push(statusCount);
					// mergeResultSet[i].report_check = this.checkUser;
					// console.log(statusLength);
				}

				// console.log(this.statusLengthTrue.length);

				var db = firebase.firestore();
				var date = new Date();
				var trueLength = this.statusLengthTrue.length;

				// console.log(itemLength, selectLength, trueLength);

				const self = this;

				function asynchronyFunction() {
					return new Promise((resolve) => {
						setTimeout(() => {
							if (itemLength == selectLength) {
								db.collection("rentals").doc(saveID).update({
									status: true,
									checkuser: self.checkuser,
									Items: mergeResultSet,
								});
							} else if (itemLength >= selectLength) {
								db.collection("rentals").doc(saveID).update({
									checkuser: self.checkuser,
									Items: mergeResultSet,
								});
							}
							if (itemLength == trueLength) {
								db.collection("rentals").doc(saveID).update({
									status: true,
									checkuser: self.checkuser,
									Items: mergeResultSet,
								});
							}
							resolve();
						}, 100);
					})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									if (itemLength == selectLength) {
										db.collection("calendar")
											.where("id", "==", saveID)
											.get()
											.then((querySnapshot) => {
												querySnapshot.forEach((doc) =>
													doc.ref.update({
														status: true,
													})
												);
											});
									} else if (itemLength > selectLength) {
										// Modelでフィルタリングする
										for (let i = 0; i < selectLength; i++) {
											const modelName = pushItems[selectLength - 1].model;
											// console.log(modelName);
											db.collection("calendar")
												.where("id", "==", saveID)
												.where("model", "==", modelName)
												.get()
												.then((querySnapshot) => {
													querySnapshot.forEach((doc) =>
														doc.ref.update({
															status: true,
														})
													);
												});

											// .then((querySnapshot) => {
											// 	querySnapshot.forEach((doc) =>
											// 		doc.ref.update({
											// 			status: true,
											// 		})
											// 	);
											// });
										}
									}
									if (itemLength == trueLength) {
										db.collection("calendar")
											.where("id", "==", saveID)
											.get()
											.then((querySnapshot) => {
												querySnapshot.forEach((doc) =>
													doc.ref.update({
														status: true,
													})
												);
											});
									}
									// db.collection("calendar")
									// 	.where("id", "==", saveID)
									// 	.get()
									// 	.then((querySnapshot) => {
									// 		querySnapshot.forEach((doc) =>
									// 			doc.ref.update({
									// 				status: true,
									// 			})
									// 		);
									// 	});
									resolve();
								}, 100);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									if (self.sendAlert === null) {
										self.todos.splice(selected + 1, 1);
										// setTimeout(
										// 	self.$router.go({
										// 		path: self.$router.currentRoute.path,
										// 		force: true,
										// 	}),
										// 	500
										// );
									} else {
										// console.log("ok");

										var sendAlert = self.sendAlert.length;
										var sendAlertArr = self.sendAlert;

										var params = {
											text: "機材の異常をお知らせします",
											blocks: [
												{
													type: "header",
													text: {
														type: "plain_text",
														text: "機材の異常をお知らせします",
														emoji: true,
													},
												},
												{
													type: "divider",
												},
												{
													type: "section",
													text: {
														type: "plain_text",
														text: "報告された日時",
														emoji: true,
													},
												},
												{
													type: "context",
													elements: [
														{
															type: "plain_text",
															text: date.toLocaleString("ja"),
															emoji: true,
														},
													],
												},
												{
													type: "section",
													text: {
														type: "plain_text",
														text: "案件名",
														emoji: true,
													},
												},
												{
													type: "context",
													elements: [
														{
															type: "plain_text",
															text: saveitems_item.projects,
															emoji: true,
														},
													],
												},
												{
													type: "section",
													text: {
														type: "plain_text",
														text: "報告者",
														emoji: true,
													},
												},
												{
													type: "context",
													elements: [
														{
															type: "plain_text",
															text: saveitems_item.user,
															emoji: true,
														},
													],
												},
											],
										};
										var paramsAlertArr = [];

										for (let i = 0; i < sendAlert; i++) {
											var paramsAlert = [
												{
													type: "section",
													text: {
														type: "plain_text",
														text: "異常が報告された機材",
														emoji: true,
													},
												},
												{
													type: "context",
													elements: [
														{
															type: "plain_text",
															text: sendAlertArr[i].key,
															emoji: true,
														},
													],
												},
												{
													type: "context",
													elements: [
														{
															type: "plain_text",
															text: sendAlertArr[i].value,
															emoji: true,
														},
													],
												},
											];
											paramsAlertArr.push(paramsAlert);

											// store.edit("db_all", {
											// 	search: { id: sendAlertArr[i].id },
											// 	set: {
											// 		status: "err",
											// 	},
											// });
											// console.log(sendAlertArr[i].id);
										}

										var paramsArrflat = _.flatten(paramsAlertArr);

										params.blocks.push(...paramsArrflat);
										// console.log(params);

										// eslint-disable-next-line no-inner-declarations
										function slack(payload) {
											var WEBHOOK_URL =
												"https://hooks.slack.com/services/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";
											const res = axios.post(
												WEBHOOK_URL,
												JSON.stringify(payload),
												{
													withCredentials: false,
													transformRequest: [
														(data, headers) => {
															delete headers.common.Authorization;
															delete headers.post["Content-Type"];
															return data;
														},
													],
												}
											);
											return res.data;
										}

										// slack(params).then(console.log);
										slack(params);

										self.todos.splice(selected + 1, 1);

										// setTimeout(
										// 	self.$router.go({
										// 		path: self.$router.currentRoute.path,
										// 		force: true,
										// 	}),
										// 	500
										// );
									}
									return (
										[
											(self.snackbar = true),
											(self.text = "アーカイブしました"),
										],
										resolve()
									);
								}, 100);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									self.$router.go({
										path: self.$router.currentRoute.path,
										force: true,
									}),
										resolve();
								}, 100);
							});
						})

						.catch(() => {
							// console.log(reason.messege);
						});
				}
				asynchronyFunction();
			},

			clickRowDefault: function (item) {
				// const store = new SteinStore(
				// 	"https://api.steinhq.com/v1/storages/60f67184d2a8585c5af283f3"
				// );
				const selected = this.docID.indexOf(item);
				const saveID = this.docID[selected].id;
				// const saveitems_item = this.docID_Items_Info;

				var db = firebase.firestore();
				// var date = new Date();

				db.collection("rentals")
					.doc(saveID)
					.update({
						status: true,
						checkuser: this.checkuser,
					})
					.then(() => {
						// console.log(res);
						return [(this.snackbar = true), (this.text = "アーカイブしました")];
					});
			},

			clickRowInfo: function (item) {
				// const selected = this.docID.indexOf(item);
				// const saveitems = this.docID[selected].Items;

				// return [
				// 	(this.snackbar2 = true),
				// 	(this.text2 = "詳細を表示しています"),
				// 	(this.docID_Items = saveitems),
				// ];
				// var sendAlertArr = this.sendAlert;
				// for (let i = 0; i < sendAlertArr.length; i++) {
				// 	console.log(sendAlertArr[i].id);
				// }

				if (this.dialogInfo_return_due == false) {
					this.ListInfo.splice(0, this.ListInfo.length);
					this.ListInfo_A.splice(0, this.ListInfo_A.length);
				}
				this.$store.commit("set_dialogInfo_return_due", true);
				this.ListInfo.push(...item.Items);
				this.ListInfo_A.push(...[item]);
			},

			clickRowInfoSave: function (item) {
				if (this.dialogInfo_return_info == false) {
					this.ListInfo.splice(0, this.ListInfo.length);
					this.ListInfo_A.splice(0, this.ListInfo_A.length);
				}
				this.$store.commit("set_dialogInfo_return_info", true);
				this.ListInfo.push(...item.Items);
				this.ListInfo_A.push(...[item]);
			},

			clickReturnInfo: function (item) {
				const selected = this.docID.indexOf(item);
				const saveitems = this.docID[selected].Items;

				this.dbResult = this.docID[selected].Items;
				this.dbResultFilter = this.docID[selected].Items.concat();

				// console.log(saveitems);

				const saveitems_item = this.docID[selected];
				this.selectedDoc = saveitems_item;

				var trueLength = this.statusLengthTrue.length;

				// console.log(trueLength);

				// this.dbSelected = this.docID_Items.indexOf(item);

				// this.dbResult = this.docID[this.dbSelected].Items;

				if (trueLength >= 1) {
					this.vForm = true;
					// console.log(trueLength);
				} else if (trueLength == 0) {
					this.vForm = false;
					// console.log(trueLength);
				}

				// const arrIndex = saveitems.findIndex(
				// 	({ arr }) => arr.projects === "テスト"
				// );

				// console.log(arrIndex);

				// console.log(saveitems_item);

				if (this.dialog2 == true) {
					this.docID_Items_Info = null;
				} else if (this.dialog2 == false) {
					this.docID_Items_Info = saveitems_item;
				}

				return [
					(this.snackbar2 = true),
					(this.text2 = "詳細を表示しています"),
					(this.docID_Items = saveitems),
				];
			},

			clickDoneInfo: function (item) {
				if (this.dialogInfo_done_info == false) {
					this.ListInfo.splice(0, this.ListInfo.length);
					this.ListInfo_A.splice(0, this.ListInfo_A.length);
				}
				this.$store.commit("set_dialogInfo_done_info", true);
				this.ListInfo.push(...item.Items);
				this.ListInfo_A.push(...[item]);
			},

			clickItemStatus: function (item, index) {
				if (this.dialogInfo_return_return == false) {
					this.ListStatus.splice(0, this.ListStatus.length);
				}
				this.$store.commit("set_dialogInfo_return_return", true);
				this.ListStatus.push(index, ...[item]);
			},

			// clickItemDontReturn: function(item) {
			// 	var db = firebase.firestore();

			// 	const selected = this.docID_Items.indexOf(item);
			// 	const saveModel = this.docID_Items[selected].model;
			// 	const indexNum = this.docID_Items.indexOf();

			// 	const saveID = this.selectedDoc.id;
			// 	const saveItems = this.selectedDoc.Items;
			// 	// const selectsaveItems = saveItems[selected];

			// 	// console.log(item);
			// 	// console.log(selectsaveItems);
			// 	// console.log(saveModel);
			// 	// console.log(indexNum);

			// 	// selectsaveItems.splice(0, 0, "test");

			// 	this.$set(saveItems[selected], "model", 0);
			// 	// this.$set(saveItems[selected], "returnstatus", false);

			// 	// console.log(saveItems);

			// 	// var db = firebase.firestore();
			// 	db.collection("rentals")
			// 		.where("id", "==", saveID)
			// 		// .doc(saveID)
			// 		.get("Items")
			// 		.then((querySnapshot) => {
			// 			querySnapshot.forEach((doc) =>
			// 				// doc.ref.update({
			// 				// 	Items: [{ who: "third@test.com", when: new Date() }],
			// 				// })
			// 				// data.push(doc)
			// 				console.log(doc.data().Items)
			// 			);
			// 		});
			// },

			statusChange: function () {
				// const selected = this.docID_Items.indexOf(item);
				// const saveitems = this.docID[selected + 1];

				const saveitems_item = this.docID_Items_Info;
				// const listitems = this.docID_Items;

				// console.log(this.docID_Items[selected]);

				// console.log(saveitems_item);

				var date = new Date();
				// date.toLocaleString("ja");

				// let sendsD = this.statusDiscription;
				let sendUser = this.user.displayName;
				let sendStatus = this.statusArr;

				// let sendStatusC = JSON.parse(sendStatus);

				const Arr = sendStatus.map((item) => {
					return { 機材名: item.key, 報告内容: item.value };
				});

				// console.log(Arr);

				async function slack(payload) {
					const webhookUrl =
						"https://hooks.slack.com/services/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";
					const res = await axios.post(webhookUrl, JSON.stringify(payload));
					return res.data;
				}

				slack({
					username: "hello-slack-axios.js",
					channel: "the-channel",
					text: `【機材の問題が発生しました】\n日時：${date.toLocaleString(
						"ja"
					)}\n案件名：${
						saveitems_item.projects
					}\n報告者：${sendUser}\n【報告された機材】\n${JSON.stringify(Arr)}`,
				}).then();

				this.$refs.form.validate();
				// console.log(item);

				// var db = firebase.firestore();

				// db.collection("rentals")
				// 	.doc(saveID)
				// 	.update({
				// 		status: true,
				// 	});
			},

			pushKizaiItem: function (item) {
				var db = firebase.firestore();

				const selected = this.docID_Items.indexOf(item);
				const saveitems = this.docID_Items[selected];

				this.statusArr.push({ key: saveitems.model, value: this.statusText });

				db.collection("rentals").doc("jOgcjfZwc6bETO6NBagb").get();
				// .then((queryResult) => {
				// 	console.log(queryResult.data().Items[selected]);
				// });
			},

			clearKizaiItem: function () {
				this.$refs.form2.reset();
			},

			checkUser: function () {
				// const checkuservalue = this.checkUser;
			},

			changeDates: function (item) {
				var db = firebase.firestore();

				const selected = this.docID.indexOf(item);
				const saveID = this.docID[selected].id;

				db.collection("rentals")
					.doc(saveID)
					.update({
						return_date: this.date,
					})
					.then(() => {
						this.$router.go({
							path: this.$router.currentRoute.path,
							force: true,
						});
					});
			},

			addStatusMethod(...args) {
				console.log(args);
				this.docID_Items[args[1]].report = args[0];
			},

			test: function () {
				this.menu = true;
			},

			resetForm: function () {
				this.$refs.child.resetChildForm();
			},

			// ログイン処理
			doLogin() {
				Firebase.login();
			},
			// ログアウト処理
			doLogout() {
				Firebase.logout();
			},
		},

		// mounted() {
		// 	if (this.selectedRows.length == this.docID_Items.length) {
		// 		this.returnBtn = true;
		// 	} else {
		// 		this.returnBtn = false;
		// 	}
		// },
		updated() {
			if (this.docID_Items.length === this.selectedRows.length) {
				this.returnBtn = true;
			} else {
				this.returnBtn = false;
			}
		},
		computed: {
			rules() {
				return [
					this.selected.length > 0 || "At least one item should be selected",
				];
			},
			user() {
				return this.$store.getters.user;
			},
			userStatus() {
				// ログインするとtrue
				return this.$store.getters.isSignedIn;
			},
			falseFilterItems() {
				return this.docID.filter((item) => item.status === "false");
			},
			statusDataFilter() {
				return Object.entries(this.pushAlert).map((item) => item[1]);
			},
			// dateRangeText() {
			// 	return this.date;
			// },
			dialogInfo_return_info() {
				return this.$store.getters.get_dialogInfo_return_info;
			},
			dialogInfo_return_due() {
				return this.$store.getters.get_dialogInfo_return_due;
			},
			dialogInfo_return_return() {
				return this.$store.getters.get_dialogInfo_return_return;
			},
			dialogInfo_done_info() {
				return this.$store.getters.get_dialogInfo_done_info;
			},
			docID_Items_returnFilter() {
				return this.dbResultFilter.filter(
					(item) => item.returnstatus === false
				);
			},

			statusLengthTrue() {
				return this.dbResultFilter.filter((item) => item.checked === true);
			},
		},
	};
</script>
<style>
	.statusBtn {
		border-radius: 50%;
	}
	.menuStyle {
		background-color: #fff;
		border-radius: 0px;
		padding: 10px;
	}

	.v-menu__content {
		background-color: #fff;
		border-radius: 0px;
		padding: 10px;
	}
	button.datetimeex {
		background-color: #fff;
	}
	.titleColor {
		background-color: #4c4c4c;
		color: #fff;
	}
	.done {
		border-radius: 50px;
	}
</style>

<style scoped>
	.mobileList {
		border-left: 2px solid #262626;
		padding: 2px 8px;
	}
</style>

<style scoped>
	.v-list-item__content {
		align-items: flex-start !important;
	}
	::v-deep .v-dialog.v-dialog--active {
		position: relative;
		top: -10%;
		margin: 12px !important;
	}

	::v-deep .mobileCheckbox {
		margin-top: 0px;
		padding-bottom: 4px;
	}

	/* :v-deep .returnDialogList {
		padding-bottom: 0px;
	} */

	::v-deep .returnDialogListContent {
		padding-bottom: 0px;
	}

	@media screen and (max-width: 960px) {
		::v-deep .nestDialog {
			top: 0% !important;
		}
	}
	::v-deep .v-list-item__subtitle {
		white-space: normal;
	}
</style>
