<template>
	<div>
		<v-footer fixed color="transparent" padless>
			<v-container
				class="d-flex justify-end pb-0 pr-0 pr-sm-3 pr-lg-3 pr-xl-3 pl-0 pl-sm-3 pl-lg-3 pl-xl-3"
			>
				<v-card elevation="15" width="480">
					<v-expansion-panels accordion flat v-model="exList">
						<v-expansion-panel>
							<v-expansion-panel-header>List</v-expansion-panel-header>
							<v-expansion-panel-content>
								<template>
									<v-data-table
										v-if="$vuetify.breakpoint.mdAndUp"
										:headers="headers"
										:items="addcartItems"
										:items-per-page="-1"
										hide-default-header
										hide-default-footer
										class="elevation-0"
									>
										<template v-slot:item.model="props">
											<span>{{ props.item.model }}</span
											><span v-if="props.item.size"
												>&nbsp;{{ props.item.size }}m</span
											>
										</template>
										<template v-slot:item.quantity="props">
											<v-text-field
												v-model.number="props.item.quantity"
												label="個数"
												single-line
												type="Number"
												class="itemField"
												min="1"
												:max="props.item.stock"
												onkeypress="return event.charCode >= 48"
												onkeydown="return event.keyCode !== 69"
											></v-text-field>
										</template>
										<template v-slot:item.info="{ item }">
											<v-btn
												outlined
												color="#262626"
												style="text-transform: none"
												small
												@click="$store.commit('removeFromCart', item)"
												class="rounded-0"
												><v-icon>mdi-close</v-icon></v-btn
											>
										</template>
									</v-data-table>
									<v-simple-table v-if="!$vuetify.breakpoint.mdAndUp" dense>
										<template v-slot:default>
											<tbody>
												<tr v-for="item in addcartItems" :key="item.name">
													<td class="text-body-2">
														<span v-if="item.manufacturer"
															>{{ item.manufacturer }}<br /></span
														><span>{{ item.model }}</span
														><span v-if="item.size"
															>&nbsp;{{ item.size }}m</span
														>
													</td>
													<td>
														<v-text-field
															v-model.number="item.quantity"
															label="個数"
															single-line
															type="Number"
															class="itemField text-body-2"
															min="1"
															:max="item.stock"
															onkeypress="return event.charCode >= 48"
															onkeydown="return event.keyCode !== 69"
														></v-text-field>
													</td>
													<td>
														<v-btn
															outlined
															color="#262626"
															style="text-transform: none"
															x-small
															fab
															@click="$store.commit('removeFromCart', item)"
															class="rounded-0"
															><v-icon>mdi-close</v-icon></v-btn
														>
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</template>

								<v-btn
									@click.stop="
										$store.commit('setDialogInfo', true), checkoutClick()
									"
									class="checkoutBtn rounded-0"
									block
									elevation="0"
									color="black"
									:disabled="!vForm"
									>Checkout</v-btn
								>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<!-- {{ dialogInfo }} -->
				</v-card>
			</v-container>
		</v-footer>
		<v-dialog
			v-model="dialogInfo"
			max-width="1024px"
			border-radius="0px"
			@click:outside="$store.commit('setDialogInfo', false)"
			style="border-radius: 0px; overflow-y: hidden !important"
			content-class="checkoutDialog"
			v-if="dialogInfo"
		>
			<Checkout />
		</v-dialog>
	</div>
</template>

<script>
	import Checkout from "./Chackout";
	export default {
		name: "Footer",
		components: {
			Checkout,
		},
		methods: {
			onSubmit() {
				this.dialog = false;
			},
			mouseOverAction() {
				this.hoverFlag = true;
			},
			mouseLeaveAction() {
				this.hoverFlag = false;
			},
			exClick() {
				this.$store.commit("setexList", null);
			},
			vFormFalse() {
				this.vForm = false;
			},
			vFormTrue() {
				this.vForm = true;
			},
			checkoutClick() {
				this.$router.push({
					path: "/rent",
				});
			},
		},
		computed: {
			addcartItems() {
				return this.$store.getters.addcartItems;
			},
			dialogInfo() {
				return this.$store.getters.getDialogInfo;
			},
			dialogInfo_i() {
				return this.$store.getters.getDialogInfo_i;
			},
			exList() {
				return this.$store.getters.getexList;
			},
			// vForm() {
			// 	if (
			// 		this.addcartItems == null ||
			// 		this.addcartItems == undefined ||
			// 		!this.addcartItems
			// 	) {
			// 		return true;
			// 	} else {
			// 		return false;
			// 	}
			// },
		},
		updated() {
			if (
				this.addcartItems == null ||
				this.addcartItems == undefined ||
				!this.addcartItems ||
				this.addcartItems.length == 0
			) {
				// console.log(this.addcartItems.length);
				return this.vFormFalse();
			} else {
				// console.log(this.addcartItems.length);
				return this.vFormTrue();
			}
		},
		created() {
			if (
				this.addcartItems == null ||
				this.addcartItems == undefined ||
				!this.addcartItems ||
				this.addcartItems.length == 0
			) {
				// console.log(this.addcartItems.length);
				return this.vFormFalse();
			} else {
				// console.log(this.addcartItems.length);
				return this.vFormTrue();
			}
		},
		mounted() {
			this.reloadCheckout = true;
		},
		data: () => ({
			dialog: false,
			hoverFlag: false,

			panel: null,

			exTest: null,

			vForm: false,

			reloadCheckout: false,

			headers: [
				{
					text: "manufacturer",
					value: "manufacturer",
					align: "start",
					sortable: false,
				},
				{ text: "model", value: "model" },
				{ text: "quantity", value: "quantity" },
				{ text: "Info", value: "info" },
			],
		}),
	};
</script>
<style>
	.footerCard .v-card {
		justify-content: flex-end;
	}
	.checkoutBtn span {
		color: #fff;
	}
	.v-dialog {
		border-radius: 0px;
	}
	.itemField {
		width: 40px;
	}
	.checkoutDialog {
		/* position: relative;
		top: -10%; */
	}
	.tooltip {
		display: block !important;
		z-index: 10000;

		.tooltip-inner {
			background: black;
			color: white;
			border-radius: 16px;
			padding: 5px 10px 4px;
		}

		.tooltip-arrow {
			width: 0;
			height: 0;
			border-style: solid;
			position: absolute;
			margin: 5px;
			border-color: black;
			z-index: 1;
		}

		&[x-placement^="top"] {
			margin-bottom: 5px;

			.tooltip-arrow {
				border-width: 5px 5px 0 5px;
				border-left-color: transparent !important;
				border-right-color: transparent !important;
				border-bottom-color: transparent !important;
				bottom: -5px;
				left: calc(50% - 5px);
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		&[x-placement^="bottom"] {
			margin-top: 5px;

			.tooltip-arrow {
				border-width: 0 5px 5px 5px;
				border-left-color: transparent !important;
				border-right-color: transparent !important;
				border-top-color: transparent !important;
				top: -5px;
				left: calc(50% - 5px);
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		&[x-placement^="right"] {
			margin-left: 5px;

			.tooltip-arrow {
				border-width: 5px 5px 5px 0;
				border-left-color: transparent !important;
				border-top-color: transparent !important;
				border-bottom-color: transparent !important;
				left: -5px;
				top: calc(50% - 5px);
				margin-left: 0;
				margin-right: 0;
			}
		}

		&[x-placement^="left"] {
			margin-right: 5px;

			.tooltip-arrow {
				border-width: 5px 0 5px 5px;
				border-top-color: transparent !important;
				border-right-color: transparent !important;
				border-bottom-color: transparent !important;
				right: -5px;
				top: calc(50% - 5px);
				margin-left: 0;
				margin-right: 0;
			}
		}

		&.popover {
			$color: #f9f9f9;

			.popover-inner {
				background: $color;
				color: black;
				padding: 24px;
				border-radius: 5px;
				box-shadow: 0 5px 30px rgba(black, 0.1);
			}

			.popover-arrow {
				border-color: $color;
			}
		}

		&[aria-hidden="true"] {
			visibility: hidden;
			opacity: 0;
			transition: opacity 0.15s, visibility 0.15s;
		}

		&[aria-hidden="false"] {
			visibility: visible;
			opacity: 1;
			transition: opacity 0.15s;
		}
	}
</style>
<style scoped>
	.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
	.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
	.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
	.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
		padding: 0 4px;
	}
	::v-deep .v-dialog {
		margin: 0px !important;
	}
</style>
