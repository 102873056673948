<template>
	<div>
		<List />
		<v-dialog v-model="menuDialog" width="500">
			<Menu />
		</v-dialog>
		<v-overlay :opacity="1" :value="overlay">
			<v-progress-circular indeterminate size="64"> </v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
	import List from "../components/List";
	import Menu from "../components/common/Menu";
	export default {
		name: "Rent",

		components: {
			List,
			Menu,
		},
		computed: {
			menuDialog: {
				get: function() {
					return this.$store.getters.getmenuDialog;
				},
			},
		},
		methods: {
			onKeyDown(event) {
				if (event.ctrlKey) {
					if (event.altKey) {
						if (event.which === 53) {
							this.$store.commit("setmenuDialog", true);
						}
					}
				}
			},
		},
		mounted() {
			document.addEventListener("keydown", this.onKeyDown);
			this.$nextTick(function() {
				setTimeout(() => {
					this.overlay = false;
				}, 2000);
			});
		},
		beforeDestroy() {
			document.removeEventListener("keydown", this.onKeyDown);
		},
		beforeRouteLeave(to, from, next) {
			this.$store.commit("setmenuDialog", false);
			next();
		},
		data: () => ({
			overlay: true,
		}),
	};
</script>
