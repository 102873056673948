<template>
	<div>
		<v-container class="pa-0 ma-0">
			<v-card flat width="100vw" class="py-4 px-4 py-md-8 px-md-8">
				<v-form ref="form" v-model="vForm">
					<v-row>
						<v-col cols="12" md="6" lg="6" xl="6">
							<v-combobox
								v-model="model"
								:items="dbItemsOutputModel_ab"
								item-text="model"
								:return-object="false"
								label="機材名（入力必須）"
								clearable
								:rules="[(v) => !!v || '必ず入力してください']"
								required
								prepend-icon="mdi-alphabetical"
							></v-combobox>
						</v-col>

						<v-col cols="12" md="6" lg="6" xl="6">
							<v-combobox
								v-model="manufacturer"
								:items="dbItemsOutputManu"
								item-text="manufacturer"
								:return-object="false"
								label="メーカー名"
								clearable
								prepend-icon="mdi-alphabetical"
							></v-combobox>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="6" lg="6" xl="6">
							<v-text-field
								v-model="model_number"
								label="モデル番号"
								prepend-icon="mdi-numeric"
								clearable
							></v-text-field>
						</v-col>

						<v-col cols="12" md="6" lg="6" xl="6">
							<v-text-field
								v-model="stock"
								:rules="[(v) => !!v || '必ず入力してください']"
								label="個数（入力必須）"
								type="number"
								required
								prepend-icon="mdi-numeric"
								clearable
								onkeypress="return event.charCode >= 48"
								onkeydown="return event.keyCode !== 69"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="6" lg="6" xl="6">
							<v-combobox
								v-model="category"
								:items="dbCategoryOutput_Select"
								item-text="text"
								item-value="value"
								:return-object="true"
								label="カテゴリー（選択必須）"
								clearable
								:rules="[(v) => !!v || '必ず選択してください']"
								required
								prepend-icon="mdi-folder-multiple-outline"
								@change="changeCategoryValue"
							></v-combobox>
						</v-col>
						<v-col cols="12" md="6" lg="6" xl="6">
							<v-text-field
								v-if="cableHide"
								v-model="size"
								label="ケーブル長（メートル換算）"
								type="number"
								step="0.1"
								prepend-icon="mdi-tape-measure"
								clearable
								hint="必ずメートル（m）換算で入力してください"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="6" lg="6" xl="6">
							<v-combobox
								v-model="location_repo"
								:items="dbLocationRepoOutput"
								item-text="name"
								:return-object="true"
								label="保管場所（入力必須）"
								clearable
								:rules="[(v) => !!v || '必ず入力してください']"
								required
								prepend-icon="mdi-folder-marker-outline"
							></v-combobox>
						</v-col>

						<v-col cols="12" md="6" lg="6" xl="6">
							<v-select
								v-model="location_box"
								:items="dbLocationBoxOutput"
								item-text="name"
								:return-object="true"
								label="棚区画（入力必須）"
								clearable
								:rules="[(v) => !!v || '必ず入力してください']"
								required
								prepend-icon="mdi-folder-marker-outline"
							></v-select>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="6" lg="6" xl="6">
							<v-menu
								ref="menu2"
								v-model="menu2"
								:close-on-content-click="false"
								:return-value.sync="date2"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="date2"
										label="登録日（入力必須）"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
										:rules="[(v) => !!v || '必ず入力してください']"
									></v-text-field>
								</template>
								<v-date-picker v-model="date2" no-title scrollable>
									<v-spacer></v-spacer>
									<v-btn text color="primary" @click="menu2 = false">
										Cancel
									</v-btn>
									<v-btn text color="primary" @click="$refs.menu2.save(date2)">
										OK
									</v-btn>
								</v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" md="6" lg="6" xl="6">
							<v-menu
								ref="menu"
								v-model="menu"
								:close-on-content-click="false"
								:return-value.sync="date"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="date"
										label="購入日"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker v-model="date" no-title scrollable>
									<v-spacer></v-spacer>
									<v-btn text color="primary" @click="menu = false">
										Cancel
									</v-btn>
									<v-btn text color="primary" @click="$refs.menu.save(date)">
										OK
									</v-btn>
								</v-date-picker>
							</v-menu>
						</v-col>
					</v-row>

					<v-card flat v-if="descriptionRender">
						<v-row>
							<v-col cols="12">
								<!-- 入力フォーム -->
								<v-form v-on:submit.prevent="addItem">
									<v-text-field
										type="text"
										v-model="text"
										label="付属品"
										v-on:keyup.enter="changeItems"
										ref="editor"
										prepend-icon="mdi-clipboard-list-outline"
									>
										<template v-slot:append-outer>
											<v-btn
												outlined
												color="#262626"
												style="text-transform: none"
												elevation="0"
												v-on:click="setItems"
												type="submit"
												class="rounded-0"
											>
												付属品を追加
											</v-btn>
										</template>
									</v-text-field>
								</v-form>
							</v-col>
							<v-col cols="12">
								<!--ループ処理-->
								<v-simple-table>
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left">付属品の名称</th>
												<th class="text-left">編集</th>
												<th class="text-left">削除</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in items" v-bind:key="index">
												<td>{{ item }}</td>
												<td>
													<v-btn
														outlined
														color="#262626"
														style="text-transform: none"
														v-on:click="edit(index)"
														class="rounded-0"
														>編集</v-btn
													>
												</td>
												<td>
													<v-btn
														outlined
														color="#262626"
														style="text-transform: none"
														v-on:click="remove(index)"
														class="rounded-0"
														>削除</v-btn
													>
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-card>

					<v-row>
						<v-col cols="12" md="4" lg="4" xl="4">
							<v-file-input
								label="機材のメイン画像を追加"
								prepend-icon="mdi-camera"
								type="file"
								accept="image/png, image/jpeg, image/bmp"
								@change="onFilePicked_0"
								ref="fileupload_0"
								chips
							></v-file-input>
						</v-col>

						<v-col cols="12" md="4" lg="4" xl="4">
							<v-file-input
								label="付属品の画像を追加"
								prepend-icon="mdi-camera"
								type="file"
								accept="image/png, image/jpeg, image/bmp"
								@change="onFilePicked_1"
								ref="fileupload_1"
								chips
							></v-file-input>
						</v-col>

						<v-col cols="12" md="4" lg="4" xl="4">
							<v-file-input
								label="収納方法の画像を追加"
								prepend-icon="mdi-camera"
								type="file"
								accept="image/png, image/jpeg, image/bmp"
								@change="onFilePicked_2"
								ref="fileupload_2"
								chips
							></v-file-input>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<v-text-field
								v-model="rentalfee"
								label="レンタル費"
								type="number"
								prepend-icon="mdi-currency-jpy"
								clearable
								onkeypress="return event.charCode >= 48"
								onkeydown="return event.keyCode !== 69"
							></v-text-field>
						</v-col>
					</v-row>
					<!-- <v-row>
						<v-col class="d-flex justify-center align-center"
							><v-btn
								@click="qrCodeGenerate"
								outlined
								color="#262626"
								style="text-transform: none"
								large
								class="rounded-0"
								>QRコードを生成</v-btn
							></v-col
						>
					</v-row>
					<v-row>
						<v-col class="d-flex justify-center align-center">
							<div v-show="this.qrCodeRender">
								<v-card max-width="256" flat color="transparent">
									<div v-if="qrCodeimgRender">
									<qrcode
										:value="this.qrCodeparams"
										:options="{ width: 512 }"
										id="qrCodeCanvas"
										class="qrCode"
									></qrcode>
									</div>
									<v-btn
										:href="this.qrCodeURL"
										download
										outlined
										color="#262626"
										style="text-transform: none"
										large
										class="rounded-0 d-flex justify-center align-center"
										>画像をダウンロード</v-btn
									>
								</v-card>
							</div>
						</v-col>
					</v-row> -->
					<v-row class="justify-center mt-6 mb-2">
						<v-btn
							outlined
							color="#262626"
							style="text-transform: none"
							large
							class="registerBtn rounded-0"
							:loading="loadingValidate"
							:disabled="!vForm"
							@click="validate"
						>
							機材を追加する
						</v-btn>
					</v-row>
				</v-form>
			</v-card>
		</v-container>

		<v-snackbar :timeout="3000" :top="true" v-model="addItem_Notification">
			{{ addItem_Notification_Text }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="red"
					text
					v-bind="attrs"
					@click="addItem_Notification = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
		<v-snackbar :timeout="3000" :top="true" v-model="addItem_Notification_err">
			{{ addItem_Notification_Text }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="red"
					text
					v-bind="attrs"
					@click="addItem_Notification_err = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>

		<v-snackbar
			:timeout="3000"
			:top="true"
			v-model="qrGenerate_Notification_err"
		>
			{{ qrGenerate_Notification_Text }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="red"
					text
					v-bind="attrs"
					@click="qrGenerate_Notification_err = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
	import firebase from "firebase";
	import axios from "axios";
	var CRC32 = require("crc-32");
	import _ from "lodash";

	export default {
		name: "Uploader",
		components: {
			// FilePond,
		},

		data: () => ({
			//ADD DESCRIPTION
			text: "", // 編集覧の値
			editIndex: -1, // 現在編集している要素のインデックス ,編集状態でない場合は-1を設定
			items: [],
			files: [],

			//FORM VALUE
			form: [
				{
					model: "",
					manufacturer: "",
					size: "",
					model_number: "",
					serial: "",
					number: "",
					location_repo: "",
					location_box: "",
					status: "",
					category: "",
					purchase_date: "",
					stock: "",
					description: "",
				},
			],

			// model: "",
			// manufacturer: "",
			size: "",
			model_number: "",
			serial: "",
			number: "",
			location_repo: "",
			location_box: "",
			status: "",
			// category: "",
			purchase_date: "",
			stock: "",
			description: "",

			category: "",
			rentalfee: "",

			uniqueID: "",
			uniqueIDHash: "",
			model: "",
			manufacturer: "",

			//FIERBASE IMAGE
			imageName_0: "",
			imageUrl_0: "",
			imageFile_0: "",

			//FIERBASE IMAGE
			imageName_1: "",
			imageUrl_1: "",
			imageFile_1: "",

			//FIERBASE IMAGE
			imageName_2: "",
			imageUrl_2: "",
			imageFile_2: "",

			menu: false,
			menu2: false,

			loadingValidate: false,
			vForm: false,

			addItem_Notification: false,
			addItem_Notification_Text: "機材が登録されました",

			addItem_Notification_err: false,
			addItem_Notification_err_Text: "機材の登録に失敗しました",

			qrGenerate_Notification_err: false,
			qrGenerate_Notification_Text: "機材名の入力をしてください",

			uploadURL: "",
			uploadURL1: "",
			uploadURL2: "",

			equipmentsDB: "",
			dbitems: [],
			testValue: "",

			// ケーブル長フォーム表示切り替え
			cableHide: false,

			// 付属品フォーク再レンダリング用
			descriptionRender: true,

			// QRコードレンダリング用
			qrCodeRender: false,
			qrCodeparams: null,
			qrCodeURL: null,
		}),

		created() {
			this.$store.dispatch("getdbItems");
			this.$store.dispatch("getdbCategory");
			this.$store.dispatch("getdbLocationRepo");
			this.$store.dispatch("getdbLocationBox");

			axios.get(axios.defaults.baseURL).then((response) => {
				this.equipmentsDB = response.data.documents;
			});

			const db = firebase.firestore();
			// データ取得
			db.collection("equipments")
				.get()
				.then((querysnapshot) => {
					querysnapshot.forEach((doc) => {
						this.dbitems.push(doc.data());
					});
				})
				.catch(() => {
					this.dbitems = [];
				});
		},

		methods: {
			// 送信ボタンをクリックしたら以下を実行
			setItems() {
				if (this.editIndex === -1) {
					// 配列に要素を追加
					this.items.push(this.text);
				} else {
					// 編集（指定位置から指定した要素数を削除し新しい要素に入れ替え）
					this.items.splice(this.editIndex, 1, this.text);
				}
				this.cancel();
			},

			// クリア
			cancel() {
				this.text = ""; //編集覧の状態をクリア
				this.editIndex = -1;
			},

			// 編集（指定した要素を編集状態に設定）
			edit(index) {
				this.editIndex = index;
				this.text = this.items[index];
				this.$refs.editor.focus(); // フォーカスを設定
			},

			// 削除
			remove(index) {
				this.items.splice(index, 1);
			},
			delimit(v) {
				const reducer = (a, e) => [...a, ...e.split(/[, ]+/)];
				this.description = [...new Set(v.reduce(reducer, []))];
			},

			//画像（1枚目）
			onFilePicked_0(file) {
				if (file !== undefined) {
					this.imageName_0 = file.name;
					if (this.imageName_0.lastIndexOf(".") <= 0) {
						return;
					}
					const fr = new FileReader();
					fr.readAsDataURL(file);
					fr.addEventListener("load", () => {
						this.imageUrl_0 = fr.result;
						this.imageFile_0 = file;
					});
				} else {
					this.imageName_0 = "";
					this.imageFile_0 = "";
					this.imageUrl_0 = "";
					this.$refs.fileupload_0.value = null;
				}
			},

			//画像（2枚目）
			onFilePicked_1(file) {
				if (file !== undefined) {
					this.imageName_1 = file.name;
					if (this.imageName_1.lastIndexOf(".") <= 0) {
						return;
					}
					const fr = new FileReader();
					fr.readAsDataURL(file);
					fr.addEventListener("load", () => {
						this.imageUrl_1 = fr.result;
						this.imageFile_1 = file;
					});
				} else {
					this.imageName_1 = "";
					this.imageFile_1 = "";
					this.imageUrl_1 = "";
					this.$refs.fileupload_1.value = null;
				}
			},

			//画像（3枚目）
			onFilePicked_2(file) {
				if (file !== undefined) {
					this.imageName_2 = file.name;
					if (this.imageName_2.lastIndexOf(".") <= 0) {
						return;
					}
					const fr = new FileReader();
					fr.readAsDataURL(file);
					fr.addEventListener("load", () => {
						this.imageUrl_2 = fr.result;
						this.imageFile_2 = file;
					});
				} else {
					this.imageName_2 = "";
					this.imageFile_2 = "";
					this.imageUrl_2 = "";
					this.$refs.fileupload_2.value = null;
				}
			},

			validate: function () {
				var db = firebase.firestore();
				var storage = firebase.storage();
				var docID = db.collection("_").doc().id;
				// ユニークIDを生成するための文字列圧縮処理
				// Model
				var uModel;
				if (this.model.model == null || this.model.model == undefined) {
					uModel = "0";
				} else {
					uModel = this.model.model
						.split(" ")
						.join("")
						.split("-")
						.join("")
						.split(".")
						.join("")
						.slice(0, 3)
						.toLowerCase();
				}
				// Maker
				var uManufacturer;
				if (
					this.manufacturer.manufacturer == null ||
					this.manufacturer.manufacturer == undefined
				) {
					uManufacturer = "0";
				} else {
					uManufacturer = this.manufacturer.manufacturer
						.split(" ")
						.join("")
						.split("-")
						.join("")
						.split(".")
						.join("")
						.slice(0, 3)
						.toLowerCase();
				}
				// Size
				var uSize;
				if (this.size == null || this.size == undefined) {
					uSize = "0";
				} else {
					uSize = this.size;
				}
				// Category
				var uCategory;
				if (this.category.value == null || this.category.value == undefined) {
					uCategory = "0";
				} else {
					uCategory = this.category.value.slice(0, 3).toLowerCase();
				}
				// Location_Repo
				var uRepo;
				if (
					this.location_repo.value == null ||
					this.location_repo.value == undefined
				) {
					uRepo = "0";
				} else {
					uRepo = this.location_repo.value.slice(0, 3).toLowerCase();
				}
				// Location_Repo
				var uBox;
				if (
					this.location_box.value == null ||
					this.location_box.value == undefined
				) {
					uBox = "0";
				} else {
					uBox = this.location_box.value
						.replace(/-/g, "")
						.slice(0, 3)
						.toLowerCase();
				}
				var uniqueIDArr = [
					uModel,
					uManufacturer,
					uCategory,
					uRepo,
					uBox,
					uSize,
				];
				var uniqueID = uniqueIDArr.join("-");
				this.uniqueID = uniqueID;
				var uniqueIDHash = CRC32.str(uniqueID);

				var uri = new URL(window.location.href);
				var routerPathName = this.model
					.replace(/[^a-zA-Z0-9ぁ-んーァ-ヶーァ-ヶー一-龠]/gi, "")
					.toLowerCase();

				var createQRCodeparams;

				if (this.size == null || this.size == undefined) {
					createQRCodeparams =
						uri.origin + "/info?id=" + docID + "&model=" + routerPathName;
				} else {
					createQRCodeparams =
						uri.origin +
						"/info?id=" +
						docID +
						"&model=" +
						routerPathName +
						"&length" +
						uSize;
				}

				const self = this;

				function asynchronyFunction() {
					return new Promise((resolve) => {
						setTimeout(() => {
							db
								.collection("equipments")
								.doc(docID)
								// .doc(self.model)
								.set({
									id: docID,
									uniqueid: uniqueID,
									hash: uniqueIDHash,
									model: self.model,
									manufacturer: self.manufacturer,
									size: self.size,
									model_number: self.model_number,
									stock: self.stock,
									category: self.category.value,
									location_repo: self.location_repo.value,
									location_box: self.location_box.value,
									status: "ok",
									registration_date: self.date2,
									purchase_date: self.date,
									rentalfee: self.rentalfee,
									description: self.items,
									// description: this.descriptionfilter || "説明文がありません",
									update_date: "",
									qrlink: createQRCodeparams,
									qrcheck: false,
								})
								.then(() => {})

								.catch(() => {}),
								resolve();
						}, 100);
					})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									if (self.imageFile_0) {
										storage
											.ref()
											.child(`item_thumbnail/${docID}/0`)
											.put(self.imageFile_0)
											.then((snapshot) => {
												snapshot.ref.getDownloadURL().then((downloadURL) => {
													firebase
														.firestore()
														.collection("equipments")
														.doc(docID)
														.update({
															item_thumbnail_0: downloadURL,
														});
												});
											});
									} else if (self.imageFile_0) {
										db.collection("equipments")
											.doc(docID)
											// .doc(self.model)
											.set({
												item_thumbnail_0: "",
											});
									}
									resolve();
								}, 100);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									if (self.imageFile_1) {
										storage
											.ref()
											.child(`item_thumbnail/${docID}/1`)
											.put(self.imageFile_1)
											.then((snapshot) => {
												snapshot.ref.getDownloadURL().then((downloadURL) => {
													firebase
														.firestore()
														.collection("equipments")
														.doc(docID)
														.update({
															item_thumbnail_1: downloadURL,
														});
												});
											});
									} else if (self.imageFile_1) {
										db.collection("equipments")
											.doc(docID)
											// .doc(self.model)
											.set({
												item_thumbnail_1: "",
											});
									}
									resolve();
								}, 100);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									if (self.imageFile_2) {
										storage
											.ref()
											.child(`item_thumbnail/${docID}/2`)
											.put(self.imageFile_2)
											.then((snapshot) => {
												snapshot.ref.getDownloadURL().then((downloadURL) => {
													firebase
														.firestore()
														.collection("equipments")
														.doc(docID)
														.update({
															item_thumbnail_2: downloadURL,
														});
												});
											});
									} else if (self.imageFile_2) {
										db.collection("equipments")
											.doc(docID)
											// .doc(self.model)
											.set({
												item_thumbnail_2: "",
											});
									}
									resolve();
								}, 100);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									self.imageName_0 = "";
									self.imageFile_0 = "";
									self.imageUrl_0 = "";
									self.$refs.fileupload_0.value = null;

									self.imageName_1 = "";
									self.imageFile_1 = "";
									self.imageUrl_1 = "";
									self.$refs.fileupload_1.value = null;

									self.imageName_2 = "";
									self.imageFile_2 = "";
									self.imageUrl_2 = "";
									self.$refs.fileupload_2.value = null;

									setTimeout(self.$refs.form.reset, 100);
									resolve();
								}, 100);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									// this.descriptionRender = false;
									// this.$nextTick(() => (this.descriptionRender = true));
									// console.log("test");
									// model: "",
									// manufacturer: "",
									self.size = "";
									self.model_number = "";
									self.serial = "";
									self.number = "";
									self.location_repo = "";
									self.location_box = "";
									self.status = "";
									// category: "",
									self.purchase_date = "";
									self.stock = "";
									self.description = "";
									self.category = "";
									self.rentalfee = "";
									self.uniqueID = "";
									self.uniqueIDHash = "";
									self.model = "";
									self.manufacturer = "";
									self.cableHide = false;
									self.items.splice(0);
									self.addItem_Notification = true;
									resolve();
								}, 100);
							});
						})
						.catch(() => {
							self.addItem_Notification_err = true;
						});
				}
				asynchronyFunction();
			},
			changeCategoryValue() {
				if (
					this.category.value == "video-cable" ||
					this.category.value == "audio-cable" ||
					this.category.value == "lan-cable" ||
					this.category.value == "power-cable" ||
					this.category.value == "other-cable"
				) {
					this.cableHide = true;
				} else {
					this.cableHide = false;
				}
			},
			qrCodeGenerate() {
				var uri = new URL(window.location.href);
				var createQRCodeparams = null;
				var routerPathName = this.model
					.replace(/[^a-zA-Z0-9ぁ-んーァ-ヶーァ-ヶー一-龠]/gi, "")
					.toLowerCase();

				if (this.model) {
					createQRCodeparams =
						uri.origin + "/rent/info?model=" + routerPathName;
					this.qrCodeRender = true;

					if (this.qrCodeRender) {
						var canvas = document.getElementById("qrCodeCanvas");
						var dataURL = canvas.toDataURL();
						var byteString = atob(dataURL.split(",")[1]);
						// eslint-disable-next-line no-useless-escape
						var mimeType = dataURL.match(/(:)([a-z\/]+)(;)/)[2];
						for (
							var i = 0, l = byteString.length, content = new Uint8Array(l);
							l > i;
							i++
						) {
							content[i] = byteString.charCodeAt(i);
						}
						var blob = new Blob([content], {
							type: mimeType,
						});
						var a = window.URL.createObjectURL(blob);
						this.qrCodeparams = createQRCodeparams;
						this.qrCodeURL = a;
					} else if (!this.qrCodeRender) {
						this.qrCodeparams = null;
					}
				} else if (this.model == "") {
					this.qrGenerate_Notification_err = true;
				}
			},
		},

		computed: {
			dbItemsOutput() {
				// return this.$store.getters.alldbItems;
				return this.dbitems;
			},
			dbItemsOutputModel() {
				// return this.$store.getters.alldbItems;
				return Object.entries(this.dbItemsOutput)
					.map((item) => item[1])
					.sort((a, b) => {
						// let textA = a.category.toUpperCase();
						// let textB = b.category.toUpperCase();
						// return textA < textB ? -1 : textA > textB ? 1 : 0;
						//priceの昇順
						if (a.category.toUpperCase() > b.category.toUpperCase()) return 1;
						if (a.category.toUpperCase() < b.category.toUpperCase()) return -1;

						// if (
						// 	this.dbCategoryOutput.indexOf(a.category.toUpperCase()) -
						// 	this.dbCategoryOutput.indexOf(b.category.toUpperCase())
						// )
						// 	return -1;

						//noの降順
						if (a.model.toUpperCase() < b.model.toUpperCase()) return -1;
						if (a.model.toUpperCase() > b.model.toUpperCase()) return 1;
						if (a.size || b.size) {
							if (Number(a.size) < Number(b.size)) return -1;
							if (Number(a.size) > Number(b.size)) return 1;
						}
						return 0;
					});
			},
			dbItemsOutputManu() {
				return Object.entries(this.dbItemsOutput)
					.map((item) => item[1])
					.sort((a, b) => {
						if (a.manufacturer.toUpperCase() > b.manufacturer.toUpperCase())
							return 1;
						if (a.manufacturer.toUpperCase() < b.manufacturer.toUpperCase())
							return -1;
						return 0;
					})
					.filter((item) => item.manufacturer != "");
			},
			dbItemsOutputModel_ab() {
				return _.sortBy(this.dbItemsOutputModel, (i) => {
					return this.dbCategoryOutput.indexOf(i.category);
				});
			},
			//CATEGORY FILTER
			categoryfilter() {
				return this.dbItemsOutput
					.map((item) => item.category)
					.filter((x, i, self) => self.indexOf(x) === i)
					.filter((v) => v);
			},

			categoryMap() {
				return this.categoryfilter.map((value) => ({
					...{ value },
				}));
			},

			categoryfilterNull() {
				let Arr_1 = ["null"];
				let Arr_2 = this.categoryfilter;
				let Arr_3 = Arr_1.concat(Arr_2);
				return Arr_3;
			},

			dbCategoryOutput() {
				return Object.entries(this.$store.getters.alldbCategory).map(
					(item) => item[1]["value"]
				);
			},
			dbCategoryOutput_Select() {
				let categoryData = Object.values(this.$store.getters.alldbCategory).map(
					(value) => {
						return {
							id: value.id,
							text: value.name,
							value: value.value,
							category: value.category,
						};
					}
				);
				return categoryData;
			},
			dbLocationRepoOutput() {
				return this.$store.getters.alldbLocationRepo;
			},

			dbLocationBoxOutput() {
				return this.$store.getters.alldbLocationBox;
			},

			//

			//LOCATION REPO FILTER
			locationRepofilter() {
				return this.dbItemsOutput
					.map((item) => item.location_repo)
					.filter((x, i, self) => self.indexOf(x) === i)
					.filter((v) => v);
			},

			//LOCATION BOX FILTER
			locationBoxfilter() {
				return this.dbItemsOutput
					.map((item) => item.location_box)
					.filter((x, i, self) => self.indexOf(x) === i)
					.filter((v) => v);
			},

			//LOCATION BOX FILTER
			statusfilter() {
				return this.dbItemsOutput
					.map((item) => item.status)
					.filter((x, i, self) => self.indexOf(x) === i)
					.filter((v) => v);
			},

			//DESCREPTION FILTER
			descriptionfilter() {
				return this.items.join(" ");
			},
		},

		updated() {},
	};
</script>
<style>
	.registerBtn span {
		color: #262626;
	}
</style>
<style scoped>
	.qrCode {
		max-width: 256px;
		max-height: 256px;
	}
</style>
