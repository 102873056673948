import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

let cart = window.localStorage.getItem("cart");

export default new Vuex.Store({
	state: {
		user: {},
		status: false,
		dbItems: {},
		dbCategory: {},
		dbLocationRepo: {},
		dbLocationBox: {},
		cart: cart ? JSON.parse(cart) : [],
		count: 1,
		dates: [],
		calendar: [],
		dialogInfo: false,
		dialogInfo_i: false,
		dialogCalendar: false,
		exList: null,
		menuStatus: false,
		menuDialog: false,

		//Tabmenu
		tabMenu: 0,
		tabMenuContent: 0,
		tabMenuContentName: 1,

		// Return Page
		dialogInfo_return_info: false,
		dialogInfo_return_due: false,
		dialogInfo_return_return: false,

		// Return Page Admin Done
		dialogInfo_done_info: false,

		dialog_close_status: false,

		dialogInfo_nest_info: false,

		dialogInfo_update_info: false,

		// Notification
		Notification_AddItem: false,
		Notification_Checkout: false,
		Notification_Checkout_Err: false,
		Notification_Store_Err: false,

		dialogCalendarHide: false,

		// QRCode
		qrReadID: null,

		// router
		routerCache: null,
	},
	mutations: {
		onAuthStateChanged(state, user) {
			state.user = user; //firebaseが返したユーザー情報
		},
		onUserStatusChanged(state, status) {
			state.status = status; //ログインしてるかどうか true or false
		},
		setdbItems(state, dbItems) {
			state.dbItems = dbItems;
		},
		setdbCategory(state, dbCategory) {
			state.dbCategory = dbCategory;
		},
		setdbLocationRepo(state, dbLocationRepo) {
			state.dbLocationRepo = dbLocationRepo;
		},
		setdbLocationBox(state, dbLocationBox) {
			state.dbLocationBox = dbLocationBox;
		},
		addToCart(state, cart) {
			let found = state.cart.find((item) => item.id == cart.id);
			let count = state.count;

			if (found) {
				found.quantity += count;
			} else {
				state.cart.push({ ...cart, quantity: count });
			}

			this.commit("saveData");
		},
		removeFromCart(state, cart) {
			let index = state.cart.indexOf(cart);
			state.cart.splice(index, 1);

			this.commit("saveData");
		},
		setCount(state, bool) {
			state.count = bool;
		},
		increment(state) {
			state.count += 1;
		},
		decrement(state) {
			state.count -= 1;
		},
		saveData(state) {
			window.localStorage.setItem("cart", JSON.stringify(state.cart));
		},
		deleteData(state) {
			state.cart = [];
			window.localStorage.removeItem("cart", JSON.stringify());
		},
		rentDates(state, dates) {
			state.dates = dates;
		},
		setCalendar(state, calendar) {
			state.calendar = calendar;
		},
		setDialogInfo(state, bool) {
			state.dialogInfo = bool;
		},
		setDialogInfo_i(state, bool) {
			state.dialogInfo_i = bool;
		},
		setexList(state, bool) {
			state.exList = bool;
		},
		settabMenu(state, int) {
			state.tabMenu = int;
		},
		settabMenuContent(state, int) {
			state.tabMenuContent = int;
		},
		settabMenuContentName(state, int) {
			state.tabMenuContentName = int;
		},
		setmenuStatus(state, bool) {
			state.menuStatus = bool;
		},
		setmenuDialog(state, payload) {
			state.menuDialog = payload;
		},
		set_dialogInfo_return_info(state, bool) {
			state.dialogInfo_return_info = bool;
		},
		set_dialogInfo_return_due(state, bool) {
			state.dialogInfo_return_due = bool;
		},
		set_dialogInfo_return_return(state, bool) {
			state.dialogInfo_return_return = bool;
		},
		set_dialogInfo_done_info(state, bool) {
			state.dialogInfo_done_info = bool;
		},
		set_dialog_close_status(state, bool) {
			state.dialog_close_status = bool;
		},
		set_dialogInfo_nest_info(state, payload) {
			state.dialogInfo_nest_info = payload;
		},
		set_dialogInfo_update_info(state, bool) {
			state.dialogInfo_update_info = bool;
		},

		// Notification
		set_Notification_AddItem(state, bool) {
			state.Notification_AddItem = bool;
		},
		set_Notification_Checkout(state, bool) {
			state.Notification_Checkout = bool;
		},
		set_Notification_Checkout_Err(state, bool) {
			state.Notification_Checkout_Err = bool;
		},
		set_Notification_Store_Err(state, bool) {
			state.Notification_Store_Err = bool;
		},

		set_dialogCalendar(state, bool) {
			state.dialogCalendar = bool;
		},
		set_dialogCalendarHide(state, bool) {
			state.dialogCalendarHide = bool;
		},
		set_qrReadID(state, str) {
			state.qrReadID = str;
		},

		set_routerCache(state, str) {
			state.routerCache = str;
		},
	},
	getters: {
		user(state) {
			return state.user;
		},
		isSignedIn(state) {
			return state.status;
		},
		alldbItems(state) {
			return state.dbItems;
		},
		alldbCategory(state) {
			return state.dbCategory;
		},
		alldbLocationRepo(state) {
			return state.dbLocationRepo;
		},
		alldbLocationBox(state) {
			return state.dbLocationBox;
		},
		addcartItems(state) {
			return state.cart;
		},
		getCount(state) {
			return state.count;
		},
		getDates(state) {
			return state.dates;
		},
		getCalendar(state) {
			return state.calendar;
		},
		getDialogInfo(state) {
			return state.dialogInfo;
		},
		getDialogInfo_i(state) {
			return state.dialogInfo_i;
		},
		getexList(state) {
			return state.exList;
		},
		gettabMenu(state) {
			return state.tabMenu;
		},
		gettabMenuContent(state) {
			return state.tabMenuContent;
		},
		gettabMenuContentName(state) {
			return state.tabMenuContentName;
		},
		getmenuStatus(state) {
			return state.menuStatus;
		},
		getmenuDialog(state) {
			return state.menuDialog;
		},
		get_dialogInfo_return_info(state) {
			return state.dialogInfo_return_info;
		},
		get_dialogInfo_return_due(state) {
			return state.dialogInfo_return_due;
		},
		get_dialogInfo_return_return(state) {
			return state.dialogInfo_return_return;
		},
		get_dialogInfo_done_info(state) {
			return state.dialogInfo_done_info;
		},
		get_dialog_close_status(state) {
			return state.dialog_close_status;
		},
		get_dialogInfo_nest_info(state) {
			return state.dialogInfo_nest_info;
		},
		get_dialogInfo_update_info(state) {
			return state.dialogInfo_update_info;
		},

		// Notification
		get_Notification_AddItem(state) {
			return state.Notification_AddItem;
		},
		get_Notification_Checkout(state) {
			return state.Notification_Checkout;
		},
		get_Notification_Checkout_Err(state) {
			return state.Notification_Checkout_Err;
		},
		get_Notification_Store_Err(state) {
			return state.Notification_Store_Err;
		},

		get_dialogCalendar(state) {
			return state.dialogCalendar;
		},
		get_dialogCalendarHide(state) {
			return state.dialogCalendarHide;
		},
		get_qrReadID(state) {
			return state.qrReadID;
		},

		get_routerCache(state) {
			return state.routerCache;
		},
	},
	actions: {
		async getdbItems({ commit }) {
			const response = await axios.get(
				"https://api.steinhq.com/v1/storages/60f67184d2a8585c5af283f3/db_all_filter"
			);
			commit("setdbItems", response.data);
		},
		async getdbCategory({ commit }) {
			const response = await axios.get(
				"https://api.steinhq.com/v1/storages/60f67184d2a8585c5af283f3/db_category"
			);
			commit("setdbCategory", response.data);
		},
		async getdbLocationRepo({ commit }) {
			const response = await axios.get(
				"https://api.steinhq.com/v1/storages/60f67184d2a8585c5af283f3/db_location_repo"
			);
			commit("setdbLocationRepo", response.data);
		},
		async getdbLocationBox({ commit }) {
			const response = await axios.get(
				"https://api.steinhq.com/v1/storages/60f67184d2a8585c5af283f3/db_location_box"
			);
			commit("setdbLocationBox", response.data);
		},
		async getCalendarDate({ commit }) {
			const response = await axios.get(
				"https://api.steinhq.com/v1/storages/60f67184d2a8585c5af283f3/db_cal"
			);
			commit("setCalendar", response.data);
		},
		increment(context) {
			context.commit("increment");
		},
		decrement({ commit }) {
			commit("decrement");
		},
	},
	// strict: true,
	plugins: [
		createPersistedState({
			key: "LoginKey",
			storage: window.sessionStorage,
			paths: ["status", "user"],
		}),
	],
});
