<template>
	<v-app>
		<v-container bg fill-height grid-list-md text-xs-center>
			<v-layout row wrap align-center>
				<v-flex>
					<v-main>
						<HelloWorld />
					</v-main>
				</v-flex>
			</v-layout>
		</v-container>
	</v-app>
</template>

<script>
	import HelloWorld from "../components/HelloWorld.vue";

	export default {
		name: "AppSingle",
		components: {
			HelloWorld,
		},
		data() {
			return {};
		},
		// beforeDestroy() {
		// 	document.v-application.className = "";
		// 	this.$root.headerHide = false;
		// 	this.$root.footerHide = false;
		// },

		// mounted() {
		// 	document.["theme--light.v-application"].className = "single";
		// 	this.$root.headerHide = true;
		// 	this.$root.footerHide = true;
		// },
	};
</script>
<style scoped>
	.theme--light.v-application {
		background: #262626 !important;
	}
</style>
