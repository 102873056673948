<template>
	<div>
		<v-container
			style="max-width: 960px; position: relative;"
			v-on:keydown.enter="onKeyDown"
			class="pa-0"
		>
			<v-card color="transparent" flat>
				<v-row class=" text-center" justify="center" align-content="center">
					<v-col cols="12" md="4" sm="12" xs="12">
						<v-card
							to="/adminuploader"
							elevation="0"
							color="#262626"
							class="card py-md-15 py-sm-12 py-5"
						>
							<v-icon :size="iconSize" color="white">
								mdi-plus-circle
							</v-icon>
							<v-card-title
								style="color:#fff;"
								class="justify-center ma-0 pt-2 pa-0 text-md-h5 text-h6"
							>
								Add item</v-card-title
							>
						</v-card>
					</v-col>
					<v-col cols="12" md="4" sm="12" xs="12">
						<v-card
							to="/adminupdate"
							elevation="0"
							color="#262626"
							class="card py-md-15 py-sm-12 py-5"
						>
							<v-icon :size="iconSize" color="white">
								mdi-refresh-circle
							</v-icon>
							<v-card-title
								style="color:#fff;"
								class="justify-center ma-0 pt-2 pa-0 text-md-h5 text-h6"
								>Update item</v-card-title
							>
						</v-card>
					</v-col>
					<v-col cols="12" md="4" sm="12" xs="12">
						<v-card
							to="/adminreturn"
							elevation="0"
							color="#262626"
							class="card py-md-15 py-sm-12 py-5"
						>
							<v-icon :size="iconSize" color="white">
								mdi-archive-check
							</v-icon>
							<v-card-title
								style="color:#fff;"
								class="justify-center ma-0 pt-2 pa-0 text-md-h5 text-h6"
								>Check item</v-card-title
							>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</v-container>
	</div>
</template>

<script>
	import firebase from "./../firebase";
	export default {
		name: "Console",
		created: function() {
			firebase.onAuth();
		},
		computed: {
			user() {
				return this.$store.getters.user;
			},
			userStatus() {
				// ログインするとtrue
				return this.$store.getters.isSignedIn;
			},

			iconSize() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 25;
					case "sm":
						return 25;
					case "md":
						return 50;
					default:
						return 50;
				}
			},
		},
		methods: {
			// ログイン処理
			doLogin() {
				firebase.login();
			},
			// ログアウト処理
			doLogout() {
				firebase.logout();
			},
		},
		data: () => ({
			ecosystem: [
				{
					text: "vuetify-loader",
					href: "https://github.com/vuetifyjs/vuetify-loader",
				},
				{
					text: "github",
					href: "https://github.com/vuetifyjs/vuetify",
				},
				{
					text: "awesome-vuetify",
					href: "https://github.com/vuetifyjs/awesome-vuetify",
				},
			],
			importantLinks: [
				{
					text: "Documentation",
					href: "https://vuetifyjs.com",
				},
				{
					text: "Chat",
					href: "https://community.vuetifyjs.com",
				},
				{
					text: "Made with Vuetify",
					href: "https://madewithvuejs.com/vuetify",
				},
				{
					text: "Twitter",
					href: "https://twitter.com/vuetifyjs",
				},
				{
					text: "Articles",
					href: "https://medium.com/vuetify",
				},
			],
			whatsNext: [
				{
					text: "Explore components",
					href: "https://vuetifyjs.com/components/api-explorer",
				},
				{
					text: "Select a layout",
					href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
				},
				{
					text: "Frequently Asked Questions",
					href:
						"https://vuetifyjs.com/getting-started/frequently-asked-questions",
				},
			],
		}),
	};
</script>
<style scoped>
	@media (min-width: 600px) {
		.v-application .py-sm-12 {
			padding-top: 48px !important;
			padding-bottom: 48px !important;
		}
	}
</style>
