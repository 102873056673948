<template>
	<div>
		<v-row v-if="$vuetify.breakpoint.mdAndUp">
			<v-col class="mb-3">
				<v-expansion-panels flat class="rounded-0">
					<v-expansion-panel>
						<v-expansion-panel-header class="pl-4"
							>Open QR code reader...</v-expansion-panel-header
						>
						<v-expansion-panel-content>
							<v-card flat elavation-0 class="pa-0">
								<v-app-bar flat color="rgba(0, 0, 0, 0)">
									<v-toolbar-title>
										<v-icon class="pr-2">mdi-qrcode-scan</v-icon>
										QR Reader
									</v-toolbar-title>
									<v-spacer></v-spacer>

									<v-switch
										v-model="qrModeSwitch"
										@change="qrSwitch"
										inset
										color="#262626"
										:label="qrModeSwitchText"
									></v-switch>
								</v-app-bar>
								<v-row class="justify-center">
									<v-col cols="12" class="pb-0" v-if="!qrModeSwitch">
										<v-text-field
											v-model.lazy="qrField"
											@keyup.enter="qrSearch"
											autofocus
											label="Scan the QR code..."
											placeholder="専用リーダーでQRコードを読み込んでください"
											outlined
											clearable
											class="rounded-0"
										></v-text-field>
									</v-col>

									<v-row v-if="!qrModeSwitch" class="pr-3 pl-3">
										<v-col
											cols="12"
											class="d-flex justify-center align-center pt-0 pb-0"
										>
											<v-alert
												dense
												outlined
												color="#262626"
												text
												type="info"
												class="rounded-0"
											>
												このリアルタイムモードでは一つずつデータを追加します。まとめて読み込みたい場合は右上のスイッチから読み込み方法を切り替えてください。
											</v-alert>
										</v-col>

										<v-col
											cols="12"
											class="d-flex justify-center align-center pt-0 pb-0"
										>
											<!-- リアルタイムモード -->
											<qrcode
												value="%%SpecCode10"
												:options="{ width: 128 }"
												id="qrCodeCanvas"
												class="qrCode"
											></qrcode>
										</v-col>
										<v-col
											cols="12"
											class="d-flex justify-center align-center pt-0 pb-0"
										>
											<p class="text-subtitle-1">リアルタイムモード切替QR</p>
										</v-col>
										<v-col
											cols="12"
											class="d-flex justify-center align-center pt-0 pb-10"
										>
											<p class="text-body-2">
												バーコードリーダーをリアルタイムモードに切り替えてください
											</p>
										</v-col>
										<v-col
											cols="12"
											class="d-flex justify-center align-center pt-0 pb-0"
										>
											<v-expansion-panels
												v-model="exQRPanel"
												flat
												class="rounded-0 elavation-0 mb-5"
											>
												<v-expansion-panel>
													<v-expansion-panel-header>
														QRCode reader settings
													</v-expansion-panel-header>

													<v-expansion-panel-content>
														<v-card flat class="rounded-0">
															<v-row>
																<v-col cols="6" class="pb-0">
																	<v-col
																		class="d-flex justify-center align-center ma-0 pa-0"
																	>
																		<!-- データのアップロード -->
																		<qrcode
																			value="%%SpecCode9E"
																			:options="{ width: 128 }"
																			id="qrCodeCanvas"
																			class="qrCode"
																		></qrcode>
																	</v-col>
																	<v-col
																		cols="12"
																		class="d-flex justify-center align-center ma-0 pa-0"
																	>
																		<p class="text-subtitle-1">
																			エンターを自動的に追加
																		</p>
																	</v-col>
																	<v-col
																		cols="12"
																		class="d-flex justify-center align-center ma-0 pt-0 pb-0"
																	>
																		<p class="text-body-2">
																			自動的にエンターが押されない場合
																		</p>
																	</v-col>
																</v-col>
																<v-col cols="6">
																	<v-col
																		class="d-flex justify-center align-center ma-0 pa-0"
																	>
																		<!-- データの消去 -->
																		<qrcode
																			value="%%SpecCodeA5"
																			:options="{ width: 128 }"
																			id="qrCodeCanvas"
																			class="qrCode"
																		></qrcode>
																	</v-col>
																	<v-col
																		cols="12"
																		class="d-flex justify-center align-center ma-0 pa-0"
																	>
																		<p class="text-subtitle-1">
																			大文字小文字を区別
																		</p>
																	</v-col>
																	<v-col
																		cols="12"
																		class="d-flex justify-center align-center ma-0 pt-0 pb-0"
																	>
																		<p class="text-body-2">
																			自動的に区別されないと正常に入力できません
																		</p>
																	</v-col>
																</v-col>
															</v-row>
														</v-card>
													</v-expansion-panel-content>
												</v-expansion-panel>
											</v-expansion-panels>
										</v-col>
									</v-row>
									<v-col cols="12" v-if="qrModeSwitch">
										<v-form ref="form" v-model="vForm">
											<v-textarea
												v-model="qrStoreField"
												autofocus
												outlined
												label="Scan the QR code..."
												placeholder="専用リーダーでQRコードを読み込んでください"
												class="rounded-0"
												no-resize
												:rules="[(v) => !!v || '必ず入力してください']"
											></v-textarea>
										</v-form>
										<v-row class="justify-center mb-1 pr-3 pl-3">
											<v-btn
												@click="qrSearch"
												outlined
												color="#262626"
												style="text-transform: none"
												:disabled="!vForm"
											>
												Import scanned data
											</v-btn>
											<v-col
												cols="12"
												class="d-flex justify-center align-center ma-0 pa-0"
											>
												<v-alert
													dense
													outlined
													color="#262626"
													text
													type="info"
													class="mt-5 rounded-0"
												>
													この点検モードではバーコードリーダー内に保存したデータをまとめて追加します。
												</v-alert>
											</v-col>
										</v-row>
										<v-row class="justify-center pr-3 pl-3 pb-0 mb-0">
											<v-col cols="4" class="pb-0">
												<!-- 点検モード -->
												<v-col
													class="d-flex justify-center align-center ma-0 pa-0"
												>
													<qrcode
														value="%%SpecCode11"
														:options="{ width: 128 }"
														id="qrCodeCanvas"
														class="qrCode"
													></qrcode>
												</v-col>
												<v-col
													cols="12"
													class="d-flex justify-center align-center ma-0 pa-0"
												>
													<p class="text-subtitle-1">点検モード切替QR</p>
												</v-col>
												<v-col
													cols="12"
													class="d-flex justify-center align-center ma-0 pt-0 pb-0"
												>
													<p class="text-body-2">
														バーコードリーダーを点検モードに切り替えてください
													</p>
												</v-col>
											</v-col>
											<v-col cols="4" class="pb-0">
												<v-col
													class="d-flex justify-center align-center ma-0 pa-0"
												>
													<!-- データのアップロード -->
													<qrcode
														value="%%SpecCode16"
														:options="{ width: 128 }"
														id="qrCodeCanvas"
														class="qrCode"
													></qrcode>
												</v-col>
												<v-col
													cols="12"
													class="d-flex justify-center align-center ma-0 pa-0"
												>
													<p class="text-subtitle-1">
														スキャンしたデータを入力
													</p>
												</v-col>
												<v-col
													cols="12"
													class="d-flex justify-center align-center ma-0 pt-0 pb-0"
												>
													<p class="text-body-2">
														バーコードリーダーに保存されたデータが出力されます
													</p>
												</v-col>
											</v-col>
											<v-col cols="4">
												<v-col
													class="d-flex justify-center align-center ma-0 pa-0"
												>
													<!-- データの消去 -->
													<qrcode
														value="%%SpecCode18"
														:options="{ width: 128 }"
														id="qrCodeCanvas"
														class="qrCode"
													></qrcode>
												</v-col>
												<v-col
													cols="12"
													class="d-flex justify-center align-center ma-0 pa-0"
												>
													<p class="text-subtitle-1">
														スキャンしたデータを削除
													</p>
												</v-col>
												<v-col
													cols="12"
													class="d-flex justify-center align-center ma-0 pt-0 pb-0"
												>
													<p class="text-body-2">
														バーコードリーダーに保存されたデータを全消去します
													</p>
												</v-col>
											</v-col>
											<v-col
												cols="12"
												class="d-flex justify-center align-center pt-0 pb-0"
											>
												<v-expansion-panels
													v-model="exQRPanel"
													flat
													class="rounded-0 elavation-0"
												>
													<v-expansion-panel>
														<v-expansion-panel-header>
															QRCode reader settings
														</v-expansion-panel-header>

														<v-expansion-panel-content>
															<v-card flat class="rounded-0">
																<v-row>
																	<v-col cols="6" class="pb-0">
																		<v-col
																			class="d-flex justify-center align-center ma-0 pa-0"
																		>
																			<!-- データのアップロード -->
																			<qrcode
																				value="%%SpecCode9E"
																				:options="{ width: 128 }"
																				id="qrCodeCanvas"
																				class="qrCode"
																			></qrcode>
																		</v-col>
																		<v-col
																			cols="12"
																			class="d-flex justify-center align-center ma-0 pa-0"
																		>
																			<p class="text-subtitle-1">
																				エンターを自動的に追加
																			</p>
																		</v-col>
																		<v-col
																			cols="12"
																			class="d-flex justify-center align-center ma-0 pt-0 pb-0"
																		>
																			<p class="text-body-2">
																				自動的にエンターが押されない場合
																			</p>
																		</v-col>
																	</v-col>
																	<v-col cols="6">
																		<v-col
																			class="d-flex justify-center align-center ma-0 pa-0"
																		>
																			<!-- データの消去 -->
																			<qrcode
																				value="%%SpecCodeA5"
																				:options="{ width: 128 }"
																				id="qrCodeCanvas"
																				class="qrCode"
																			></qrcode>
																		</v-col>
																		<v-col
																			cols="12"
																			class="d-flex justify-center align-center ma-0 pa-0"
																		>
																			<p class="text-subtitle-1">
																				大文字小文字を区別
																			</p>
																		</v-col>
																		<v-col
																			cols="12"
																			class="d-flex justify-center align-center ma-0 pt-0 pb-0"
																		>
																			<p class="text-body-2">
																				自動的に区別されないと正常に入力できません
																			</p>
																		</v-col>
																	</v-col>
																</v-row>
															</v-card>
														</v-expansion-panel-content>
													</v-expansion-panel>
												</v-expansion-panels>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-card>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
		<v-row class="switchUI">
			<v-col v-if="$vuetify.breakpoint.mdAndUp">
				<span style="text-align: left"
					>Filter: {{ selectedCat }} / {{ selectedName }}</span
				>
			</v-col>
			<v-text-field
				v-if="$vuetify.breakpoint.mdAndDown"
				v-model="search"
				prepend-icon="mdi-magnify"
				dense
				class="pr-2"
			></v-text-field>
			<v-btn @click="show = !show" icon>
				<v-icon size="35px">{{
					show ? "mdi-format-list-bulleted" : "mdi-view-grid"
				}}</v-icon>
			</v-btn>
		</v-row>
		<v-tabs color="black" background-color="transparent" v-model="activeTab">
			<v-tab
				v-for="item in listInCategory"
				@click="clickTabMenucat(item)"
				v-bind:key="item.name"
				:class="{ 'd-none': $vuetify.breakpoint.mdAndDown }"
				style="color: #fff"
			>
				<v-list-item class="tabsItem">{{ item.name }}</v-list-item></v-tab
			>
			<v-spacer></v-spacer>
			<v-text-field
				v-if="$vuetify.breakpoint.lgAndUp"
				v-model="search"
				append-icon="mdi-magnify"
				dense
			></v-text-field>
			<v-tab-item
				transition="fade-transition"
				reverse-transition="fade-transition"
			>
				<div v-if="!show">
					<v-row>
						<v-col
							cols="6"
							md="3"
							v-for="(item, index) in dbitemsSort_ab"
							:key="index"
						>
							<v-card @click="onClickRow(item)" elevation="0" flat tile>
								<v-img
									:height="gridImageHeight"
									contain
									class="white--text align-center"
									v-bind:src="item.item_thumbnail_0"
									gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
								>
									<v-card-title
										class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
										v-if="item.manufacturer"
										>{{ item.manufacturer }}</v-card-title
									>
									<v-card-title
										class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
										v-if="!item.size"
										>{{ item.model }}</v-card-title
									>
									<v-card-title
										class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
										v-if="item.size"
										>{{ item.model }} {{ item.size }}m</v-card-title
									>
								</v-img>
							</v-card>
						</v-col>
					</v-row>
				</div>
				<!-- <v-expand-transition> -->
				<div v-if="show">
					<v-card elevation="0" flat tile>
						<v-data-table
							v-if="$vuetify.breakpoint.mdAndUp"
							:headers="listHeaders"
							:items="dbitemsSort_ab"
							:items-per-page="30"
							:footer-props="{ 'items-per-page-text': '' }"
							class="elevation-0"
						>
							<template v-slot:item.category="{ item }">
								{{
									item.category
										| replace("camera", "Camera")
										| replace("lens", "Lens")
										| replace("recorder", "Recorder / Monitor")
										| replace("media", "Media")
										| replace("tripod", "Tripod/Gimbal/Jib")
										| replace("light", "Light")
										| replace("switcher", "Switcher")
										| replace("video-cable", "Video cable")
										| replace("audio-cable", "Audio cable")
										| replace("lan-cable", "LAN cable")
										| replace("power-cable", "Power cable")
										| replace("other-cable", "Cable (Other)")
										| replace("labo", "Labo")
								}}
							</template>
							<template v-slot:item.size="{ item }">
								{{ item.size }}<span v-if="item.size">m</span>
							</template>
							<template v-slot:item.info="{ item }">
								<v-btn
									outlined
									color="#262626"
									style="text-transform: none"
									small
									@click="onClickRow(item)"
									>Info</v-btn
								>
							</template>
						</v-data-table>

						<v-list-item-group
							color="white"
							v-if="!$vuetify.breakpoint.mdAndUp"
						>
							<v-list-item
								v-for="(item, i) in dbitemsSort_ab"
								:key="i"
								@click="onClickRow(item)"
							>
								<v-row>
									<v-list-item-content class="mobileListContent">
										<v-divider></v-divider>
										<v-col cols="6" md="3">
											<div class="mobileList" v-if="item.manufacturer">
												<v-list-item-subtitle class="text-subtitle-2"
													>Manufacturer</v-list-item-subtitle
												>
												<v-list-item-subtitle
													class="text-subtitle-1"
													v-text="item.manufacturer"
												></v-list-item-subtitle>
											</div>
											<div v-if="item.manufacturer" class="py-1"></div>
											<div class="mobileList">
												<v-list-item-subtitle class="text-subtitle-2"
													>Model</v-list-item-subtitle
												>
												<v-list-item-subtitle
													class="text-subtitle-1"
													v-text="item.model"
												></v-list-item-subtitle
												><span v-if="item.size">{{ item.size }}m</span>
											</div>
										</v-col>
										<v-col cols="6" md="3">
											<div class="mobileList">
												<v-list-item-subtitle class="text-subtitle-2"
													>Category</v-list-item-subtitle
												>
												<v-list-item-subtitle class="text-subtitle-1">{{
													item.category
														| replace("camera", "Camera")
														| replace("lens", "Lens")
														| replace("recorder", "Recorder / Monitor")
														| replace("media", "Media")
														| replace("tripod", "Tripod/Gimbal/Jib")
														| replace("light", "Light")
														| replace("switcher", "Switcher")
														| replace("video-cable", "Video cable")
														| replace("audio-cable", "Audio cable")
														| replace("lan-cable", "LAN cable")
														| replace("power-cable", "Power cable")
														| replace("other-cable", "Cable (Other)")
														| replace("labo", "Labo")
												}}</v-list-item-subtitle>
											</div>
											<div class="py-1"></div>
											<div class="mobileList">
												<v-list-item-subtitle class="text-subtitle-2"
													>Stock</v-list-item-subtitle
												>
												<v-list-item-subtitle
													class="text-subtitle-1"
													v-text="item.stock"
												></v-list-item-subtitle>
											</div>
										</v-col>
									</v-list-item-content>
								</v-row>
							</v-list-item>
						</v-list-item-group>
					</v-card>
				</div>
				<!-- </v-expand-transition> -->
			</v-tab-item>
			<v-tab-item
				transition="fade-transition"
				reverse-transition="fade-transition"
			>
				<v-tabs
					v-model="listInActive"
					color="black"
					background-color="transparent"
				>
					<v-tab
						v-for="item in listInCamera"
						@click="clickTabMenu(item)"
						v-bind:key="item.id"
						:class="{ 'd-none': $vuetify.breakpoint.mdAndDown }"
					>
						<v-list-item class="tabsItem">{{ item.name }}</v-list-item></v-tab
					>

					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									v-for="(item, index) in cameraFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card>
								</v-col>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders"
									:items="cameraFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>

								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in cameraFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									class="listCat"
									v-for="(item, index) in lensFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders"
									:items="lensFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>

								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in lensFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									class="listCat"
									v-for="(item, index) in recorderFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders"
									:items="recorderFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>
								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in recorderFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									class="listCat"
									v-for="(item, index) in mediaFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders"
									:items="mediaFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>
								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in mediaFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									class="listCat"
									v-for="(item, index) in tripodFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>

						<div v-if="show">
							<!-- <v-expand-transition> -->
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders"
									:items="tripodFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>
								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in tripodFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
							<!-- </v-expand-transition> -->
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									class="listCat"
									v-for="(item, index) in lightFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders"
									:items="lightFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>
								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in lightFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
				</v-tabs>
			</v-tab-item>
			<v-tab-item
				transition="fade-transition"
				reverse-transition="fade-transition"
			>
				<v-tabs
					v-model="listInActive_VJ"
					color="black"
					background-color="transparent"
				>
					<v-tab
						v-for="item in listInVJ"
						@click="clickTabMenu_VJ(item)"
						v-bind:key="item.id"
						:class="{ 'd-none': $vuetify.breakpoint.mdAndDown }"
					>
						<v-list-item class="tabsItem">{{ item.name }}</v-list-item></v-tab
					>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									v-for="(item, index) in switcherFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders"
									:items="switcherFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>
								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in switcherFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
				</v-tabs>
			</v-tab-item>
			<v-tab-item
				transition="fade-transition"
				reverse-transition="fade-transition"
			>
				<v-tabs
					v-model="listInActive_Cable"
					color="black"
					background-color="transparent"
				>
					<v-tab
						v-for="item in listInCable"
						@click="clickTabMenu_CABLE(item)"
						v-bind:key="item.id"
						:class="{ 'd-none': $vuetify.breakpoint.mdAndDown }"
					>
						<v-list-item class="tabsItem">{{ item.name }}</v-list-item></v-tab
					>

					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									v-for="(item, index) in videocableFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders_Cable"
									:items="videocableFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>

								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in videocableFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									class="listCat"
									v-for="(item, index) in audiocableFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders_Cable"
									:items="audiocableFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>

								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in audiocableFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									class="listCat"
									v-for="(item, index) in lancableFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders_Cable"
									:items="lancableFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>

								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in lancableFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									class="listCat"
									v-for="(item, index) in powercableFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders_Cable"
									:items="powercableFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>

								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in powercableFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									class="listCat"
									v-for="(item, index) in othercableFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders_Cable"
									:items="othercableFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>

								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in othercableFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
				</v-tabs>
			</v-tab-item>
			<v-tab-item
				transition="fade-transition"
				reverse-transition="fade-transition"
			>
				<v-tabs
					v-model="listInActive_Labo"
					color="black"
					background-color="transparent"
				>
					<v-tab
						v-for="item in listInLabo"
						@click="clickTabMenu_LABO(item)"
						v-bind:key="item.id"
						:class="{ 'd-none': $vuetify.breakpoint.mdAndDown }"
					>
						<v-list-item class="tabsItem">{{ item.name }}</v-list-item></v-tab
					>
					<v-tab-item
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<div v-if="!show">
							<v-row>
								<v-col
									cols="6"
									md="3"
									v-for="(item, index) in laboFilterItems"
									:key="index"
								>
									<v-card @click="onClickRow(item)" elevation="0" flat tile
										><v-img
											:height="gridImageHeight"
											contain
											class="white--text align-center"
											v-bind:src="item.item_thumbnail_0"
											gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
										>
											<v-card-title
												class="justify-center text-sm-subtitle-2 text-md-subtitle-1 text-lg-h5 text-xl-h5 text-subtitle-2 font-weight-light pb-0"
												v-if="item.manufacturer"
												>{{ item.manufacturer }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="!item.size"
												>{{ item.model }}</v-card-title
											>
											<v-card-title
												class="justify-center text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h4 text-subtitle-1 font-weight-regular pb-0"
												v-if="item.size"
												>{{ item.model }} {{ item.size }}m</v-card-title
											>
										</v-img>
									</v-card></v-col
								>
							</v-row>
						</div>
						<!-- <v-expand-transition> -->
						<div v-if="show">
							<v-card elevation="0" flat tile>
								<v-data-table
									v-if="$vuetify.breakpoint.mdAndUp"
									:headers="listHeaders"
									:items="laboFilterItems"
									:items-per-page="30"
									:footer-props="{ 'items-per-page-text': '' }"
									class="elevation-0"
								>
									<template v-slot:item.category="{ item }">
										{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}
									</template>
									<template v-slot:item.size="{ item }">
										{{ item.size }}<span v-if="item.size">m</span>
									</template>
									<template v-slot:item.info="{ item }">
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											small
											@click="onClickRow(item)"
											>Info</v-btn
										>
									</template>
								</v-data-table>

								<v-list-item-group
									color="white"
									v-if="!$vuetify.breakpoint.mdAndUp"
								>
									<v-list-item
										v-for="(item, i) in laboFilterItems"
										:key="i"
										@click="onClickRow(item)"
									>
										<v-row>
											<v-list-item-content class="mobileListContent">
												<v-divider></v-divider>
												<v-col cols="6" md="3">
													<div class="mobileList" v-if="item.manufacturer">
														<v-list-item-subtitle class="text-subtitle-2"
															>Manufacturer</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.manufacturer"
														></v-list-item-subtitle>
													</div>
													<div v-if="item.manufacturer" class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Model</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.model"
														></v-list-item-subtitle
														><span v-if="item.size">{{ item.size }}m</span>
													</div>
												</v-col>
												<v-col cols="6" md="3">
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Category</v-list-item-subtitle
														>
														<v-list-item-subtitle class="text-subtitle-1">{{
															item.category
																| replace("camera", "Camera")
																| replace("lens", "Lens")
																| replace("recorder", "Recorder / Monitor")
																| replace("media", "Media")
																| replace("tripod", "Tripod/Gimbal/Jib")
																| replace("light", "Light")
																| replace("switcher", "Switcher")
																| replace("video-cable", "Video cable")
																| replace("audio-cable", "Audio cable")
																| replace("lan-cable", "LAN cable")
																| replace("power-cable", "Power cable")
																| replace("other-cable", "Cable (Other)")
																| replace("labo", "Labo")
														}}</v-list-item-subtitle>
													</div>
													<div class="py-1"></div>
													<div class="mobileList">
														<v-list-item-subtitle class="text-subtitle-2"
															>Stock</v-list-item-subtitle
														>
														<v-list-item-subtitle
															class="text-subtitle-1"
															v-text="item.stock"
														></v-list-item-subtitle>
													</div>
												</v-col>
											</v-list-item-content>
										</v-row>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</div>
						<!-- </v-expand-transition> -->
					</v-tab-item>
				</v-tabs>
			</v-tab-item>
		</v-tabs>
		<div>
			<v-dialog
				v-model="dialogInfo_i"
				@click:outside="dialog_outside"
				@close="closeModal"
				max-width="600px"
				class="ma-0"
			>
				<Infomation
					v-if="dialogInfo_i"
					:ListInfo="ListInfo"
					@input="ListInfo = $event"
				/>
			</v-dialog>
			<v-snackbar v-model="Notification_AddItem" top :timeout="1500">
				追加されました

				<template v-slot:action="{ attrs }">
					<v-btn
						color="red"
						text
						v-bind="attrs"
						@click="$store.commit('set_Notification_AddItem', false)"
					>
						Close
					</v-btn>
				</template>
			</v-snackbar>
			<v-snackbar v-model="Notification_Checkout" top :timeout="1500">
				追加されました

				<template v-slot:action="{ attrs }">
					<v-btn
						color="red"
						text
						v-bind="attrs"
						@click="$store.commit('set_Notification_Checkout', false)"
					>
						Close
					</v-btn>
				</template>
			</v-snackbar>
			<v-snackbar v-model="Notification_Checkout_Err" top timeout="1500">
				追加に失敗しました

				<template v-slot:action="{ attrs }">
					<v-btn
						color="red"
						text
						v-bind="attrs"
						@click="$store.commit('set_Notification_Checkout_Err', false)"
					>
						Close
					</v-btn>
				</template>
			</v-snackbar>
			<v-snackbar
				v-model="Notification_Store_Err"
				top
				timeout="1500"
				multi-line
			>
				不正なデータが検出されました。以降のデータは読み込まれません。

				<template v-slot:action="{ attrs }">
					<v-btn
						color="red"
						text
						v-bind="attrs"
						@click="$store.commit('set_Notification_Store_Err', false)"
					>
						Close
					</v-btn>
				</template>
			</v-snackbar>
		</div>
	</div>
</template>

<script>
	import Infomation from "./Infomation";
	import firebase from "firebase";
	import _ from "lodash";

	export default {
		name: "List",
		components: {
			Infomation,
		},
		filters: {
			replace: function (st, rep, repWith) {
				const result = st.split(rep).join(repWith);
				return result;
			},
		},
		computed: {
			dbItemsOutput() {
				return Object.entries(this.dbitems)
					.map((item) => item[1])
					.filter((item) => item.status === "ok");
			},
			dbCategoryOutput() {
				return Object.entries(this.$store.getters.alldbCategory).map(
					(item) => item[1]["value"]
				);
			},
			count() {
				return this.$store.getters.getCount;
			},
			dialogInfo_i() {
				return this.$store.getters.getDialogInfo_i;
			},
			exList() {
				return this.$store.getters.getexList;
			},
			switch() {
				return this.filterSwitch;
			},
			tabMenu() {
				return this.$store.getters.gettabMenu;
			},
			tabMenuContent() {
				return this.$store.getters.gettabMenuContent;
			},
			tabMenuContentName() {
				return this.$store.getters.gettabMenuContentName;
			},
			Notification_AddItem() {
				return this.$store.getters.get_Notification_AddItem;
			},
			Notification_Checkout() {
				return this.$store.getters.get_Notification_Checkout;
			},
			Notification_Checkout_Err() {
				return this.$store.getters.get_Notification_Checkout_Err;
			},
			Notification_Store_Err() {
				return this.$store.getters.get_Notification_Store_Err;
			},

			searchfilter() {
				return Object.entries(this.dbItemsOutput)
					.map((item) => item[1])
					.filter((item) => {
						return (
							item.model.toLowerCase().includes(this.search.toLowerCase()) ||
							item.manufacturer
								.toLowerCase()
								.includes(this.search.toLowerCase())
						);
					});
			},

			dbItemsSort() {
				return Object.entries(this.searchfilter)
					.map((item) => item[1])
					.sort((a, b) => {
						// let textA = a.category.toUpperCase();
						// let textB = b.category.toUpperCase();
						// return textA < textB ? -1 : textA > textB ? 1 : 0;
						//priceの昇順
						if (a.category.toUpperCase() > b.category.toUpperCase()) return 1;
						if (a.category.toUpperCase() < b.category.toUpperCase()) return -1;

						// if (
						// 	this.dbCategoryOutput.indexOf(a.category.toUpperCase()) -
						// 	this.dbCategoryOutput.indexOf(b.category.toUpperCase())
						// )
						// 	return -1;

						//noの降順
						if (a.model.toUpperCase() < b.model.toUpperCase()) return -1;
						if (a.model.toUpperCase() > b.model.toUpperCase()) return 1;
						if (a.size || b.size) {
							if (Number(a.size) < Number(b.size)) return -1;
							if (Number(a.size) > Number(b.size)) return 1;
						}
						return 0;
					});
			},
			dbitemsSort_ab() {
				return _.sortBy(this.dbItemsSort, (i) => {
					return this.dbCategoryOutput.indexOf(i.category);
				});
			},

			//GLOBAL FILTER SHOOT
			SHOOTFilterItems() {
				return Object.entries(this.listInCamera)
					.map((item) => item[1])
					.filter((item) => item === item.match(/SH$/g));
			},

			//GLOBAL FILTER VJ
			VJFilterItems() {
				return Object.entries(this.listInVJ)
					.map((item) => item[1])
					.filter((item) => item === item.match(/SH$/g));
			},

			//GLOBAL FILTER CABLE
			CABLEFilterItems() {
				return Object.entries(this.listInCable)
					.map((item) => item[1])
					.filter((item) => item === item.match(/SH$/g));
			},

			//GLOBAL FILTER LABO
			LABOFilterItems() {
				return Object.entries(this.listInLabo)
					.map((item) => item[1])
					.filter((item) => item === item.match(/SH$/g));
			},

			//SHOOT FILTER
			cameraFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "camera")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},
			lensFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "lens")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},
			recorderFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "recorder")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},
			monitorFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "monitor")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},
			mediaFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "media")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},
			tripodFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "tripod")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},
			lightFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "light")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},

			//VJ FILTER
			switcherFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "switcher")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},

			//CABLE FILTER
			videocableFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "video-cable")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},

			audiocableFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "audio-cable")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},

			lancableFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "lan-cable")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},

			powercableFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "power-cable")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},

			othercableFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "other-cable")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},

			//LABO FILTER
			laboFilterItems() {
				return Object.entries(this.dbitemsSort_ab)
					.map((item) => item[1])
					.filter((item) => item.category === "labo")
					.filter((item) => {
						return item.model.toLowerCase().includes(this.search.toLowerCase());
					});
			},
			gridImageHeight() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 150;
					case "sm":
						return 150;
					case "md":
						return 150;
					case "lg":
						return 250;
					case "xl":
						return 250;
					default:
						return 150;
				}
			},
			qrReadID() {
				return this.$store.getters.get_qrReadID;
			},
		},
		created() {
			this.$store.dispatch("getdbItems");
			this.$store.dispatch("getdbCategory");
			// this.items = this.dbItemsOutput;

			const db = firebase.firestore();
			// データ取得
			db.collection("equipments")
				.get()
				.then((querysnapshot) => {
					querysnapshot.forEach((doc) => {
						this.dbitems.push(doc.data());
					});
				})
				.catch((error) => {
					console.log(error);
					// テーブルリセット
					this.dbitems = [];
				});
		},
		watch: {
			tabMenu: function () {
				// let setTabIndex = this.$store.getters("gettabMenu");

				let setTabIndex = this.tabMenu;

				if (setTabIndex == 0) {
					this.selectedName = null;
					this.selectedCat = "All";
					this.listInActive = null;
					this.activeTab = 0;
				}
				if (setTabIndex == 1) {
					this.selectedName = "Camera";
					this.selectedCat = this.listInCategory[setTabIndex].name;
					this.listInActive = 0;
					this.activeTab = 1;
					// console.log(this.selectedCat);
				}
				if (setTabIndex == 2) {
					this.selectedName = "Switcher";
					this.selectedCat = this.listInCategory[setTabIndex].name;
					this.activeTab = 2;
					this.listInActive_VJ = 0;
				}
				if (setTabIndex == 3) {
					this.selectedName = "Video";
					this.selectedCat = this.listInCategory[setTabIndex].name;
					this.activeTab = 3;
					this.listInActive_Cable = 0;
				}
				if (setTabIndex == 4) {
					this.selectedName = "Labo";
					this.selectedCat = this.listInCategory[setTabIndex].name;
					this.activeTab = 4;
					this.listInActive_Labo = 0;
				} else {
					this.selectedCat = this.listInCategory[setTabIndex].name;
				}
				// this.selectedCat = this.listInCategory[selectedIndex].name;
			},
			tabMenuContent() {
				let setTabContentIndex = this.tabMenuContent;
				// let setTabContentName = this.tabMenuContentName;

				let setTabIndex = this.tabMenu;

				// let selectedIndex = this.listInCamera.indexOf(item);
				// console.log(selectedIndex, this.selectedName);

				if (this.activeTab == 1) {
					this.selectedName = "Camera";
					this.selectedCat = this.listInCategory[setTabIndex].name;
					this.listInActive = setTabContentIndex - 1;
					this.selectedName = this.listInCamera[setTabContentIndex - 1].name;
				}
				if (this.activeTab == 2) {
					this.selectedName = "Switcher";
					this.selectedCat = this.listInCategory[setTabIndex].name;
					this.listInActive_VJ = setTabContentIndex - 1;
					this.selectedName = this.listInCamera[setTabContentIndex - 1].name;
				}
				if (this.activeTab == 3) {
					this.selectedName = "Video";
					this.selectedCat = this.listInCategory[setTabIndex].name;
					this.listInActive_Cable = setTabContentIndex - 1;
					this.selectedName = this.listInCamera[setTabContentIndex - 1].name;
				}
				if (this.activeTab == 4) {
					this.selectedName = "Labo";
					this.selectedCat = this.listInCategory[setTabIndex].name;
					this.listInActive_Labo = setTabContentIndex - 1;
					this.selectedName = this.listInCamera[setTabContentIndex - 1].name;
				}

				// console.log(setTabContentIndex, setTabContentName);
			},
		},
		methods: {
			onClickRow: function (item) {
				if (this.dialogInfo_i == false) {
					this.ListInfo.splice(0, this.ListInfo.length);
				}

				// this.dialog = true;
				// this.$store.commit.setDialogInfo_i("dialog", true);

				this.$store.commit("setDialogInfo_i", true); // this.dialog = true;
				this.ListInfo.push(...[item]);

				// const selected = this.dbItemsOutput.indexOf(item);
				// this.$store.commit("addToCart", this.dbItemsOutput[selected]);
			},
			dialog_outside: function () {
				this.$router.push({
					path: "/rent",
				});
				this.ListInfo = [];
				this.$store.commit("setDialogInfo_i", false);
				this.$store.commit("setCount", 1);
			},
			clickTabMenu(item) {
				let selectedIndex = this.listInCamera.indexOf(item);
				this.selectedName = this.listInCamera[selectedIndex].name;
				console.log(selectedIndex, this.selectedName);
			},

			clickTabMenu_VJ(item) {
				let selectedIndex = this.listInVJ.indexOf(item);
				this.selectedName = this.listInVJ[selectedIndex].name;
			},
			clickTabMenu_CABLE(item) {
				let selectedIndex = this.listInCable.indexOf(item);
				this.selectedName = this.listInCable[selectedIndex].name;
			},
			clickTabMenu_LABO(item) {
				let selectedIndex = this.listInLabo.indexOf(item);
				this.selectedName = this.listInLabo[selectedIndex].name;
			},
			clickTabMenucat: function (item) {
				let selectedIndex = this.listInCategory.indexOf(item);
				// this.$store.commit("settabMenu", selectedIndex);

				let setTabIndex = this.$store.commit("settabMenu", selectedIndex);

				if (setTabIndex == 0) {
					this.selectedName = null;
					this.selectedCat = "All";
					this.listInActive = null;
				}
				if (setTabIndex == 1) {
					this.selectedName = "Camera";
					this.selectedCat = this.listInCategory[selectedIndex].name;
					this.listInActive = 0;
					// console.log(this.selectedCat);
				}
				if (setTabIndex == 2) {
					this.selectedName = "Switcher";
					this.selectedCat = this.listInCategory[selectedIndex].name;
					this.listInActive_VJ = 0;
				}
				if (setTabIndex == 3) {
					this.selectedName = "Video";
					this.selectedCat = this.listInCategory[selectedIndex].name;
					this.listInActive_Cable = 0;
				}
				if (setTabIndex == 4) {
					this.selectedName = "Labo";
					this.selectedCat = this.listInCategory[selectedIndex].name;
					this.listInActive_Labo = 0;
				} else {
					this.selectedCat = this.listInCategory[selectedIndex].name;
				}
				// this.selectedCat = this.listInCategory[selectedIndex].name;
			},
			increment() {
				this.$store.dispatch("increment");
			},
			decrement() {
				this.$store.dispatch("decrement");
			},
			closeModal() {
				this.ListInfo = [];
				this.dialog = false;
			},
			// camerafSw: function() {
			// 	console.log("test");

			itemfSW(click) {
				// console.log(click);
				if (click == "camerafSw") {
					this.filterSwitch = "camerafSw";
				}
				if (click == "lensfSw") {
					this.filterSwitch = "lensfSw";
				}
				if (click == "recorderfSw") {
					this.filterSwitch = "recorderfSw";
				}
				if (click == "mediafSw") {
					this.filterSwitch = "mediafSw";
				}
				if (click == "tripodfSw") {
					this.filterSwitch = "tripodfSw";
				}
				if (click == "lightfSw") {
					this.filterSwitch = "lightfSw";
				}
			},
			qrSwitch() {
				if (!this.qrModeSwitch) {
					this.qrModeSwitchText = "Realtime";
					this.qrStoreField = null;
					this.exQRPanel = false;
				} else if (this.qrModeSwitch) {
					this.qrModeSwitchText = "Store";
					this.qrField = null;
					this.exQRPanel = false;
				}
			},
			qrSearch() {
				// this.$nextTick(function () {
				var self = this;
				let items = this.dbItemsOutput;

				if (!this.qrModeSwitch) {
					var urlStr = this.qrField;

					if (isValidHttpUrl(urlStr)) {
						var url = new URL(urlStr);
						var urlParams = new URLSearchParams(url.search);
						var getParamsID = urlParams.get("id");

						asynchronyFunction();

						setTimeout(() => {
							this.$store.commit("set_Notification_AddItem", false);
						}, 1500);
					} else if (!isValidHttpUrl(urlStr)) {
						this.$store.commit("set_Notification_Checkout_Err", true);
						setTimeout(() => {
							this.$store.commit("set_Notification_Checkout_Err", false);
						}, 1500);
					}
				} else if (this.qrModeSwitch) {
					var qrStoreArr = this.qrStoreField.split(/\n/).filter(function (s) {
						return s !== "";
					});
					for (let i = 0; i < qrStoreArr.length; i++) {
						urlStr = qrStoreArr[i];
						if (isValidHttpUrl(urlStr)) {
							url = new URL(urlStr);
							urlParams = new URLSearchParams(url.search);
							getParamsID = urlParams.get("id");

							items = this.dbItemsOutput.filter(
								(item) => item.id === getParamsID
							);

							this.ListInfo.push(...items);
							this.$store.commit("addToCart", ...items);
							this.$store.commit("set_Notification_AddItem", true);

							this.ListInfo = [];
							this.qrField = null;
							this.$store.commit("setexList", 0);

							// asynchronyFunction();

							setTimeout(() => {
								this.$store.commit("set_Notification_AddItem", false);
							}, 100);
						} else if (!isValidHttpUrl(urlStr)) {
							this.$store.commit("set_Notification_Store_Err", true);

							setTimeout(() => {
								this.$store.commit("set_Notification_Store_Err", false);
							}, 1500);
							return;
						}
					}
				}

				function isValidHttpUrl(string) {
					let url;

					try {
						url = new URL(string);
					} catch (_) {
						return false;
					}

					return url.protocol === "http:" || url.protocol === "https:";
				}

				function asynchronyFunction() {
					return new Promise((resolve) => {
						setTimeout(() => {
							var modelName = getParamsID;
							items = self.dbItemsOutput.filter((v) =>
								v.id.includes(modelName)
							);
							self.ListInfo.push(...items);
							self.$store.commit("addToCart", ...items);
							self.$store.commit("set_Notification_AddItem", true);
							resolve();
						}, 10);
					})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									self.ListInfo = [];
									self.qrField = null;
									self.$store.commit("setexList", 0);
									resolve();
								}, 10);
							});
						})

						.catch(() => {
							self.$store.commit("set_Notification_Checkout_Err", true);
						});
				}

				// });
			},
			qrSearchStore() {
				console.log(this.qrStoreField);
				var qrStoreArr = this.qrStoreField.split(/\n/);
				console.log(qrStoreArr);
			},
			qrSearchisMobile() {
				setTimeout(() => {
					let items = this.dbItemsOutput;
					var urlParams = new URLSearchParams(window.location.search);
					var getParamsID = urlParams.get("id");
					var getParamsModel = urlParams.get("model");
					items = items.filter((v) => v.id.includes(getParamsID));

					// モバイル対応
					if (this.$isMobile()) {
						this.$router.push({
							path: "/rent/info",
							query: { id: getParamsID, model: getParamsModel },
							hash: "#auto",
						});
						var getHash = location.hash;
						if (getHash) {
							if (getHash == "#auto") {
								this.$store.commit("addToCart", ...items);
								this.$store.commit("setexList", 0);
							}
						}
					}
				}, 2000);
			},
			resetQRField() {
				this.qrField = null;
				this.qrStoreField = null;
			},
		},

		mounted() {
			this.items = this.dbItemsOutput;
			this.qrSearchisMobile();
		},
		// computed: mapState("db", {
		// 	pref_list: "pref_list",
		// }),
		data: () => ({
			search: "",
			selectedName: null,
			selectedCat: "All",
			cFi: [],
			active: "0",
			nestedActive: ["0", "0", "0"],
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

			ListInfo: [],
			show: false,
			items: [],
			dialog: false,
			filterSwitch: "",

			exQRPanel: false,

			listHeaders: [
				{
					text: "Category",
					align: "start",
					sortable: false,
					value: "category",
					width: "20%",
				},
				{ text: "Manufacturer", value: "manufacturer", width: "20%" },
				{ text: "Model", value: "model", width: "20%" },
				{ text: "Stock", value: "stock", width: "20%" },
				{ text: "Info", value: "info", width: "20%" },
			],

			listHeaders_Cable: [
				{
					text: "Category",
					align: "start",
					sortable: false,
					value: "category",
					width: "16.6%",
				},
				{ text: "Manufacturer", value: "manufacturer", width: "16.6%" },
				{ text: "Model", value: "model", width: "16.6%" },
				{ text: "Stock", value: "stock", width: "16.6%" },
				{ text: "Cable length", value: "size", width: "16.6%" },
				{ text: "Info", value: "info", width: "16.6%" },
			],

			listInCamera: [
				{ id: 1, name: "Camera", click: "camerafSw_SH" },
				{ id: 2, name: "Lens", click: "lensfSw_SH" },
				{ id: 3, name: "Recorder/Monitor", click: "recorderfSw_SH" },
				{ id: 4, name: "Media", click: "mediafSw_SH" },
				{ id: 5, name: "Tripod/Gimbal/Jib", click: "tripodfSw_SH" },
				{ id: 6, name: "Light", click: "lightfSw_SH" },
			],

			listInVJ: [{ id: 1, name: "Switcher", click: "switcherfSw_VJ" }],

			listInCable: [
				{ id: 1, name: "Video", click: "videofSw_CA" },
				{ id: 2, name: "Audio", click: "audiofSw_CA" },
				{ id: 3, name: "LAN", click: "lanfSw_CA" },
				{ id: 4, name: "Power", click: "powerfSw_CA" },
				{ id: 5, name: "Other", click: "otherfSw_CA" },
			],

			listInLabo: [{ id: 1, name: "Labo", click: "labofSw_LA" }],

			listInActive: null,
			listInActive_VJ: null,
			listInActive_Cable: null,
			listInActive_Labo: null,

			listInCategory: [
				{ name: "All" },
				{ name: "Shoot" },
				{ name: "VJ" },
				{ name: "Cable" },
				{ name: "Labo" },
			],

			listInfoPanel: false,

			dbitems: [],

			// Tab 別の場所から
			activeTab: 0,

			//QR用テキストフィールド
			qrField: null,
			qrStoreField: null,
			qrModeSwitch: false,
			qrModeSwitchText: "Realtime",

			vForm: false,
		}),
	};
</script>
<style>
	.listCat.col {
		flex-grow: 0;
	}
	.v-data-table {
		border-radius: 0px !important;
	}
	.v-data-table td {
		background: #eee;
	}
	.v-data-table tr:nth-child(odd) td {
		background: #fff;
	}
	.v-data-table tr:hover td {
		background-color: #ddd;
	}
	.row.switchUI {
		justify-content: flex-end;
		margin: 0;
	}
	span.countNum {
		font-weight: bold;
	}
	button.addBtn span {
		color: #fff;
	}
	.theme--light.v-list {
		background: transparent;
	}
	div.v-list.v-sheet.theme--light {
		background-color: transparent;
	}
	div.v-window.v-item-group.theme--light.v-tabs-items {
		background-color: transparent;
	}
	.imgCover::before {
		background-color: rgba(0, 0, 0, 0.4);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.tabsItem {
		padding: 0;
		min-width: 100px;
		max-width: 100px;
		align-items: center;
		justify-content: center;
		font-size: 13px;
	}

	/* .tabsItem.v-list-item theme--light {
		text-align: center;
	} */

	/* .theme--light.v-tabs > .v-tabs-bar {
		border-radius: inherit;
		height: 0px;
	}

	@media screen and (min-width: 1264px) {
		.theme--light.v-tabs > .v-tabs-bar {
			border-radius: inherit;
			height: 48px;
		}
	} */
</style>
<style scoped>
	.mobileList {
		border-left: 2px solid #262626;
		padding: 2px 8px;
	}

	@media screen and (max-width: 1264px) {
		::v-deep .v-tabs-bar {
			display: none !important;
			height: 0px !important;
		}
	}

	::v-deep .v-dialog {
		/* position: relative;
			top: -10%; */
		margin: 0px !important;
	}

	::v-deep .v-btn {
		border-radius: 0px;
	}

	::v-deep .v-toolbar__content {
		padding: 0px;
	}

	::v-deep .v-input--selection-controls {
		margin-top: 16px !important;
		padding-top: 4px !important;
	}

	::v-deep .v-list-item__subtitle {
		white-space: normal;
	}
	.mobileListContent {
		align-items: flex-start !important;
	}
</style>
