<template>
	<div>
		<v-card color="#262626" class="px-5">
			<div class="pt-5" />
			<v-row>
				<v-col class="text-center">
					<v-btn
						to="/admin"
						outlined
						color="white"
						style="text-transform: none"
						class="px-15 rounded-0"
						>Admin Console</v-btn
					>
				</v-col>
			</v-row>
			<div class="pb-5" />
			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="white"
					text
					@click="$store.commit('setmenuDialog', false)"
				>
					Close
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
	export default {
		name: "Menu",
		components: {},
		methods: {},
		computed: {},
		data: () => ({
			ecosystem: [
				{
					text: "vuetify-loader",
					href: "https://github.com/vuetifyjs/vuetify-loader",
				},
				{
					text: "github",
					href: "https://github.com/vuetifyjs/vuetify",
				},
				{
					text: "awesome-vuetify",
					href: "https://github.com/vuetifyjs/awesome-vuetify",
				},
			],
			importantLinks: [
				{
					text: "Documentation",
					href: "https://vuetifyjs.com",
				},
				{
					text: "Chat",
					href: "https://community.vuetifyjs.com",
				},
				{
					text: "Made with Vuetify",
					href: "https://madewithvuejs.com/vuetify",
				},
				{
					text: "Twitter",
					href: "https://twitter.com/vuetifyjs",
				},
				{
					text: "Articles",
					href: "https://medium.com/vuetify",
				},
			],
			whatsNext: [
				{
					text: "Explore components",
					href: "https://vuetifyjs.com/components/api-explorer",
				},
				{
					text: "Select a layout",
					href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
				},
				{
					text: "Frequently Asked Questions",
					href:
						"https://vuetifyjs.com/getting-started/frequently-asked-questions",
				},
			],
		}),
	};
</script>
