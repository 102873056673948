<template>
	<v-app>
		<Nav />
		<v-container bg fill-height grid-list-md text-xs-center>
			<v-layout>
				<v-flex>
					<v-main>
						<router-view />
					</v-main>
				</v-flex>
			</v-layout>
		</v-container>
		<Footer />
	</v-app>
</template>

<script>
	import Nav from "../components/Nav.vue";
	import Footer from "../components/Footer.vue";

	export default {
		name: "App",
		components: {
			Nav,
			Footer,
		},
		data() {
			return {};
		},
		methods: {},
		computed: {},
		created() {},
	};
</script>
<style scoped>
	.theme--light.v-application {
		background: #e6e6e6 !important;
	}
</style>
<style>
	html {
		height: -webkit-fill-available;
	}
	body {
		min-height: 100vh;
		min-height: -webkit-fill-available;
	}
</style>
