<template>
	<div>
		<Table_Checkout />
		<v-dialog v-model="menuDialog" width="500">
			<Menu />
		</v-dialog>
	</div>
</template>

<script>
	import Table_Checkout from "../components/Table_Checkout";
	import Menu from "../components/common/Menu";

	export default {
		name: "Return",

		components: {
			Table_Checkout,
			Menu,
		},
		computed: {
			menuDialog: {
				get: function() {
					return this.$store.getters.getmenuDialog;
				},
			},
		},
		methods: {
			onKeyDown(event) {
				if (event.ctrlKey) {
					if (event.altKey) {
						if (event.which === 53) {
							this.$store.commit("setmenuDialog", true);
						}
					}
				}
			},
		},
		mounted() {
			document.addEventListener("keydown", this.onKeyDown);
		},
		beforeDestroy() {
			document.removeEventListener("keydown", this.onKeyDown);
		},
		beforeRouteLeave(to, from, next) {
			this.$store.commit("setmenuDialog", false);
			next();
		},
	};
</script>
