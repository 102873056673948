<template>
	<div>
		<v-card flat>
			<div v-if="serverDataHide">
				{{ this.serverData }}
			</div>
			<v-card-title
				class="text-h6 text-md-h5 pt-2 pt-md-4 pb-2 pb-md-4 titleColor"
				color="primary"
			>
				Checkout
				<v-spacer></v-spacer>

				<v-btn icon @click="closeTest">
					<v-icon color="white">mdi-close-circle</v-icon>
				</v-btn>
			</v-card-title>
			<div>
				<v-stepper v-model="e1" non-linear>
					<v-stepper-header style="height: 48px">
						<v-stepper-step
							class="pr-6 pl-6 pt-2 pb-2"
							color="#262626"
							:complete="e1 > 1"
							step="1"
						>
							借りる機材を確認
						</v-stepper-step>

						<v-divider></v-divider>

						<v-stepper-step
							class="pr-6 pl-6 pt-2 pb-2"
							color="#262626"
							:complete="e1 > 2"
							step="2"
						>
							貸出日と返却予定日を決める
						</v-stepper-step>

						<v-divider></v-divider>

						<v-stepper-step
							class="pr-6 pl-6 pt-2 pb-2"
							color="#262626"
							:complete="e1 > 3"
							step="3"
						>
							案件名を入力
						</v-stepper-step>

						<v-divider></v-divider>

						<v-stepper-step
							class="pr-6 pl-6 pt-2 pb-2"
							color="#262626"
							:complete="e1 > 4"
							step="4"
						>
							貸出情報
						</v-stepper-step>
					</v-stepper-header>
					<v-stepper-items>
						<!-- <v-form ref="form" v-model="vForm"> -->
						<v-stepper-content step="1">
							<v-form ref="form" v-model="vForm_el1">
								<v-card v-if="reloadTable">
									<v-card-text>
										<div style="display: none">
											{{ addcartItems }}
										</div>
										<v-data-table
											v-if="$vuetify.breakpoint.mdAndUp"
											:headers="headers"
											:items="addcartItems"
											:items-per-page="20"
											item-key="name"
											class="elevation-0"
											:footer-props="{ 'items-per-page-text': '' }"
										>
											<template v-slot:item.category="{ item }">
												{{
													item.category
														| replace("camera", "Camera")
														| replace("lens", "Lens")
														| replace("recorder", "Recorder / Monitor")
														| replace("media", "Media")
														| replace("tripod", "Tripod/Gimbal/Jib")
														| replace("light", "Light")
														| replace("switcher", "Switcher")
														| replace("video-cable", "Video cable")
														| replace("audio-cable", "Audio cable")
														| replace("lan-cable", "LAN cable")
														| replace("power-cable", "Power cable")
														| replace("other-cable", "Cable (Other)")
														| replace("labo", "Labo")
												}}
											</template>
											<template v-slot:item.location_repo="{ item }">
												{{
													item.location_repo
														| replace("tech_room", "Tech Room")
														| replace("stock_room", "Stock Room")
												}}
											</template>
											<template v-slot:item.location_box="{ item }">
												{{
													item.location_box
														| replace("a1light", "A-1-Light")
														| replace("a1camera", "A-1-Camera")
														| replace("a1lens", "A-1-Lens")
														| replace("a2camera", "A-2-Camera")
														| replace("a3case", "A-3-Case")
														| replace("a41", "A-4-1")
														| replace("a42", "A-4-2")
														| replace("a43", "A-4-3")
														| replace("b1jib", "B-1-Jib")
														| replace("b1light", "B-1-Light")
														| replace("b2sdi", "B-2-SDI")
														| replace("b24", "B-2-4")
														| replace("b25", "B-2-5")
														| replace("b31", "B-3-1")
														| replace("b32", "B-3-2")
														| replace("b33", "B-3-3")
														| replace("b34", "B-3-4")
														| replace("b35", "B-3-5")
														| replace("c1display", "C-1-Display")
														| replace("c21", "C-2-1")
														| replace("c22", "C-2-2")
														| replace("c23", "C-2-3")
														| replace("c24", "C-2-4")
														| replace("c25", "C-2-5")
														| replace("c31", "C-3-1")
														| replace("c32", "C-3-2")
														| replace("c33", "C-3-3")
														| replace("c34", "C-3-4")
														| replace("c35", "C-3-5")
												}}
											</template>
											<template v-slot:item.quantity="props">
												<v-text-field
													v-model.number="props.item.quantity"
													label="個数"
													single-line
													type="Number"
													:rules="[required]"
													required
													min="1"
													:max="props.item.stock"
													onkeypress="return event.charCode >= 48"
													onkeydown="return event.keyCode !== 69"
													step="1"
												></v-text-field>
											</template>
										</v-data-table>

										<v-simple-table v-if="!$vuetify.breakpoint.mdAndUp" dense>
											<template v-slot:default>
												<thead>
													<tr>
														<th class="text-left">Model</th>
														<th class="text-left">Quantity</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="item in addcartItems" :key="item.name">
														<td>{{ item.model }}</td>
														<td>
															<v-text-field
																v-model.number="item.quantity"
																label="個数"
																single-line
																type="Number"
																class="itemField"
																min="1"
																:max="item.stock"
																onkeypress="return event.charCode >= 48"
																onkeydown="return event.keyCode !== 69"
															></v-text-field>
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>

										<v-card-actions>
											<v-btn
												color="#262626"
												elevation="0"
												@click="e1 = 2"
												class="checkoutBtn rounded-0"
												:disabled="!vForm_el1"
											>
												NEXT
											</v-btn>
										</v-card-actions>
									</v-card-text>
								</v-card>
							</v-form>
						</v-stepper-content>
						<v-stepper-content step="2">
							<v-card>
								<v-card-text>
									<v-row>
										<v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
											<v-date-picker
												v-model="dates"
												range
												full-width
												color="transparent"
												:min="this.valueCache"
												flat
												no-title
											></v-date-picker>
										</v-col>
										<v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
											<v-text-field
												v-model="dateRangeText"
												label="貸出日 - 返却日"
												prepend-icon="mdi-calendar"
												readonly
											></v-text-field>
										</v-col>
									</v-row>
									<v-card-actions>
										<v-btn
											color="#262626"
											elevation="0"
											@click="e1 = 1"
											class="mt-5 checkoutBtn rounded-0"
										>
											PREV
										</v-btn>
										<!-- <v-btn
										color="#262626"
										elevation="0"
										@click="e1 = 3"
										class="mt-5 checkoutBtn"
									>
										NEXT
									</v-btn> -->
										<template v-if="dateValidationHide">
											<v-btn
												color="#262626"
												elevation="0"
												@click="calandoverVal"
												class="mt-5 checkoutBtn rounded-0"
											>
												NEXT
											</v-btn>
										</template>
										<template v-if="!dateValidationHide">
											<v-btn
												color="#262626"
												elevation="0"
												@click.stop="e1 = 3"
												class="mt-5 checkoutBtn rounded-0"
												disabled
											>
												NEXT
											</v-btn>
										</template>
									</v-card-actions>
								</v-card-text>
							</v-card>
						</v-stepper-content>
						<v-stepper-content step="3">
							<v-form ref="form" v-model="vForm_el3">
								<v-card>
									<v-card-text>
										<v-text-field
											label="案件名を入力してください"
											counter="50"
											v-model="projects"
											:rules="[required]"
											required
										></v-text-field>
										<v-card-actions>
											<v-btn
												color="#262626"
												elevation="0"
												@click="e1 = 2"
												class="mt-5 checkoutBtn rounded-0"
											>
												PREV
											</v-btn>
											<v-btn
												color="#262626"
												elevation="0"
												@click.stop="e1 = 4"
												class="mt-5 checkoutBtn rounded-0"
												:disabled="!vForm_el3"
											>
												NEXT
											</v-btn>
											<!-- <template v-if="viewOutputLength">
										<v-btn
											color="#262626"
											elevation="0"
											@click.stop="errDialog = true"
											class="mt-5 checkoutBtn"
											:disabled="!vForm"
										>
											NEXT
										</v-btn>
									</template>
									<template v-if="!viewOutputLength">
										<v-btn
											color="#262626"
											elevation="0"
											@click.stop="e1 = 4"
											class="mt-5 checkoutBtn"
											:disabled="!vForm"
										>
											NEXT
										</v-btn>
									</template> -->
											<v-dialog
												v-model="errDialog"
												@click:outside="dialog_outside"
												max-width="600"
											>
												<v-card>
													<v-card-title
														class="text-h6 text-md-h5 pt-2 pt-md-4 pb-2 pb-md-4 titleColor"
														color="primary"
													>
														<!-- <div v-if="viewOutputLength">
													Error
												</div> -->
														<!-- <div v-if="!viewOutputLength"> -->
														貸出可能数を超過しています
														<!-- </div> -->
														<v-spacer></v-spacer>

														<v-btn icon @click="errDialog = false">
															<v-icon color="white">mdi-close-circle</v-icon>
														</v-btn>
													</v-card-title>

													<!-- <v-card-text> -->
													<div v-if="viewOutputLength">
														<v-simple-table>
															<template v-slot:default>
																<thead>
																	<tr>
																		<th class="text-left">Model</th>
																		<th class="text-left">Stock Calendar</th>
																	</tr>
																</thead>
																<tbody>
																	<tr
																		v-for="(item, index) in viewOutputInfo"
																		:key="item.id + item.model + index"
																	>
																		<td>{{ item.model }}</td>
																		<td>
																			<v-btn
																				color="red darken-4"
																				elevation="0"
																				outlined
																				class="rounded-0"
																				@click="dialogCalClick(item)"
																				>超過個数</v-btn
																			>
																		</td>
																	</tr>
																</tbody>
															</template>
														</v-simple-table>
													</div>
													<!-- <div v-if="!viewOutputLength">
													<v-row
														class="text-center justify-center align-center ma-0"
													>
														<v-card-text class="justify-center">
															<v-col>
																<v-icon color="#262626" size="150" v-cloak>
																	mdi-check-circle-outline
																</v-icon>
															</v-col>
														</v-card-text>
													</v-row>
												</div> -->
													<!-- </v-card-text> -->

													<!-- <v-card-actions>
												<v-spacer></v-spacer>
												<v-btn
													color="#262626"
													elevation="0"
													@click="
														e1 = 4;
														errDialog = false;
													"
													class="mt-5 checkoutBtn"
													:disabled="viewOutputLength"
												>
													NEXT
												</v-btn>
											</v-card-actions> -->
												</v-card>
											</v-dialog>
										</v-card-actions>
									</v-card-text>
								</v-card>
							</v-form>
						</v-stepper-content>
						<!-- </v-form> -->
						<v-stepper-content step="4">
							<v-card flat>
								<div>
									<div class="OutputPrintStyle" ref="OutputPrint">
										<v-card flat>
											<v-list-item>
												<v-list-item-content>
													<v-list-item-title
														class="subtitle-2 text-md-subtitle-1 font-weight-regular"
													>
														案件名：{{ projects }}
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>

											<v-list-item>
												<v-list-item-content>
													<v-list-item-title
														class="subtitle-2 text-md-subtitle-1 font-weight-regular"
														>ユーザー名：{{
															user.displayName
														}}</v-list-item-title
													>
												</v-list-item-content>
											</v-list-item>
											<v-list-item>
												<v-list-item-content>
													<v-list-item-title
														class="subtitle-2 text-md-subtitle-1 font-weight-regular"
														>貸出日：{{ dateValidation[0] }}</v-list-item-title
													>
												</v-list-item-content>
											</v-list-item>

											<v-list-item>
												<v-list-item-content>
													<v-list-item-title
														class="subtitle-2 text-md-subtitle-1 font-weight-regular"
														>返却日：{{ dateValidation[1] }}</v-list-item-title
													>
												</v-list-item-content>
											</v-list-item>
											<v-expansion-panels
												flat
												elevation="0"
												accordion
												:value="0"
											>
												<v-expansion-panel>
													<v-expansion-panel-header
														>機材名：</v-expansion-panel-header
													>
													<v-expansion-panel-content>
														<v-simple-table v-if="$vuetify.breakpoint.mdAndUp">
															<template v-slot:default>
																<thead>
																	<tr>
																		<th class="text-left" v-if="checkHide">
																			Check
																		</th>
																		<th class="text-left">Manufacturer</th>
																		<th class="text-left">Model</th>
																		<th class="text-left">Category</th>
																		<th class="text-left">Location</th>
																		<th class="text-left">Box</th>
																		<th class="text-left">Cable length</th>
																		<th class="text-left">Quantity</th>
																	</tr>
																</thead>
																<tbody>
																	<tr
																		v-for="(item, index) in addcartItems"
																		:key="item.id + index"
																	>
																		<td v-if="checkHide">
																			<v-icon
																				>mdi-checkbox-blank-outline</v-icon
																			>
																		</td>
																		<td>{{ item.manufacturer }}</td>
																		<td>{{ item.model }}</td>
																		<td>
																			{{
																				item.category
																					| replace("camera", "Camera")
																					| replace("lens", "Lens")
																					| replace(
																						"recorder",
																						"Recorder / Monitor"
																					)
																					| replace("media", "Media")
																					| replace(
																						"tripod",
																						"Tripod/Gimbal/Jib"
																					)
																					| replace("light", "Light")
																					| replace("switcher", "Switcher")
																					| replace(
																						"video-cable",
																						"Video cable"
																					)
																					| replace(
																						"audio-cable",
																						"Audio cable"
																					)
																					| replace("lan-cable", "LAN cable")
																					| replace(
																						"power-cable",
																						"Power cable"
																					)
																					| replace(
																						"other-cable",
																						"Cable (Other)"
																					)
																					| replace("labo", "Labo")
																			}}
																		</td>
																		<td>
																			{{
																				item.location_repo
																					| replace("tech_room", "Tech Room")
																					| replace("stock_room", "Stock Room")
																			}}
																		</td>
																		<td>
																			{{
																				item.location_box
																					| replace("a1light", "A-1-Light")
																					| replace("a1camera", "A-1-Camera")
																					| replace("a1lens", "A-1-Lens")
																					| replace("a2camera", "A-2-Camera")
																					| replace("a3case", "A-3-Case")
																					| replace("a41", "A-4-1")
																					| replace("a42", "A-4-2")
																					| replace("a43", "A-4-3")
																					| replace("b1jib", "B-1-Jib")
																					| replace("b1light", "B-1-Light")
																					| replace("b2sdi", "B-2-SDI")
																					| replace("b24", "B-2-4")
																					| replace("b25", "B-2-5")
																					| replace("b31", "B-3-1")
																					| replace("b32", "B-3-2")
																					| replace("b33", "B-3-3")
																					| replace("b34", "B-3-4")
																					| replace("b35", "B-3-5")
																					| replace("c1display", "C-1-Display")
																					| replace("c21", "C-2-1")
																					| replace("c22", "C-2-2")
																					| replace("c23", "C-2-3")
																					| replace("c24", "C-2-4")
																					| replace("c25", "C-2-5")
																					| replace("c31", "C-3-1")
																					| replace("c32", "C-3-2")
																					| replace("c33", "C-3-3")
																					| replace("c34", "C-3-4")
																					| replace("c35", "C-3-5")
																			}}
																		</td>
																		<td>{{ item.size }}</td>
																		<td>{{ item.quantity }}</td>
																	</tr>
																</tbody>
															</template>
														</v-simple-table>

														<v-simple-table v-if="!$vuetify.breakpoint.mdAndUp">
															<template v-slot:default>
																<thead>
																	<tr>
																		<!-- <th class="text-left" v-if="checkHide">
																			Check
																		</th> -->

																		<th class="text-left">Model</th>

																		<!-- <th class="text-left">
																			Location
																		</th>
																		<th class="text-left">
																			Box
																		</th>
																		<th class="text-left">
																			Cable length
																		</th> -->
																		<th class="text-left">Quantity</th>
																	</tr>
																</thead>
																<tbody>
																	<tr
																		v-for="(item, index) in addcartItems"
																		:key="item.id + index"
																	>
																		<!-- <td v-if="checkHide">
																			<v-icon
																				>mdi-checkbox-blank-outline</v-icon
																			>
																		</td> -->

																		<td>{{ item.model }}</td>

																		<!-- <td>{{ item.location_repo }}</td>
																		<td>{{ item.location_box }}</td>
																		<td>{{ item.size }}</td> -->
																		<td>{{ item.quantity }}</td>
																	</tr>
																</tbody>
															</template>
														</v-simple-table>
													</v-expansion-panel-content>
												</v-expansion-panel>
											</v-expansion-panels>
										</v-card>
										<v-divider></v-divider>
									</div>

									<v-card-actions>
										<v-btn
											color="#262626"
											elevation="0"
											@click="e1 = 3"
											class="mt-5 checkoutBtn rounded-0"
										>
											PREV
										</v-btn>
										<v-spacer></v-spacer>
										<v-col cols="auto">
											<v-btn
												outlined
												color="black"
												style="text-transform: none"
												@click="submitDB"
												:loading="loadingValidate"
												:disabled="!vForm"
												class="rounded-0"
												>SUBMIT</v-btn
											>
											<v-dialog
												transition="dialog-top-transition"
												max-width="600"
												v-model="dialog2"
												@click:outside="closeCompleted_outside"
											>
												<v-card>
													<v-toolbar color="#4C4C4C" elevation="0" dark
														>Completed<v-spacer></v-spacer
														><v-btn icon @click="closeCompleted">
															<v-icon color="white">mdi-close-circle</v-icon>
														</v-btn></v-toolbar
													>
													<v-row
														class="text-center justify-center align-center ma-0"
													>
														<v-card-text class="justify-center">
															<v-col>
																<v-icon color="#262626" size="150" v-cloak>
																	mdi-check-circle-outline
																</v-icon>
															</v-col>
															<v-col>
																<v-btn
																	large
																	outlined
																	color="#262626"
																	style="text-transform: none"
																	@click="print"
																	class="rounded-0"
																	><v-icon>mdi-printer</v-icon>Print</v-btn
																>
															</v-col>
														</v-card-text>
													</v-row>
													<v-snackbar
														:timeout="3000"
														:top="true"
														v-model="snackbar"
													>
														{{ text }}

														<template v-slot:action="{ attrs }">
															<v-btn
																color="pink"
																text
																v-bind="attrs"
																@click="snackbar = false"
															>
																Close
															</v-btn>
														</template>
													</v-snackbar>
												</v-card>
											</v-dialog>
										</v-col>
									</v-card-actions>
								</div>
							</v-card>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</div>
			<v-dialog
				v-model="dialogCalendar"
				@click:outside="dialog_outside"
				max-width="600px"
			>
				<v-card flat>
					<v-card-title
						class="text-h6 text-md-h5 pt-2 pt-md-4 pb-2 pb-md-4 titleColor"
						color="primary"
						>超過個数を確認
						<v-spacer></v-spacer>

						<v-btn icon @click="closeDialog">
							<v-icon color="white">mdi-close-circle</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-sheet>
							<v-toolbar flat class="pa-0 ma-0 mb-5">
								<v-btn
									fab
									text
									small
									color="grey darken-2"
									class="rounded-0"
									@click="$refs.calendar.prev(), prev()"
								>
									<v-icon small> mdi-chevron-left </v-icon>
								</v-btn>
								<v-spacer></v-spacer>
								<!-- <v-btn outlined small class="ma-4" @click="setToday">
									Today
								</v-btn> -->
								<v-toolbar-title>{{ datetitle }}</v-toolbar-title>
								<v-spacer></v-spacer>
								<v-btn
									fab
									text
									small
									color="grey darken-2"
									class="rounded-0"
									@click="$refs.calendar.next(), next()"
								>
									<v-icon small> mdi-chevron-right </v-icon>
								</v-btn>
							</v-toolbar>
							<v-alert text prominent type="error" icon="mdi-cloud-alert">
								指定した日付、{{
									dateRangeText
								}}では貸出可能数を超過しており貸出の手続きを完了することができません。下記のカレンダーから超過している日付を確認して、数量変更もしくは日付の変更をしてください。
							</v-alert>
							<v-row>
								<v-col cols="6">
									<v-btn
										fab
										text
										small
										block
										outlined
										color="red darken-2"
										class="mb-4 rounded-0"
										@click="backCheckout"
										>数量の変更をする</v-btn
									>
								</v-col>
								<v-col cols="6">
									<v-btn
										fab
										text
										small
										block
										outlined
										color="red darken-2"
										class="mb-4 rounded-0"
										@click="backCheckoutDay"
										>日付の変更をする</v-btn
									>
								</v-col>
							</v-row>
						</v-sheet>
						<div v-if="dialogCalendarHide">
							<v-sheet height="500px">
								<v-calendar
									ref="calendar"
									v-model="value"
									type="month"
									locale="ja-jp"
									class="rentCal ma-0 pa-0"
									color="white"
									:day-format="
										(timestamp) => new Date(timestamp.date).getDate()
									"
									:month-format="
										(timestamp) =>
											new Date(timestamp.date).getMonth() + 1 + ' /'
									"
									:events="resultmonthCal"
									:event-height="20"
								>
									<template v-slot:event="{ event, start }">
										<v-card
											v-if="start"
											:color="color"
											elevation="0"
											outlined
											class="transparentCard ma-0 pa-0"
										>
											<v-card-text
												class="pa-0"
												v-html="event.name"
												align="center"
												style="font-size: 1.5em; color: #ffffff"
											></v-card-text>
										</v-card> </template
								></v-calendar>
							</v-sheet>
						</div>
						<div v-if="!dialogCalendarHide"></div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-card>
	</div>
</template>

<script>
	import firebase from "firebase";
	import moment from "moment";
	// import merge from "deepmerge";
	// const SteinStore = require("stein-js-client");
	import _ from "lodash";
	// import CalendarInfomation from "./common/CalendarInfomation";

	export default {
		name: "Chackout",
		components: {
			// CalendarInfomation,
		},
		filters: {
			replace: function (st, rep, repWith) {
				const result = st.split(rep).join(repWith);
				return result;
			},
		},
		computed: {
			addcartItems() {
				return this.$store.getters.addcartItems;
			},
			//ログイン処理
			user() {
				return this.$store.getters.user;
			},
			userStatus() {
				// ログインするとtrue
				return this.$store.getters.isSignedIn;
			},
			//v-date-picker
			dateRangeText() {
				// if (this.dates.length == 0) {
				// 	return this.dateValidation.join("");
				// } else {
				return this.dateValidation.join(" ~ ");
				// }
			},
			dateValidationHide() {
				if (this.dates.length == 2) {
					return true;
				} else {
					return false;
				}
			},
			dateValidation() {
				var compareValue = moment(this.dates[0]).isSameOrBefore(this.dates[1]);

				if (compareValue == false) {
					return this.dates.slice().reverse();
				} else {
					return this.dates;
				}
			},
			dialogInfo() {
				return this.$store.getters.getDialogInfo;
			},
			dialogInfo_i() {
				return this.$store.getters.getDialogInfo_i;
			},
			exList() {
				return this.$store.getters.getexList;
			},
			addcartItemsFilter() {
				return this.saveDBItemsFlat;
			},
			dialogCalendar() {
				return this.$store.getters.get_dialogCalendar;
			},
			dialogCalendarHide() {
				return this.$store.getters.get_dialogCalendarHide;
			},
			datetitle() {
				return moment(this.value).format("yyyy年 M月"); // 表示用文字列を返す
			},
			datemoment() {
				return moment(this.value).format("YYYY-MM-DD");
			},
		},
		methods: {
			calandoverVal() {
				if (this.viewOutputLength) {
					this.errDialog = true;
				} else if (!this.viewOutputLength) {
					this.e1 = 3;
				}
			},
			backCheckout() {
				this.errDialog = false;
				this.e1 = 1;
				this.$store.commit("set_dialogCalendar", false);
				this.$store.commit("set_dialogCalendarHide", false);
			},

			backCheckoutDay() {
				this.errDialog = false;
				this.e1 = 2;
				this.$store.commit("set_dialogCalendar", false);
				this.$store.commit("set_dialogCalendarHide", false);
			},

			closeDialog() {
				this.$store.commit("set_dialogCalendar", false);
				this.$store.commit("set_dialogCalendarHide", false);
			},
			allowedDates(a) {
				return this.availableDates.includes(a);
			},
			pickerUpdate: async function () {
				var tempDates = this.calendarMappingFin;

				this.availableDates = tempDates;
				this.allowedDates();
			},
			closeTest() {
				this.dialog = false;
				this.dialog2 = false;
				this.$store.commit("setDialogInfo", false);
				this.$store.commit("set_dialogCalendarHide", false);
				this.viewOutputLength = false;

				this.reloadTable = false;
				// this.serverData = [];
				this.$nextTick(() => (this.reloadTable = true));
			},
			closeTest1() {
				this.dialog = false;
				this.dialog2 = false;
				this.$store.commit("setDialogInfo", false);
				this.$store.commit("set_dialogCalendarHide", false);
				this.viewOutputLength = false;

				this.reloadTable = false;
				// this.serverData = [];
				this.$nextTick(() => (this.reloadTable = true));
			},

			closeCompleted() {
				this.dialog = false;
				this.dialog2 = false;
				this.$store.commit("setDialogInfo", false);
				this.$store.commit("set_dialogCalendarHide", false);
				this.viewOutputLength = false;
				this.e1 = 1;
				this.checkHide = false;
				(this.addcartItems = []),
					(this.dates = []),
					(this.projects = ""),
					this.$refs.form.reset(),
					this.$store.commit("deleteData", []),
					(this.reloadTable = false);
				this.$nextTick(() => (this.reloadTable = true));
			},
			closeCompleted_outside() {
				this.dialog = false;
				this.dialog2 = false;
				this.$store.commit("setDialogInfo", false);
				this.$store.commit("set_dialogCalendarHide", false);
				this.viewOutputLength = false;
				this.e1 = 1;
				this.checkHide = false;
				(this.addcartItems = []),
					(this.dates = []),
					(this.projects = ""),
					this.$refs.form.reset(),
					this.$store.commit("deleteData", []),
					(this.reloadTable = false);
				this.$nextTick(() => (this.reloadTable = true));
			},

			dialog_outside: function () {
				(this.resultmonthCal = []),
					this.$store.commit("set_dialogCalendar", false);
				this.$store.commit("set_dialogCalendarHide", false);
				this.viewOutputLength = false;
			},
			dialogCalClick(item) {
				this.$store.commit("set_dialogCalendar", true); // this.dialog = true;
				this.$store.commit("set_dialogCalendarHide", true);
				this.CalendarInfo = item.model;

				const resultmonth = [];
				const resultmonthCopy = [];

				const resultday = this.datemoment.split("-");
				const startresult = resultday[0] + "-" + resultday[1] + "-01";
				const endresult = moment(
					resultday[0] + "-" + resultday[1],
					"YYYY-MM"
				).daysInMonth();

				var begin_month = moment(startresult).format("YYYY-MM-DD");
				var end_month = moment(
					resultday[0] + "-" + resultday[1] + "-" + endresult
				).format("YYYY-MM-DD");

				this.startresult = begin_month;
				this.endresult = end_month;

				var model = item.model;
				var stock = item.stock;

				this.dialogModel = model;
				this.dialogStock = stock;

				for (
					var d = new Date(this.startresult);
					d <= new Date(this.endresult);
					d.setDate(d.getDate() + 1)
				) {
					let item = {
						model: model,
						start: moment(d).format("YYYY-MM-DD"),
						end: moment(d).format("YYYY-MM-DD"),
						name: stock + "/" + stock,
						// name: stock,
						status: false,
						color: "#BDBDBD",
					};
					let itemCopy = {
						model: model,
						start: moment(d).format("YYYY-MM-DD"),
						end: moment(d).format("YYYY-MM-DD"),
						name: stock,
						// namecart: stock,
						status: false,
						color: "#BDBDBD",
					};
					resultmonth.push(item);
					resultmonthCopy.push(itemCopy);
				}
				this.resultmonthCopy = resultmonthCopy;
				this.resultmonth = resultmonth;

				// console.log(this.resultmonthCopy);

				var resultmonthCal = _.merge(
					_.keyBy(this.resultmonthCopy, "start"),
					_.keyBy(this.saveCalValues, "start")
				);

				this.resultmonthCal = _.values(resultmonthCal);

				// console.log(this.resultmonthCal);
				// console.log(this.resultmonth);
			},
			submitDB() {
				var db = firebase.firestore();
				var length = this.addcartItems.length;

				// カレンダー用データベース
				const id = db.collection("_").doc().id;

				const cartItems = this.addcartItems;
				// cartItems.report = "";

				for (let i = 0; i < length; i++) {
					cartItems[i].returnstatus = false;
					cartItems[i].report_check = "";
				}

				for (let i = 0; i < length; i++) {
					db.collection("calendar")
						.doc()
						.set({
							id: id,
							model: cartItems[i].model,
							start: this.dateValidation[0],
							end: this.dateValidation[1],
							name: Number(cartItems[i].quantity),
							user: this.user.displayName,
							status: false,
						});
					this.dialog2 = true;
				}

				this.loadingValidate = true;
				db.collection("rentals")
					.doc(id)
					.set({
						id: id,
						projects: this.projects,
						user: this.user.displayName,
						checkuser: null,
						Items: firebase.firestore.FieldValue.arrayUnion(...cartItems),
						status: false,
						checkout_date: this.dateValidation[0],
						return_date: this.dateValidation[1],
					})
					.then(() => {
						return (
							(this.loadingValidate = false),
							// (this.checkHide = true),
							// this.$store.commit("setDialogInfo", false),
							this.$store.commit("set_Notification_Checkout", true)
						);
					})
					.catch(() => {
						return (
							(this.loadingValidate = false),
							this.$store.commit("set_Notification_Checkout_Err", true)
						);
					});

				// this.e1 = 1;
			},

			prev() {
				this.$refs.calendar.prev();

				const resultmonth = [];

				const resultday = this.datemoment.split("-");
				const startresult = resultday[0] + "-" + resultday[1] + "-01";
				const endresult = moment(
					resultday[0] + "-" + resultday[1],
					"YYYY-MM"
				).daysInMonth();

				var begin_month = moment(startresult).format("YYYY-MM-DD");
				var end_month = moment(
					resultday[0] + "-" + resultday[1] + "-" + endresult
				).format("YYYY-MM-DD");

				this.startresult = begin_month;
				this.endresult = end_month;

				var model = this.dialogModel;
				var stock = this.dialogStock;

				// console.log(this.dialogModel, this.dialogStock);

				for (
					var d = new Date(this.startresult);
					d <= new Date(this.endresult);
					d.setDate(d.getDate() + 1)
				) {
					let item = {
						model: model,
						start: moment(d).format("YYYY-MM-DD"),
						end: moment(d).format("YYYY-MM-DD"),
						name: stock + "/" + stock,
						status: false,
						color: "#BDBDBD",
					};
					resultmonth.push(item);
				}
				this.resultmonth = resultmonth;

				// console.log(this.resultmonth);

				var resultmonthCal = _.merge(
					_.keyBy(this.resultmonth, "start"),
					_.keyBy(this.saveCalValues, "start")
				);

				this.resultmonthCal = _.values(resultmonthCal);

				// console.log(this.resultmonthCal);
			},
			next() {
				this.$refs.calendar.next();

				const resultmonth = [];

				const resultday = this.datemoment.split("-");
				const startresult = resultday[0] + "-" + resultday[1] + "-01";
				const endresult = moment(
					resultday[0] + "-" + resultday[1],
					"YYYY-MM"
				).daysInMonth();

				var begin_month = moment(startresult).format("YYYY-MM-DD");
				var end_month = moment(
					resultday[0] + "-" + resultday[1] + "-" + endresult
				).format("YYYY-MM-DD");

				this.startresult = begin_month;
				this.endresult = end_month;

				// console.log(this.startresult, this.endresult);

				var model = this.dialogModel;
				var stock = this.dialogStock;

				for (
					var d = new Date(this.startresult);
					d <= new Date(this.endresult);
					d.setDate(d.getDate() + 1)
				) {
					let item = {
						model: model,
						start: moment(d).format("YYYY-MM-DD"),
						end: moment(d).format("YYYY-MM-DD"),
						name: stock + "/" + stock,
						status: false,
						color: "#BDBDBD",
					};
					resultmonth.push(item);
				}
				this.resultmonth = resultmonth;

				// console.log(this.resultmonth);

				var resultmonthCal = _.merge(
					_.keyBy(this.resultmonth, "start"),
					_.keyBy(this.saveCalValues, "start")
				);

				this.resultmonthCal = _.values(resultmonthCal);

				// console.log(this.resultmonthCal);
			},
			setToday() {
				this.value = moment().format("yyyy-MM-DD");
				// console.log(this.value);
			},
			//Print
			print() {
				this.checkHide = true;
				this.$nextTick(function () {
					this.$print(this.$refs.OutputPrint);
				});
			},
		},
		updated: function () {
			setTimeout(() => {
				this.$store.commit("set_Notification_Checkout", false);
				this.$store.commit("set_Notification_Checkout_Err", false);
			}, 10000);

			const addcartItemsMap = _.map(this.addcartItems, (el) => {
				return {
					model: _.get(el, "model"),
					start: this.dateValidation[0],
					end: this.dateValidation[1],
					name: Number(_.get(el, "quantity")),
					mainStock: Number(_.get(el, "stock")),
				};
			});

			// console.log(addcartItemsMap);

			setTimeout(() => {
				let item = [];
				let itemsMap = [];
				for (let i = 0; i < addcartItemsMap.length; i++) {
					// 機種名
					var model = addcartItemsMap[i].model;

					// 在庫
					var stock = addcartItemsMap[i].mainStock;

					// 既に借りられている個数
					// var nameuse = this.saveDBItemsFlatOutput[i].name;

					// 借りる予定個数
					var namecart = addcartItemsMap[i].name;

					// 日付
					var startDate = addcartItemsMap[i].start;
					var endDate = addcartItemsMap[i].end;

					// console.log(mainStock);

					var splitstartDate = startDate.split("-");
					var splitendDate = endDate.split("-");

					var convStartDate = new Date(
						splitstartDate[0],
						splitstartDate[1] - 1,
						splitstartDate[2]
					);
					var convEndDate = new Date(
						splitendDate[0],
						splitendDate[1] - 1,
						splitendDate[2]
					);

					// const resultday = this.datemoment.split("-");
					// const startresult = resultday[0] + "-" + resultday[1] + "-01";
					// const endresult = resultday[0] + "-" + resultday[1] + "-";

					// var begin = moment(startresult).format("YYYY-MM-01");
					// var end =
					// 	moment(endresult).format("YYYY-MM-") + moment().daysInMonth();

					// this.startresult = begin;
					// this.endresult = end;

					// var beginSplit = this.startresult.split("-");
					// var endSplit = this.endresult.split("-");

					// var beginDay = beginSplit[2].replace("0", "");
					// var endDay = endSplit[2];

					// var convStartDateOrigin = new Date(
					// 	beginSplit[0],
					// 	beginSplit[1] - 1,
					// 	beginSplit[2]
					// );
					// var convEndDateOrigin = new Date(
					// 	endSplit[0],
					// 	endSplit[1] - 1,
					// 	endSplit[2]
					// );

					for (
						var d = convStartDate;
						d <= convEndDate;
						d.setDate(d.getDate() + 1)
					) {
						item.push({
							model: model,
							stock: stock,
							// nameuse: nameuse,
							namecart: namecart,
							start: moment(d).format("YYYY-MM-DD"),
							end: moment(d).format("YYYY-MM-DD"),
						});

						itemsMap = _.map(item, (el) => {
							return {
								id: _.get(el, "model") + "#" + _.get(el, "start"),
								// name: -el.namesource,
								model: _.get(el, "model"),
								stock: _.get(el, "stock"),
								// nameuse: _.get(el, "nameuse"),
								namecart: _.get(el, "namecart"),
								// result: _.get(el, "stock"),
								start: _.get(el, "start"),
								type: "local",
							};
						});
					}

					// console.log(item);

					// const itemsMap = _.map(item, (el) => {
					// 	return {
					// 		id: _.get(el, "model") + "#" + _.get(el, "start"),
					// 		// name: -el.namesource,
					// 		model: _.get(el, "model"),
					// 		stock: _.get(el, "stock"),
					// 		// nameuse: _.get(el, "nameuse"),
					// 		namecart: _.get(el, "namecart"),
					// 		// result: _.get(el, "stock"),
					// 		start: _.get(el, "start"),
					// 		type: "local",
					// 	};
					// });

					// this.saveCalValuesMap = _.map(itemsMap, (el) => {
					// 	return {
					// 		// id: _.get(el, "model") + "#" + _.get(el, "start"),
					// 		// name: -el.namesource,
					// 		// model: _.get(el, "model"),
					// 		// stock: _.get(el, "stock"),
					// 		// nameuse: _.get(el, "nameuse"),
					// 		// namecart: _.get(el, "namecart"),
					// 		// result: _.get(el, "stock"),
					// 		name: _.get(el, "namecart") - _.get(el, "stock"),
					// 		start: _.get(el, "start"),
					// 		type: "local",
					// 	};
					// });

					// console.log(itemsMap);
					// var serverDataOutput = _.filter(this.serverData, {
					// 	model: addcartItemsMap[i].model,
					// });

					var merged = _.concat(
						JSON.parse(JSON.stringify(itemsMap)),
						JSON.parse(JSON.stringify(this.serverData))
					);

					console.log(merged);
					// console.log(this.serverData);

					var outputkey = _(merged)
						.groupBy("id")
						.map((objs, key) => ({
							id: key,
							model: objs[0].model,
							stock: objs[0].stock,
							namecart: _.sumBy(objs, "namecart"),
							result: objs[0].stock - _.sumBy(objs, "namecart"),
							start: objs[0].start,
							type: objs[0].type,
						}))
						.value();

					var outputkey2 = _(merged)
						.uniqBy("id")
						.filter({ type: "server" })
						.value();

					var outputkey3 = _(outputkey2)
						.groupBy("id")
						.map((objs, key) => ({
							id: key,
							model: objs[0].model,
							stock: objs[0].stock,
							namecart: _.sumBy(objs, "namecart"),
							result: objs[0].stock - _.sumBy(objs, "namecart"),
							start: objs[0].start,
							type: objs[0].type,
						}))
						.value();

					// console.log(outputkey);
					// console.log(outputkey2);
					console.log(outputkey3);

					// var groups = _.groupBy(merged, (value) => {
					// 	return value.model + "#" + value.start;
					// });

					// var output = _.map(groups, (group) => {
					// 	return {
					// 		model: group[0].model,
					// 		stock: group[0].stock,
					// 		// nameuse: group[0].nameuse,
					// 		namecart: group[0].namecart,
					// 		start: group[0].start,
					// 		type: group[0].type,
					// 	};
					// });

					// console.log(output);

					// var output2 = _.filter(output, { type: "local" });

					// var output3 = _.map(output2, (el) => {
					// 	return {
					// 		model: _.get(el, "model"),
					// 		start: _.get(el, "start"),
					// 		result: _.get(el, "stock") - _.get(el, "nameuse"),
					// 		type: _.get(el, "type"),
					// 	};
					// });

					// console.log(output3);
					// console.log(this.saveDBItemsFlatOutput);

					var outputMinus = _.filter(outputkey, (el) => el.result < "0");
					this.viewOutput = outputMinus;

					var stockModelArr = _.merge(_.keyBy(outputMinus, "model"));
					this.viewOutputInfo = stockModelArr;

					var stockInfoArr = _.filter(outputMinus, {
						model: this.CalendarInfo,
					});

					var stockInfoArr2 = _.filter(outputkey2, {
						model: this.CalendarInfo,
					});

					// console.log(stockInfoArr);

					var stockInfoArrOut = _.map(stockInfoArr, (el) => {
						return {
							start: _.get(el, "start"),
							name: Math.abs(_.get(el, "result")),
							namecart: _.get(el, "namecart"),
							test: Math.abs(_.get(el, "result")) - _.get(el, "namecart"),
							color: "#E57373",
						};
					});

					var stockInfoArrOut2 = _.map(stockInfoArr2, (el) => {
						return {
							start: _.get(el, "start"),
							name:
								Math.abs(_.get(el, "stock")) -
								_.get(el, "namecart") +
								"/" +
								Math.abs(_.get(el, "stock")),
							namecart: _.get(el, "namecart"),
							test: Math.abs(_.get(el, "result")) - _.get(el, "namecart"),
							color: "#AED581",
							model: this.CalendarInfo,
						};
					});

					this.saveCalValues = stockInfoArrOut;
					// this.saveCalValues2 = stockInfoArrOut2;

					// console.log(this.saveCalValues);

					var resultmonthCal = _.merge(
						_.keyBy(this.resultmonth, "start"),
						_.keyBy(this.saveCalValues, "start")
					);

					var resultmonthCal2 = _.merge(
						_.keyBy(this.resultmonthCal, "start"),
						_.keyBy(stockInfoArrOut2, "start")
					);

					// console.log(stockInfoArrOut2);
					console.log(resultmonthCal2);
					// console.log(this.CalendarInfo);

					this.resultmonthCal = _.values(resultmonthCal);

					console.log(resultmonthCal);
					// console.log(this.saveCalValues);

					if (outputMinus.length > 0) {
						this.viewOutputLength = true;
					} else if (outputMinus.length <= 0) {
						this.viewOutputLength = false;
					}
				}

				// var beginOrigin = this.startresult;
				// var endOrigin = this.endresult;

				// var beginSplit = this.startresult.split("-");
				// var endSplit = this.endresult.split("-");

				// var beginDay = beginSplit[2].replace("0", "");
				// var endDay = endSplit[2];

				// var convStartDateOrigin = new Date(
				// 	beginSplit[0],
				// 	beginSplit[1] - 1,
				// 	beginSplit[2]
				// );
				// var convEndDateOrigin = new Date(
				// 	endSplit[0],
				// 	endSplit[1] - 1,
				// 	endSplit[2]
				// );

				// console.log(beginDay, endDay);

				// let monthFullLength = [];

				// for (
				// 	var dd = convStartDateOrigin;
				// 	dd <= convEndDateOrigin;
				// 	dd.setDate(dd.getDate() + 1)
				// ) {
				// 	var monthFullitems = {
				// 		model: model,
				// 		name: stock - namecart,
				// 		namecart: namecart,
				// 		start: moment(dd).format("YYYY-MM-DD"),
				// 		end: moment(dd).format("YYYY-MM-DD"),
				// 	};
				// 	monthFullLength.push(monthFullitems);
				// }
				// console.log(monthFullLength);
				// this.monthFullLength = monthFullLength;
			}, 50);
		},
		mounted() {
			this.items = this.addcartItems;

			this.valueCache = this.value;

			this.dates = [];

			// 借りる数 - 残数
			var db = firebase.firestore();
			var length = this.addcartItems.length;

			const savepushData = [];

			let item = [];

			for (let i = 0; i < length; i++) {
				db.collection("equipments")
					.where("model", "==", this.addcartItems[i].model)
					// .get()
					.onSnapshot((querysnapshot) => {
						querysnapshot.forEach((doc) => {
							this.dbAllitems.push(doc.data());
						});
					});
			}

			// console.log(this.dbAllitems);

			for (let i = 0; i < length; i++) {
				db.collection("calendar")
					.where("model", "==", this.addcartItems[i].model)
					.where("status", "==", false)
					// .get()
					.onSnapshot((querySnapshot) => {
						querySnapshot.forEach(() => {});
						savepushData.push(querySnapshot.docs.map((doc) => doc.data()));
						this.saveDBItems = savepushData;
						this.saveDBItemsFlat = _.flattenDeep(this.saveDBItems);

						// const totalSave = [];

						// var totalby = _.keyBy(this.saveDBItemsFlat, "model");
						// console.log(totalby);
						// var totalbyFlat = _.values(totalby);
						// console.log(totalbyFlat);

						// var total = _.sumBy(totalbyFlat, "name");
						// console.log(total);

						// console.log(this.saveDBItemsFlat);

						var outputkey = _(this.saveDBItemsFlat)
							.groupBy("model")
							.map((objs, key) => ({
								model: key,
								name: _.sumBy(objs, "name"),
								start: _.get(objs, "start"),
							}))
							.value();

						const groups = _.groupBy(this.saveDBItemsFlat, (value) => {
							return value.model + "#" + value.start;
						});

						const result = _.map(groups, (group) => {
							return {
								id: group[0].model,
								start: group[0].start,
								end: group[0].end,
								name: _.sumBy(group, "name"),
							};
						});

						// console.log(result);

						// console.log(outputkey);

						this.saveDBItemsFlatOutput = outputkey;

						console.log(this.saveDBItemsFlatOutput);

						setTimeout(() => {
							const resultday = this.datemoment.split("-");
							const startresult = resultday[0] + "-" + resultday[1] + "-01";
							const endresult =
								resultday[0] +
								"-" +
								resultday[1] +
								"-" +
								moment().daysInMonth();

							var begin = moment(startresult).format("YYYY-MM-DD");
							var end = moment(endresult).format("YYYY-MM-DD");

							this.startresult = begin;
							this.endresult = end;

							var beginSplit = this.startresult.split("-");
							var endSplit = this.endresult.split("-");

							// var beginDay = beginSplit[2].replace("0", "");
							// var endDay = endSplit[2];

							var convStartDateOrigin = new Date(
								beginSplit[0],
								beginSplit[1] - 1,
								beginSplit[2]
							);
							var convEndDateOrigin = new Date(
								endSplit[0],
								endSplit[1] - 1,
								endSplit[2]
							);

							// console.log(beginDay, endDay);

							let monthFullLength = [];

							for (
								var dd = convStartDateOrigin;
								dd <= convEndDateOrigin;
								dd.setDate(dd.getDate() + 1)
							) {
								// var uid_origin = result[0].id;
								// var name_origin = result[0].name;

								var monthFullitems = {
									// uid: uid_origin,
									// name: name_origin,
									model: this.addcartItems[i].model,
									// name: stock - namecart,
									// namecart: namecart,
									start: moment(dd).format("YYYY-MM-DD"),
									end: moment(dd).format("YYYY-MM-DD"),
								};
								monthFullLength.push(monthFullitems);
							}
							// console.log(monthFullLength);
							this.monthFullLength = monthFullLength;

							var stock = this.dbAllitems[i].stock;
							// console.log(stock);

							for (let i = 0; i < result.length; i++) {
								var uid = result[i].id;
								var name = result[i].name;
								// var stock = this.addcartItems[i].stock;

								// Use 計算
								// カレンダー取得後に同一アイテムでsumbyした配列を取り出す

								// var use =
								// 	this.dbAllitems[i].stock - this.saveDBItemsFlat[i].name;
								var startDate = new Date(result[i].start);
								var endDate = new Date(result[i].end);

								this.DateArr.push({
									id: uid,
									name: name,
									stock: name,
									// use: use,
									start: startDate,
									end: endDate,
								});

								for (
									var d = startDate;
									d <= endDate;
									d.setDate(d.getDate() + 1)
								) {
									item.push({
										id: uid,
										name: name,
										stock: stock,
										// use: use,
										start: moment(d).format("YYYY-MM-DD"),
										end: moment(d).format("YYYY-MM-DD"),
									});
								}
							}

							// console.log(item);

							var groups = _.groupBy(item, (value) => {
								return value.id + "#" + value.start;
							});

							// console.log(groups);

							var output = _.map(groups, (group) => {
								return {
									id: group[0].id + "#" + group[0].start,
									model: group[0].id,
									namecart: group[0].name,
									start: group[0].start,
									stock: Number(group[0].stock),
									// use: group[0].use,
									// namecart: Number(group[0].stock) - _.sumBy(group, "name"),
									type: "server",
								};
							});

							// this.serverData = _.filter(output, {
							// 	model: this.CalendarInfo,
							// });
							this.serverData = output;
							console.log(JSON.parse(JSON.stringify(this.serverData)));
						}, 100);
					});
			}
		},
		created() {
			var db = firebase.firestore();
			db.collection("calendar")
				.get()
				.then((querysnapshot) => {
					querysnapshot.forEach((doc) => {
						this.dbitems.push(doc.data());
					});
				})
				.catch((error) => {
					console.log(error);
					// テーブルリセット
					this.dbitems = [];
				});
		},
		beforeDestroy() {
			localStorage.removeItem("cart");
		},
		data: () => ({
			dialog: false,
			dialog2: false,

			panel: [0, 1],

			headers: [
				{
					text: "Category",
					value: "category",
					align: "start",
					sortable: false,
				},
				{
					text: "Manufacturer",
					value: "manufacturer",
				},
				{
					text: "Model",
					value: "model",
				},
				{
					text: "Location",
					value: "location_repo",
				},
				{
					text: "Box",
					value: "location_box",
				},
				{
					text: "Cable length",
					value: "size",
				},
				{
					text: "Quantity",
					value: "quantity",
				},
			],
			items: null,
			//v-date-pickerの範囲データ
			dates: ["", ""],

			//Firestoreに送信するデータ
			checkout_date: "",
			return_date: "",
			// rentuser: "",
			quantity: "",
			//プロジェクト名
			projects: "",

			loadingValidate: false,

			snackbar: false,
			text: "登録に失敗しました",

			required: (value) => !!value || "必ず入力してください", // 入力必須の制約

			vForm: true,
			vForm_el1: true,
			vForm_el2: true,
			vForm_el3: true,
			vForm_el4: true,

			saveCalItems: [],
			saveDateItems: [],
			saveDBItems: [],

			availableDates: [],
			saveDBItemsFlat: [],
			saveDBItemsFlatOutput: [],
			saveDateItemsOut: [],

			DateArr: [],
			DateArrS: [],
			// endDateArr: []

			serverData: [],
			localData: [],

			itemDates: [],

			MappingCalendar: [],

			e1: 1,

			errDialog: false,

			viewOutput: [],
			viewOutputProps: [],
			viewOutputLength: false,
			viewOutputInfo: [],
			CalenderInfo: [],

			saveCalValues: [],
			saveCalValuesMap: [],
			saveCalValuesC: false,
			value: moment().format("yyyy-MM-DD"),
			dateformat: moment().format("yyyy-MM-DD"),
			valueCache: "",

			dbitems: [],
			dbAllitems: [],

			reloadTable: true,

			startresult: [],
			endresult: [],
			resultmonth: [],
			resultmonthCopy: [],
			resultmonthCal: [],

			monthFullLength: [],

			dialogModel: "",
			dialogStock: "",

			color: "",
			checkHide: false,
		}),
	};
</script>
<style scoped>
	.v-date-picker-table .v-btn.v-btn--active {
		color: #000000;
	}

	.v-date-picker-title {
		display: none;
	}

	.titleColor {
		background-color: #4c4c4c;
		color: #fff;
	}

	@media print {
		.OutputPrintStyle .v-list-item__title {
			font-size: 0.5rem;
		}

		.OutputPrintStyle .v-expansion-panel-header {
			font-size: 0.5rem;
		}

		.OutputPrintStyle
			.v-data-table
			> .v-data-table__wrapper
			> table
			> tbody
			> tr
			> td {
			font-size: 0.5rem !important;
		}

		.OutputPrintStyle
			.v-data-table
			> .v-data-table__wrapper
			> table
			> thead
			> tr
			> th {
			font-size: 0.2rem !important;
		}

		.theme--dark.v-btn.v-btn--outlined.v-btn--text {
			border-color: rgba(0, 0, 0, 0.12);
		}
	}
</style>
