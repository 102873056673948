import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";
// import VueMoment from 'vue-moment'
import VuePrinter from "vue-dom-printer";
import VTooltip from "v-tooltip";
import VCalendar from "v-calendar";
import SlideUpDown from "vue-slide-up-down";
import Dummy from "dummyjs";
import "@/thirdpartyjs/typekit.js";
import "./assets/sass/style.scss";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueMobileDetection from "vue-mobile-detection";

axios.defaults.baseURL =
	"https://firestore.googleapis.com/v1/projects/eqpmanager-d9cb5/databases/(default)/documents/equipments";

import Firebase from "./firebase";
Firebase.init();

Vue.use(Vuex);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VuePrinter);
Vue.use(VTooltip);
Vue.use(VCalendar);
Vue.use(Dummy);
Vue.use(VueMobileDetection);
Vue.component("slide-up-down", SlideUpDown);
Vue.component(VueQrcode.name, VueQrcode);
// Vue.component('ic-firebase-uploader', IcFirebaseUploader);

new Vue({
	router,
	vuetify,
	store,
	render: (h) => h(App),
}).$mount("#app");
