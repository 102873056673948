<template>
	<v-app>
		<v-container fluid fill-height class="ma-0 pa-0">
			<v-layout row wrap align-center class="ma-0 pa-0">
				<v-flex>
					<v-main>
						<router-view />
					</v-main>
				</v-flex>
			</v-layout>
		</v-container>
	</v-app>
</template>

<script>
	// import Nav from "./components/Nav.vue";
	// import Footer from "./components/Footer.vue";

	export default {
		name: "App",
		components: {
			// Nav,
			// Footer,
		},
		data() {
			return {};
		},
		methods: {},
		computed: {},
		created() {},
	};
</script>
<style></style>
