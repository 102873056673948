<template>
	<div id="nav">
		<v-app-bar color="white" elevation="0">
			<v-container class="d-flex align-center">
				<div v-if="isNavActive">
					<v-app-bar-nav-icon
						@click.stop="drawer = !drawer"
						v-if="$vuetify.breakpoint.mdAndDown"
						w
					></v-app-bar-nav-icon>
				</div>
				<router-link to="/menu">
					<img
						class="mt-2"
						:src="require('../assets/f3_logo.png')"
						height="40"
					/>
				</router-link>
				<v-menu
					bottom
					offset-y
					open-on-hover
					:close-on-content-click="false"
					:close-on-click="false"
					content-class="elevation-2"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-toolbar-title v-bind="attrs" v-on="on" class="mt-1"
							>F3 EQUIPMENTS</v-toolbar-title
						>
					</template>
					<v-list class="navlistMenu">
						<v-list-item
							v-for="(item, index) in navMenu"
							:key="index"
							:to="item.link"
						>
							<v-list-item-title>{{ item.name }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-spacer></v-spacer>
				<div v-if="userStatus" key="login" class="navbar-item">
					<v-toolbar-title
						><v-btn
							class="BtnNav ma-0"
							@click="doLogout"
							text
							color="error"
							outlined
						>
							LOGOUT
						</v-btn></v-toolbar-title
					>
				</div>
				<div v-else key="logout">
					<v-toolbar-title
						><v-btn
							class="BtnNav ma-0"
							@click="doLogin"
							text
							color="primary"
							outlined
						>
							LOGIN
						</v-btn></v-toolbar-title
					>
				</div>
			</v-container>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" absolute temporary>
			<v-list nav dense>
				<v-list-group
					v-for="(nav_list, index) in listInCategory"
					:key="nav_list.name"
					:prepend-icon="nav_list.icon"
					no-action
					:append-icon="nav_list.lists ? undefined : ''"
					@click="clickNavMenu(index)"
					color="#262626"
				>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title>{{ nav_list.name }}</v-list-item-title>
						</v-list-item-content>
					</template>
					<v-list-item
						v-for="list in nav_list.lists"
						:key="list.name"
						:to="list.link"
						@click="clickNavMenuContent(index, list)"
					>
						<v-list-item-content>
							<v-list-item-title>{{ list.name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
	import Firebase from "./../firebase";
	export default {
		name: "Nav",
		created: function() {
			Firebase.onAuth();
		},
		computed: {
			user() {
				return this.$store.getters.user;
			},
			userStatus() {
				// ログインするとtrue
				return this.$store.getters.isSignedIn;
			},
			tabMenu() {
				return this.$store.getters.gettabMenu;
			},
			isNavActive() {
				return this.$route.name === "Rent";
			},

			// userAdmin() {
			// 	return this.$store.getters.user == this.userAddress;
			// },
		},
		methods: {
			// ログイン処理
			doLogin() {
				Firebase.login();
			},
			// ログアウト処理
			doLogout() {
				Firebase.logout();
				this.$router.push({ path: "/" });
			},
			// ナビゲーション処理
			clickNavMenu(index) {
				console.log(index);
				this.$store.commit("settabMenu", index);
			},
			clickNavMenuContent(index, list) {
				// console.log(index, list.name, list.id);
				this.$store.commit("settabMenuContent", list.id);
				this.$store.commit("settabMenuContentName", list.name);
			},
		},
		watch: {
			group() {
				this.drawer = false;
			},
		},
		data: () => ({
			dialog: false,
			notifications: false,
			sound: true,
			widgets: false,

			tooltipShow: true,

			drawer: false,
			group: null,

			navMenu: [
				{ name: "RESERVE", link: "/rent" },
				{ name: "RETURN", link: "/return" },
				// { name: "PICKUP", link: "/pickup" },
			],

			listInCategory: [
				{
					name: "All",
				},

				{
					name: "Shoot",
					lists: [
						{ id: 1, name: "Camera", click: "camerafSw_SH" },
						{ id: 2, name: "Lens", click: "lensfSw_SH" },
						{ id: 3, name: "Recorder/Monitor", click: "recorderfSw_SH" },
						{ id: 4, name: "Media", click: "mediafSw_SH" },
						{ id: 5, name: "Tripod/Gimbal/Jib", click: "tripodfSw_SH" },
						{ id: 6, name: "Light", click: "lightfSw_SH" },
					],
				},
				{
					name: "VJ",
					// lists: [{ id: 1, name: "Switcher", click: "switcherfSw_VJ" }],
				},
				{
					name: "Cable",
					lists: [
						{ id: 1, name: "Video", click: "videofSw_CA" },
						{ id: 2, name: "Audio", click: "audiofSw_CA" },
						{ id: 3, name: "LAN", click: "lanfSw_CA" },
						{ id: 4, name: "Power", click: "powerfSw_CA" },
						{ id: 5, name: "Other", click: "otherfSw_CA" },
					],
				},
				{
					name: "Labo",
					// lists: [{ id: 1, name: "Labo", click: "labofSw_LA" }],
				},
			],
		}),
	};
</script>
<style>
	.BtnNav {
		border-radius: 0px;
	}
	.v-tooltip__content {
		background: transparent;
	}
	.navlistMenu {
		display: inline-flex;
	}
</style>
<style scoped>
	::v-deep .v-toolbar__content,
	.v-toolbar__extension {
		padding: 0;
	}

	::v-deep .v-toolbar__content .v-btn.v-btn--icon.v-size--default,
	.v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
		width: 24px;
		height: 24px;
	}
</style>
