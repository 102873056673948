import firebase from "@firebase/app";
import "@firebase/auth";
import "firebase/database";
import store from "./store";

const config = {
	apiKey: "AIzaSyBdl-GfcgzVBgHqWEFfX4ZydAiXVYyI7HA",
	authDomain: "eqpmanager-d9cb5.firebaseapp.com",
	databaseURL: "https://eqpmanager-d9cb5-default-rtdb.firebaseio.com",
	projectId: "eqpmanager-d9cb5",
	storageBucket: "eqpmanager-d9cb5.appspot.com",
	messagingSenderId: "894047992651",
	appId: "1:894047992651:web:b6e5225799a6d98eec06ce",
	measurementId: "G-2TFK2FX0NT",
};

export default {
	init() {
		firebase.initializeApp(config);
		firebase.firestore().settings({ ignoreUndefinedProperties: true });
		firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
	},
	login() {
		const provider = new firebase.auth.GoogleAuthProvider();
		provider.setCustomParameters({
			hd: "flapper3.co.jp",
		});
		firebase.auth().signInWithPopup(provider);
		// this.$router.push("Loading");
	},
	logout() {
		firebase.auth().signOut();
	},
	onAuth() {
		firebase.auth().onAuthStateChanged((user) => {
			user = user ? user : {};
			store.commit("onAuthStateChanged", user);
			store.commit("onUserStatusChanged", user.uid ? true : false);
		});
	},
};
