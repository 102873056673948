<template>
	<v-container fill-height>
		<v-row align-content="center">
			<v-col align="center">
				<v-img
					src="./../assets/f3_logo_wh_w.png"
					width="150"
					class="mb-5"
				></v-img>
				<div class="text-h4 white--text mx-auto mb-5">F3 EQUIPMENTS</div>

				<template v-if="userStatus" class="pa-0 ma-0">
					<v-btn
						@click="doLogout"
						outlined
						color="white"
						style="text-transform: none"
						class="px-15 rounded-0"
						>Logout</v-btn
					>
				</template>
				<template v-else class="pa-0 ma-0">
					<v-btn
						@click="doLogin"
						outlined
						color="white"
						style="text-transform: none"
						class="px-15 rounded-0"
						>Member Login</v-btn
					>
				</template>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import firebase from "./../firebase";
	import "firebase/auth";
	export default {
		name: "HelloWorld",

		data: () => ({}),
		created: function () {
			firebase.onAuth();
		},
		computed: {
			user() {
				return this.$store.getters.user;
			},
			userStatus() {
				// ログインするとtrue
				return this.$store.getters.isSignedIn;
			},
		},
		methods: {
			// ログイン処理
			doLogin() {
				// console.log(this.$route.query.redirect);
				firebase.login();

				var routeStr = this.$route.query.redirect;
				this.$store.commit("set_routerCache", routeStr);

				this.$router.push("Loading");

				// this.$router.push({
				// 			path: "/loading",
				// 			query: {  },
				// 			hash: "#auto",
				// 		});
			},
			// ログアウト処理
			doLogout() {
				firebase.logout();
			},
		},
	};
</script>
<style scoped>
	html {
		overflow-y: hidden;
	}
</style>
