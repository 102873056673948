<template>
	<div>
		<v-container class="pr-0 pl-0">
			<v-snackbar v-model="Update_Item_Notification" :timeout="timeout" top>
				{{ Update_Item_Notification_Text }}
				<template v-slot:action="{ attrs }">
					<v-btn
						color="pink"
						text
						v-bind="attrs"
						@click="Update_Item_Notification = false"
					>
						Close
					</v-btn>
				</template>
			</v-snackbar>
			<v-snackbar v-model="Update_Item_Notification_err" :timeout="timeout" top>
				{{ Update_Item_Notification_Text_err }}
				<template v-slot:action="{ attrs }">
					<v-btn
						color="pink"
						text
						v-bind="attrs"
						@click="Update_Item_Notification_err = false"
					>
						Close
					</v-btn>
				</template>
			</v-snackbar>
			<v-snackbar v-model="Delete_Item_Notification" :timeout="timeout" top>
				{{ Delete_Item_Notification_Text }}
				<template v-slot:action="{ attrs }">
					<v-btn
						color="pink"
						text
						v-bind="attrs"
						@click="Delete_Item_Notification = false"
					>
						Close
					</v-btn>
				</template>
			</v-snackbar>
			<v-snackbar v-model="Delete_Item_Notification_err" :timeout="timeout" top>
				{{ Delete_Item_Notification_Text_err }}
				<template v-slot:action="{ attrs }">
					<v-btn
						color="pink"
						text
						v-bind="attrs"
						@click="Delete_Item_Notification_err = false"
					>
						Close
					</v-btn>
				</template>
			</v-snackbar>
			<v-card flat elevation="0" width="100vw">
				<!-- <template v-slot:top> -->
				<!-- v-container, v-col and v-row are just for decoration purposes. -->
				<!-- <v-container fluid> -->
				<v-row v-if="!$vuetify.breakpoint.mdAndUp">
					<v-col cols="8" md="6" lg="6" xl="6">
						<v-row class="pa-6">
							<!-- Filter for dessert name-->
							<v-text-field
								v-model="search"
								label="Search"
								single-line
								hide-details
							></v-text-field>
						</v-row>
					</v-col>

					<v-col cols="4" md="6" lg="6" xl="6">
						<v-row class="pa-6">
							<!-- Filter for calories -->
							<v-select
								:items="categoryfilterNull"
								v-model="caloriesFilterValue"
								label="Category"
								item-text="text"
								item-value="value"
								@change="categoryChangeLabel($event)"
							></v-select>
						</v-row>
					</v-col>
				</v-row>
				<!-- </v-container> -->
				<!-- </template> -->
				<v-list-item-group color="white" v-if="!$vuetify.breakpoint.mdAndUp">
					<v-list-item
						v-for="(item, i) in dbItemsListSearch"
						:key="i"
						inactive
						:ripple="false"
					>
						<v-row>
							<v-list-item-content class="mobileListContent">
								<v-divider></v-divider>
								<v-col cols="6" md="3">
									<div v-if="item.manufacturer" class="mobileList">
										<v-list-item-subtitle class="text-subtitle-2"
											>Manufacturer</v-list-item-subtitle
										>
										<v-list-item-subtitle
											class="text-subtitle-1"
											v-text="item.manufacturer"
										></v-list-item-subtitle>
									</div>
									<div class="py-1"></div>
									<div class="mobileList">
										<v-list-item-subtitle class="text-subtitle-2"
											>Model</v-list-item-subtitle
										>
										<v-list-item-subtitle
											class="text-subtitle-1"
											v-text="item.model"
										></v-list-item-subtitle
										><span v-if="item.size">{{ item.size }}m</span>
									</div>
								</v-col>
								<v-col cols="6" md="3">
									<div class="mobileList">
										<v-list-item-subtitle class="text-subtitle-2"
											>Category</v-list-item-subtitle
										>
										<v-list-item-subtitle class="text-subtitle-1">{{
											item.category
												| replace("camera", "Camera")
												| replace("lens", "Lens")
												| replace("recorder", "Recorder / Monitor")
												| replace("media", "Media")
												| replace("tripod", "Tripod/Gimbal/Jib")
												| replace("light", "Light")
												| replace("switcher", "Switcher")
												| replace("video-cable", "Video cable")
												| replace("audio-cable", "Audio cable")
												| replace("lan-cable", "LAN cable")
												| replace("power-cable", "Power cable")
												| replace("other-cable", "Cable (Other)")
												| replace("labo", "Labo")
										}}</v-list-item-subtitle>
									</div>
									<div class="py-1"></div>
									<div class="mobileList">
										<v-list-item-subtitle class="text-subtitle-2"
											>Stock</v-list-item-subtitle
										>
										<v-list-item-subtitle
											class="text-subtitle-1"
											v-text="item.stock"
										></v-list-item-subtitle>
									</div>
								</v-col>
								<v-col>
									<v-dialog
										v-model="dialog_complete[item.model]"
										:retain-focus="false"
										max-width="600"
										content-class="updateDialog"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												outlined
												color="#262626"
												style="text-transform: none"
												small
												block
												class="rounded-0"
												@click="clickRowInfo(item)"
												v-bind="attrs"
												v-on="on"
												>Info</v-btn
											>
										</template>
										<v-form ref="form" v-model="vForm">
											<v-card>
												<v-card-title class="text-h5 titleColor">
													機材の内容を変更する
												</v-card-title>
												<v-card-text>
													<v-text-field
														v-model="item.model"
														label="機材名（入力必須）"
														prepend-icon="mdi-alphabetical"
														hint="変更する場合は管理者へお問い合わせください"
														persistent-hint
														readonly
													></v-text-field>
												</v-card-text>
												<v-card-text>
													<v-text-field
														v-model="item.manufacturer"
														label="メーカー名"
														prepend-icon="mdi-alphabetical"
													></v-text-field>
												</v-card-text>
												<v-card-text>
													<v-text-field
														v-model="item.model_number"
														label="モデル番号"
														prepend-icon="mdi-numeric"
													></v-text-field>
												</v-card-text>
												<v-card-text>
													<v-text-field
														v-model="item.stock"
														label="個数（入力必須）"
														type="number"
														min="1"
														prepend-icon="mdi-numeric"
														:rules="[(v) => !!v || '必ず入力してください']"
														required
														onkeypress="return event.charCode >= 48"
														onkeydown="return event.keyCode !== 69"
													></v-text-field>
												</v-card-text>
												<v-card-text>
													<v-select
														v-model="categorycache"
														:items="dbCategoryOutput"
														label="カテゴリー"
														item-text="name"
														item-value="value"
														prepend-icon="mdi-folder-multiple-outline"
														@change="changeCategoryValue"
													></v-select>
												</v-card-text>
												<v-card-text>
													<v-text-field
														v-model="item.size"
														label="ケーブル長（メートル換算）"
														type="number"
														step="0.1"
														prepend-icon="mdi-tape-measure"
														hint="必ずメートル（m）換算で入力してください"
														onkeypress="return event.charCode >= 48"
														onkeydown="return event.keyCode !== 69"
													></v-text-field>
												</v-card-text>
												<v-card-text>
													<v-select
														v-model="item.location_repo"
														:items="dbLocationRepoOutput"
														label="保管場所"
														item-text="name"
														item-value="value"
														required
														prepend-icon="mdi-folder-marker-outline"
													></v-select>
												</v-card-text>
												<v-card-text>
													<v-select
														v-model="item.location_box"
														:items="dbLocationBoxOutput"
														label="棚区画"
														item-text="name"
														item-value="value"
														required
														prepend-icon="mdi-folder-marker-outline"
													></v-select>
												</v-card-text>
												<v-card flat class="ma-6">
													<v-form v-on:submit.prevent="addItem">
														<v-text-field
															type="text"
															v-model="text"
															label="付属品"
															v-on:keyup.enter="changeItems"
															ref="editor"
															prepend-icon="mdi-clipboard-list-outline"
														>
															<template v-slot:append-outer>
																<v-btn
																	outlined
																	color="#262626"
																	style="text-transform: none"
																	elevation="0"
																	v-on:click="setItems"
																	type="submit"
																	class="rounded-0"
																>
																	付属品を追加
																</v-btn>
															</template>
														</v-text-field>
													</v-form>

													<!--ループ処理-->
													<v-simple-table>
														<template v-slot:default>
															<thead>
																<tr>
																	<th class="text-left">付属品の名称</th>
																	<th class="text-left">編集</th>
																	<th class="text-left">削除</th>
																</tr>
															</thead>
															<tbody>
																<tr
																	v-for="(item, index) in descriptionItem"
																	v-bind:key="index"
																>
																	<td>{{ item }}</td>
																	<td>
																		<v-btn
																			outlined
																			color="#262626"
																			style="text-transform: none"
																			v-on:click="edit(index)"
																			class="rounded-0"
																			>編集</v-btn
																		>
																	</td>
																	<td>
																		<v-btn
																			outlined
																			color="#262626"
																			style="text-transform: none"
																			v-on:click="remove(index)"
																			class="rounded-0"
																			>削除</v-btn
																		>
																	</td>
																</tr>
															</tbody>
														</template>
													</v-simple-table>
												</v-card>
												<v-divider></v-divider>
												<v-row class="ma-2">
													<v-col cols="12" md="4" lg="4" xl="4">
														<div v-show="!imageDelete">
															<v-card elevation="0" flat tile>
																<div v-show="!imageRender">
																	<v-img
																		v-bind:src="item.item_thumbnail_0"
																		contain
																		class="white--text align-center"
																		gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
																		:height="gridImageHeight"
																		><v-card-title class="justify-center"
																			><h3>本体</h3></v-card-title
																		></v-img
																	>
																</div>
																<div v-show="imageRender">
																	<v-img :src="imagelocal"></v-img>
																</div>
															</v-card>
															<v-file-input
																label="機材のメイン画像を追加"
																prepend-icon="mdi-camera"
																type="file"
																ref="fileupload"
																accept="image/png, image/jpeg, image/bmp"
																@change="onFilePicked"
																@click:clear="clearFile"
																chips
															></v-file-input>
														</div>
														<div v-show="imageDelete"></div>
														<div v-if="!imageDelete">
															<v-btn
																outlined
																color="red darken-4"
																block
																@click="imageDelete = true"
																class="rounded-0"
																>画像を削除</v-btn
															>
														</div>
														<div v-if="imageDelete">
															<v-btn
																outlined
																color="blue darken-4"
																block
																@click="imageDelete = false"
																class="rounded-0"
																>削除をキャンセル</v-btn
															>
														</div>
													</v-col>

													<v-col cols="12" md="4" lg="4" xl="4">
														<div v-show="!imageDelete1">
															<v-card elevation="0" flat tile>
																<div v-show="!imageRender1">
																	<v-img
																		v-bind:src="item.item_thumbnail_1"
																		contain
																		class="white--text align-center"
																		gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
																		:height="gridImageHeight"
																		><v-card-title class="justify-center"
																			><h3>付属品</h3></v-card-title
																		></v-img
																	>
																</div>
																<div v-show="imageRender1">
																	<v-img :src="imagelocal1"></v-img>
																</div>
															</v-card>
															<v-file-input
																label="付属品の画像を追加"
																prepend-icon="mdi-camera"
																type="file"
																ref="fileupload1"
																accept="image/png, image/jpeg, image/bmp"
																@change="onFilePicked1"
																@click:clear="clearFile1"
																chips
															></v-file-input>
														</div>
														<div v-show="imageDelete1"></div>
														<div v-if="!imageDelete1">
															<v-btn
																outlined
																color="red darken-4"
																block
																@click="imageDelete1 = true"
																class="rounded-0"
																>画像を削除</v-btn
															>
														</div>
														<div v-if="imageDelete1">
															<v-btn
																outlined
																color="blue darken-4"
																block
																@click="imageDelete1 = false"
																class="rounded-0"
																>削除をキャンセル</v-btn
															>
														</div>
													</v-col>

													<v-col cols="12" md="4" lg="4" xl="4">
														<div v-show="!imageDelete2">
															<v-card elevation="0" flat tile>
																<div v-show="!imageRender2">
																	<v-img
																		v-bind:src="item.item_thumbnail_2"
																		contain
																		class="white--text align-center"
																		gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
																		:height="gridImageHeight"
																		><v-card-title class="justify-center"
																			><h3>収納方法</h3></v-card-title
																		></v-img
																	>
																</div>
																<div v-show="imageRender2">
																	<v-img :src="imagelocal2"></v-img>
																</div>
															</v-card>
															<v-file-input
																label="収納方法の画像を追加"
																prepend-icon="mdi-camera"
																type="file"
																ref="fileupload2"
																accept="image/png, image/jpeg, image/bmp"
																@change="onFilePicked2"
																@click:clear="clearFile2"
																chips
															></v-file-input>
														</div>
														<div v-show="imageDelete2"></div>
														<div v-if="!imageDelete2">
															<v-btn
																outlined
																color="red darken-4"
																block
																@click="imageDelete2 = true"
																class="rounded-0"
																>画像を削除</v-btn
															>
														</div>
														<div v-if="imageDelete2">
															<v-btn
																outlined
																color="blue darken-4"
																block
																@click="imageDelete2 = false"
																class="rounded-0"
																>削除をキャンセル</v-btn
															>
														</div>
													</v-col>
												</v-row>
												<v-divider></v-divider>
												<v-card-text>
													<v-text-field
														v-model="item.rentalfee"
														label="レンタル費"
														type="number"
														prepend-icon="mdi-currency-jpy"
														onkeypress="return event.charCode >= 48"
														onkeydown="return event.keyCode !== 69"
													></v-text-field>
												</v-card-text>
												<v-card-actions>
													<v-spacer></v-spacer>
													<v-btn
														outlined
														color="#262626"
														style="text-transform: none"
														@click="clickRowUpdate(item)"
														:disabled="!vForm"
														class="rounded-0"
													>
														変更
													</v-btn>
													<v-btn
														outlined
														color="red darken-4"
														style="text-transform: none"
														@click="clickRowDelete(item)"
														class="mr-5 rounded-0"
														>削除</v-btn
													>
												</v-card-actions>
											</v-card>
										</v-form>
									</v-dialog>
								</v-col>
							</v-list-item-content>
						</v-row>
					</v-list-item>
				</v-list-item-group>
				<v-data-table
					v-if="$vuetify.breakpoint.mdAndUp"
					:headers="shownHeaders"
					:items="dbitemsSort_ab"
					:items-per-page="30"
					item-key="id"
					class="elevation-0"
					:search="search"
					:footer-props="{ 'items-per-page-text': '' }"
				>
					<template v-slot:item.category="{ item }">
						{{
							item.category
								| replace("camera", "Camera")
								| replace("lens", "Lens")
								| replace("recorder", "Recorder / Monitor")
								| replace("media", "Media")
								| replace("tripod", "Tripod/Gimbal/Jib")
								| replace("light", "Light")
								| replace("switcher", "Switcher")
								| replace("video-cable", "Video cable")
								| replace("audio-cable", "Audio cable")
								| replace("lan-cable", "LAN cable")
								| replace("power-cable", "Power cable")
								| replace("other-cable", "Cable (Other)")
								| replace("labo", "Labo")
						}}
					</template>
					<template v-slot:item.location_repo="{ item }">
						{{
							item.location_repo
								| replace("tech_room", "Tech Room")
								| replace("stock_room", "Stock Room")
						}}
					</template>
					<template v-slot:item.location_box="{ item }">
						{{
							item.location_box
								| replace("a1light", "A-1-Light")
								| replace("a1camera", "A-1-Camera")
								| replace("a1lens", "A-1-Lens")
								| replace("a2camera", "A-2-Camera")
								| replace("a3case", "A-3-Case")
								| replace("a41", "A-4-1")
								| replace("a42", "A-4-2")
								| replace("a43", "A-4-3")
								| replace("b1jib", "B-1-Jib")
								| replace("b1light", "B-1-Light")
								| replace("b2sdi", "B-2-SDI")
								| replace("b24", "B-2-4")
								| replace("b25", "B-2-5")
								| replace("b31", "B-3-1")
								| replace("b32", "B-3-2")
								| replace("b33", "B-3-3")
								| replace("b34", "B-3-4")
								| replace("b35", "B-3-5")
								| replace("c1display", "C-1-Display")
								| replace("c21", "C-2-1")
								| replace("c22", "C-2-2")
								| replace("c23", "C-2-3")
								| replace("c24", "C-2-4")
								| replace("c25", "C-2-5")
								| replace("c31", "C-3-1")
								| replace("c32", "C-3-2")
								| replace("c33", "C-3-3")
								| replace("c34", "C-3-4")
								| replace("c35", "C-3-5")
						}}
					</template>
					<template v-slot:item.status="{ item }">
						{{ item.status | replace("ok", "OK") }}
					</template>
					<template v-slot:item.infomation="{ item }">
						<v-dialog
							v-model="dialog_complete[item]"
							:retain-focus="false"
							max-width="600"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									outlined
									color="#262626"
									style="text-transform: none"
									small
									@click="clickRowInfo(item)"
									v-bind="attrs"
									v-on="on"
									class="rounded-0"
									>Info</v-btn
								>
							</template>
							<v-form ref="form" v-model="vForm">
								<v-card>
									<v-card-title class="text-h5 titleColor">
										機材の内容を変更する
									</v-card-title>
									<v-card-text>
										<v-text-field
											v-model="item.model"
											label="機材名（入力必須）"
											prepend-icon="mdi-alphabetical"
											hint="変更する場合は管理者へお問い合わせください"
											persistent-hint
											readonly
										></v-text-field>
									</v-card-text>
									<v-card-text>
										<v-text-field
											v-model="item.manufacturer"
											label="メーカー名"
											prepend-icon="mdi-alphabetical"
										></v-text-field>
									</v-card-text>
									<v-card-text>
										<v-text-field
											v-model="item.model_number"
											label="モデル番号"
											prepend-icon="mdi-numeric"
										></v-text-field>
									</v-card-text>
									<v-card-text>
										<v-text-field
											v-model="item.stock"
											label="個数（入力必須）"
											type="number"
											min="1"
											prepend-icon="mdi-numeric"
											:rules="[(v) => !!v || '必ず入力してください']"
											required
											onkeypress="return event.charCode >= 48"
											onkeydown="return event.keyCode !== 69"
										></v-text-field>
									</v-card-text>
									<v-card-text>
										<v-select
											v-model="categorycache"
											:items="dbCategoryOutput"
											label="カテゴリー"
											item-text="name"
											item-value="value"
											prepend-icon="mdi-folder-multiple-outline"
											@change="changeCategoryValue(item)"
										></v-select>
									</v-card-text>
									<v-card-text v-if="cableHide">
										<v-text-field
											v-model="item.size"
											label="ケーブル長（メートル換算）"
											type="number"
											step="0.1"
											prepend-icon="mdi-tape-measure"
											hint="必ずメートル（m）換算で入力してください"
											onkeypress="return event.charCode >= 48"
											onkeydown="return event.keyCode !== 69"
										></v-text-field>
									</v-card-text>
									<v-card-text>
										<v-select
											v-model="item.location_repo"
											:items="dbLocationRepoOutput"
											label="保管場所"
											item-text="name"
											item-value="value"
											required
											prepend-icon="mdi-folder-marker-outline"
										></v-select>
									</v-card-text>
									<v-card-text>
										<v-select
											v-model="item.location_box"
											:items="dbLocationBoxOutput"
											label="棚区画"
											item-text="name"
											item-value="value"
											required
											prepend-icon="mdi-folder-marker-outline"
										></v-select>
									</v-card-text>
									<v-card flat class="ma-6">
										<v-form v-on:submit.prevent="addItem">
											<v-text-field
												type="text"
												v-model="text"
												label="付属品"
												v-on:keyup.enter="changeItems"
												ref="editor"
												prepend-icon="mdi-clipboard-list-outline"
											>
												<template v-slot:append-outer>
													<v-btn
														outlined
														color="#262626"
														style="text-transform: none"
														elevation="0"
														v-on:click="setItems"
														type="submit"
														class="rounded-0"
													>
														付属品を追加
													</v-btn>
												</template>
											</v-text-field>
										</v-form>

										<!--ループ処理-->
										<v-simple-table>
											<template v-slot:default>
												<thead>
													<tr>
														<th class="text-left">付属品の名称</th>
														<th class="text-left">編集</th>
														<th class="text-left">削除</th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="(item, index) in descriptionItem"
														v-bind:key="index"
													>
														<td>{{ item }}</td>
														<td>
															<v-btn
																outlined
																color="#262626"
																style="text-transform: none"
																v-on:click="edit(index)"
																class="rounded-0"
																>編集</v-btn
															>
														</td>
														<td>
															<v-btn
																outlined
																color="#262626"
																style="text-transform: none"
																v-on:click="remove(index)"
																class="rounded-0"
																>削除</v-btn
															>
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
									<v-divider></v-divider>
									<v-row class="ma-2">
										<v-col cols="6" md="4">
											<div v-show="!imageDelete">
												<v-card elevation="0" flat tile>
													<div v-show="!imageRender">
														<v-img
															v-bind:src="item.item_thumbnail_0"
															contain
															class="white--text align-center"
															gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
															><v-card-title class="justify-center"
																><h3>本体</h3></v-card-title
															></v-img
														>
													</div>
													<div v-show="imageRender">
														<v-img :src="imagelocal"></v-img>
													</div>
												</v-card>
												<v-file-input
													label="機材のメイン画像を追加"
													prepend-icon="mdi-camera"
													type="file"
													ref="fileupload"
													accept="image/png, image/jpeg, image/bmp"
													@change="onFilePicked"
													@click:clear="clearFile"
													chips
												></v-file-input>
											</div>
											<div v-show="imageDelete"></div>
											<div v-if="!imageDelete">
												<v-btn
													outlined
													color="red darken-4"
													block
													@click="imageDelete = true"
													class="rounded-0"
													>画像を削除</v-btn
												>
											</div>
											<div v-if="imageDelete">
												<v-btn
													outlined
													color="blue darken-4"
													block
													@click="imageDelete = false"
													class="rounded-0"
													>削除をキャンセル</v-btn
												>
											</div>
										</v-col>

										<v-col cols="6" md="4">
											<div v-show="!imageDelete1">
												<v-card elevation="0" flat tile>
													<div v-show="!imageRender1">
														<v-img
															v-bind:src="item.item_thumbnail_1"
															contain
															class="white--text align-center"
															gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
															><v-card-title class="justify-center"
																><h3>付属品</h3></v-card-title
															></v-img
														>
													</div>
													<div v-show="imageRender1">
														<v-img :src="imagelocal1"></v-img>
													</div>
												</v-card>
												<v-file-input
													label="付属品の画像を追加"
													prepend-icon="mdi-camera"
													type="file"
													ref="fileupload1"
													accept="image/png, image/jpeg, image/bmp"
													@change="onFilePicked1"
													@click:clear="clearFile1"
													chips
												></v-file-input>
											</div>
											<div v-show="imageDelete1"></div>
											<div v-if="!imageDelete1">
												<v-btn
													outlined
													color="red darken-4"
													block
													@click="imageDelete1 = true"
													class="rounded-0"
													>画像を削除</v-btn
												>
											</div>
											<div v-if="imageDelete1">
												<v-btn
													outlined
													color="blue darken-4"
													block
													@click="imageDelete1 = false"
													class="rounded-0"
													>削除をキャンセル</v-btn
												>
											</div>
										</v-col>

										<v-col cols="6" md="4">
											<div v-show="!imageDelete2">
												<v-card elevation="0" flat tile>
													<div v-show="!imageRender2">
														<v-img
															v-bind:src="item.item_thumbnail_2"
															contain
															class="white--text align-center"
															gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
															><v-card-title class="justify-center"
																><h3>収納方法</h3></v-card-title
															></v-img
														>
													</div>
													<div v-show="imageRender2">
														<v-img :src="imagelocal2"></v-img>
													</div>
												</v-card>
												<v-file-input
													label="収納方法の画像を追加"
													prepend-icon="mdi-camera"
													type="file"
													ref="fileupload2"
													accept="image/png, image/jpeg, image/bmp"
													@change="onFilePicked2"
													@click:clear="clearFile2"
													chips
												></v-file-input>
											</div>
											<div v-show="imageDelete2"></div>
											<div v-if="!imageDelete2">
												<v-btn
													outlined
													color="red darken-4"
													block
													@click="imageDelete2 = true"
													class="rounded-0"
													>画像を削除</v-btn
												>
											</div>
											<div v-if="imageDelete2">
												<v-btn
													outlined
													color="blue darken-4"
													block
													@click="imageDelete2 = false"
													class="rounded-0"
													>削除をキャンセル</v-btn
												>
											</div>
										</v-col>
									</v-row>
									<v-divider></v-divider>
									<v-card-text>
										<v-text-field
											v-model="item.rentalfee"
											label="レンタル費"
											type="number"
											prepend-icon="mdi-currency-jpy"
											onkeypress="return event.charCode >= 48"
											onkeydown="return event.keyCode !== 69"
										></v-text-field>
									</v-card-text>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn
											outlined
											color="#262626"
											style="text-transform: none"
											@click="clickRowUpdate(item)"
											:disabled="!vForm"
											class="rounded-0"
										>
											変更
										</v-btn>
										<v-btn
											outlined
											color="red darken-4"
											style="text-transform: none"
											@click="clickRowDelete(item)"
											class="mr-5 rounded-0"
											>削除</v-btn
										>
									</v-card-actions>
								</v-card>
							</v-form>
						</v-dialog>
					</template>
					<template v-slot:top>
						<!-- v-container, v-col and v-row are just for decoration purposes. -->
						<v-container fluid>
							<v-row>
								<v-col cols="2" class="pl-6">
									<v-switch
										v-model="qrSearchMode"
										color="#262626"
										inset
										label="出力済を除く"
										@click="qrSearchModeFunc"
									></v-switch>
								</v-col>
								<v-col cols="2" class="pl-6">
									<v-switch
										v-model="qrAccessoriesMode"
										color="#262626"
										inset
										label="付属品リスト出力"
									></v-switch>
								</v-col>
								<v-col cols="6">
									<template v-slot:prepend-item>
										<v-list-item>
											<v-list-item-content>
												<v-text-field
													v-model="searchTerm"
													placeholder="Search"
													@input="searchFruits"
												></v-text-field>
											</v-list-item-content>
										</v-list-item>
										<v-divider class="mt-2"></v-divider>
									</template>

									<v-select
										v-model="selectedFruits"
										:items="dbitemscopy"
										attach
										item-text="model"
										item-value="model"
										label="Select items"
										multiple
										@click="searchSelect"
										@input="searchFruits"
										clearable
									>
										<template v-slot:prepend-item>
											<v-list-item>
												<v-list-item-content>
													<v-text-field
														v-model="searchTerm"
														placeholder="Search"
														@input="searchFruits"
													></v-text-field>
												</v-list-item-content>
											</v-list-item>
											<v-divider class="mt-2"></v-divider>
										</template>
									</v-select>
								</v-col>
								<v-col cols="2" class="pt-6 pr-6 pl-6">
									<v-btn
										@click="qrExport"
										outlined
										block
										color="#262626"
										style="text-transform: none"
										class="rounded-0 pa-2"
									>
										Export QR code data
									</v-btn>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="6">
									<v-row class="pa-6">
										<!-- Filter for dessert name-->
										<v-text-field
											v-model="search"
											label="Search"
											single-line
											hide-details
										></v-text-field>
									</v-row>
								</v-col>

								<v-col cols="6">
									<v-row class="pa-6">
										<!-- Filter for calories -->
										<v-select
											:items="categoryfilterNull"
											v-model="caloriesFilterValue"
											label="Category"
											@change="categoryChangeLabel($event)"
										></v-select>
									</v-row>
								</v-col>
							</v-row>
						</v-container>
					</template>
				</v-data-table>
			</v-card>
		</v-container>
	</div>
</template>

<script>
	// import { timingSafeEqual } from "crypto";
	import firebase from "firebase";
	import moment from "moment";
	import _ from "lodash";

	export default {
		name: "Update",
		filters: {
			replace: function (st, rep, repWith) {
				const result = st.split(rep).join(repWith);
				return result;
			},
		},

		data: () => ({
			//ADD DESCRIPTION
			text: "", // 編集覧の値
			editIndex: -1, // 現在編集している要素のインデックス ,編集状態でない場合は-1を設定

			todos: [],
			// dbItemsOutput: [],
			//Notification
			Update_Item_Notification: false,
			Update_Item_Notification_Text: "機材の更新が完了しました",

			Update_Item_Notification_err: false,
			Update_Item_Notification_Text_err: "機材の更新に失敗しました",

			Delete_Item_Notification: false,
			Delete_Item_Notification_Text: "機材の削除が完了しました",

			Delete_Item_Notification_err: false,
			Delete_Item_Notification_Text_err: "機材の削除に失敗しました",

			timeout: 1000,

			form: [
				{
					name: "",
					maker: "",
					size: "",
					model_number: "",
					serial: "",
					number: "",
					location_repo: "",
					location_box: "",
					status: "",
					category: "",
					purchase_date: "",
					stock: "",
					rentalfee: "",
				},
			],

			//デーブルデータ用の管理モデル
			dessertFilterValue: "",
			caloriesFilterValue: null,

			search: "",

			dialog_complete: false,

			items: [],
			// descreptionItems: this.descriptionSet,
			descriptionItem: "",

			//FIERBASE IMAGE
			imageName: "",
			imageUrl: "",
			imageFile: "",
			imageLength: "",
			imagelocal: "",
			imageRender: false,
			downloadURL: "",

			//FIERBASE IMAGE
			imageName1: "",
			imageUrl1: "",
			imageFile1: "",
			imageLength1: "",
			imagelocal1: "",
			imageRender1: false,
			downloadURL1: "",

			//FIERBASE IMAGE
			imageName2: "",
			imageUrl2: "",
			imageFile2: "",
			imageLength2: "",
			imagelocal2: "",
			imageRender2: false,
			downloadURL2: "",

			uploadURL: "",
			uploadURL1: "",
			uploadURL2: "",

			imageSelect: false,
			imageSelect1: false,
			imageSelect2: false,

			imageDelete: false,
			imageDelete1: false,
			imageDelete2: false,

			dbitems: [],

			vForm: false,

			// ケーブル長フォーム表示切り替え
			cableHide: false,

			// itemModelCache: "",

			// QR用データベース検索

			searchTerm: "",

			dbitemscopy: [],
			fruitsCopy: [],
			selectedFruits: [],
			searchfilterdItems: [],

			qrSearchMode: false,
			qrAccessoriesMode: false,
			qrResultFull: [],

			categorycache: "",
		}),
		computed: {
			headers() {
				return [
					{
						text: "ID",
						value: "id",
						shown: false,
						align: "start",
						sortable: false,
					},
					{
						text: "Category",
						value: "category",
						shown: true,
						filter: this.filterFilter,
						width: "10%",
					},
					{
						text: "Model",
						value: "model",
						shown: true,
						align: "start",
						sortable: false,
						width: "10%",
					},
					{
						text: "Manufacturer",
						value: "manufacturer",
						shown: true,
						width: "10%",
					},
					{
						text: "Location",
						value: "location_repo",
						shown: true,
						width: "10%",
					},
					{ text: "Box", value: "location_box", shown: true, width: "10%" },
					{ text: "Size", value: "size", shown: true, width: "10%" },
					{
						text: "Modelnumber",
						value: "model_number",
						shown: true,
						width: "10%",
					},
					{ text: "画像", value: "image", shown: false },
					{ text: "シリアル", value: "serial", shown: false },
					{ text: "番号", value: "number", shown: false },
					{ text: "Status", value: "status", shown: true, width: "10%" },
					{ text: "Stock", value: "stock", shown: true, width: "10%" },
					{ text: "Use", value: "rent_number", shown: false },
					{ text: "Back inventory", value: "stock_now", shown: false },
					{ text: "購入日", value: "purchase_date", shown: false },
					{ text: "登録日", value: "registration_date", shown: false },
					{ text: "更新日", value: "update_date", shown: false },
					{ text: "説明", value: "description", shown: false },
					{
						text: "借りる数",
						value: "quantity",
						sortable: false,
						shown: false,
					},
					// { text: "追加", value: "add", sortable: false, shown: true },
					{
						text: "Info",
						value: "infomation",
						sortable: false,
						shown: true,
						width: "10%",
					},
				];
			},

			shownHeaders() {
				return this.headers.filter((h) => h.shown);
			},
			dbItemsOutput() {
				return Object.entries(this.dbitems).map((item) => item[1]);
			},
			dbItemsListFilter() {
				if (
					this.caloriesFilterValue == "All" ||
					this.caloriesFilterValue == null
				) {
					return this.dbitemsSort_ab;
				} else {
					return Object.entries(this.dbitemsSort_ab)
						.map((item) => item[1])
						.filter((item) => item.category === this.caloriesFilterValue);
				}
			},
			dbItemsListSearch() {
				if (this.search == "") return this.dbItemsListFilter;
				return this.dbItemsListFilter.filter((item) => {
					return item.model.toLowerCase().includes(this.search.toLowerCase());
				});
			},
			dbItemsSort() {
				return Object.entries(this.dbItemsOutput)
					.map((item) => item[1])
					.sort((a, b) => {
						// let textA = a.category.toUpperCase();
						// let textB = b.category.toUpperCase();
						// return textA < textB ? -1 : textA > textB ? 1 : 0;
						//priceの昇順
						if (a.category.toUpperCase() > b.category.toUpperCase()) return 1;
						if (a.category.toUpperCase() < b.category.toUpperCase()) return -1;

						// if (
						// 	this.dbCategoryOutput.indexOf(a.category.toUpperCase()) -
						// 	this.dbCategoryOutput.indexOf(b.category.toUpperCase())
						// )
						// 	return -1;

						//noの降順
						if (a.model.toUpperCase() < b.model.toUpperCase()) return -1;
						if (a.model.toUpperCase() > b.model.toUpperCase()) return 1;
						if (a.size || b.size) {
							if (Number(a.size) < Number(b.size)) return -1;
							if (Number(a.size) > Number(b.size)) return 1;
						}
						return 0;
					});
			},
			dbitemsSort_ab() {
				return _.sortBy(this.dbItemsSort, (i) => {
					return this.dbCategoryOutput_Value.indexOf(i.category);
				});
			},
			dbCategoryOutput() {
				return this.$store.getters.alldbCategory;
			},
			dbCategoryOutput_Value() {
				return Object.entries(this.$store.getters.alldbCategory).map(
					(item) => item[1]["value"]
				);
			},

			dbLocationRepoOutput() {
				return this.$store.getters.alldbLocationRepo;
			},

			dbLocationBoxOutput() {
				return this.$store.getters.alldbLocationBox;
			},

			//CATEGORY FILTER
			categoryfilter() {
				return Object.values(this.dbCategoryOutput)
					.map((item) => item)
					.filter((x, i, self) => self.indexOf(x) === i)
					.filter((v) => v);
			},

			categoryMap() {
				return this.categoryfilter.map((value) => ({
					...{ value },
				}));
			},

			categoryfilterNull() {
				let Arr_1 = [{ name: "All", value: "All" }];
				let Arr_2 = this.categoryfilter;
				let Arr_3 = Arr_1.concat(Arr_2).map((value) => {
					return {
						id: value.id,
						text: value.name,
						value: value.value,
						category: value.category,
					};
				});
				return Arr_3;
			},

			//LOCATION REPO FILTER
			locationRepofilter() {
				return this.dbItemsOutput
					.map((item) => item.location_repo)
					.filter((x, i, self) => self.indexOf(x) === i)
					.filter((v) => v);
			},

			//LOCATION BOX FILTER
			locationBoxfilter() {
				return this.dbItemsOutput
					.map((item) => item.location_box)
					.filter((x, i, self) => self.indexOf(x) === i)
					.filter((v) => v);
			},

			//LOCATION BOX FILTER
			statusfilter() {
				return this.dbItemsOutput
					.map((item) => item.status)
					.filter((x, i, self) => self.indexOf(x) === i)
					.filter((v) => v);
			},

			//DESCREPTION FILTER
			descriptionSet() {
				return this.dbItemsOutput.map((item) => item["description"]);
			},

			descriptionfilter() {
				return this.dbItemsOutput.map((item) => item.description);
			},

			onlyNumberKey(evt) {
				// Only ASCII character in that range allowed
				var ASCIICode = evt.which ? evt.which : evt.keyCode;
				if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
				return true;
			},

			gridImageHeight() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 150;
					case "sm":
						return 150;
					case "md":
						return 150;
					case "lg":
						return 250;
					case "xl":
						return 250;
					default:
						return 150;
				}
			},
		},
		mounted() {
			var resultFull = this.dbitems;

			// QR出力条件分岐
			if (this.qrSearchMode) {
				// QR出力済みを弾く場合
				return (this.qrResultFull = resultFull.filter(
					(item) => item.qrcheck === false
				));
			} else if (!this.qrStartMode) {
				return (this.qrResultFull = resultFull);
			}
		},
		created: function () {
			this.$store.dispatch("getdbItems");
			this.$store.dispatch("getdbCategory");
			this.$store.dispatch("getdbLocationRepo");
			this.$store.dispatch("getdbLocationBox");

			const db = firebase.firestore();
			// データ取得
			db.collection("equipments")
				.get()
				.then((querysnapshot) => {
					querysnapshot.forEach((doc) => {
						this.dbitems.push(doc.data());
					});
				})
				.catch((error) => {
					console.log(error);
					// テーブルリセット
					this.dbitems = [];
				});
		},

		methods: {
			// methods: {
			searchSelect() {
				// リストに表示される配列をセット
				// this.dbitemscopy = this.qrResultFull;

				var dbItemsMap = this.qrResultFull.map((item) => {
					return item.model;
				});
				console.log(dbItemsMap);
				this.dbitemscopy = _.sortBy(dbItemsMap);
			},

			inputTest() {
				console.log("test");
			},

			qrSearchModeFunc() {
				var resultFull = this.dbitems;

				// let itemID = items.id;

				// QR出力条件分岐
				if (this.qrSearchMode) {
					// QR出力済みを弾く場合
					this.qrResultFull = resultFull.filter(
						(item) => item.qrcheck === false
					);
					console.log(resultFull);
				} else if (!this.qrStartMode) {
					this.qrResultFull = resultFull;
				}
			},

			// eslint-disable-next-line no-unused-vars
			searchFruits(e) {
				var target = this.selectedFruits;

				this.searchfilterdItems = this.dbitems.filter((x) =>
					target.includes(x.model)
				);

				if (!this.searchTerm) {
					this.dbitemscopy = this.dbitems;
				} else if (this.searchTerm) {
					this.dbitemscopy = this.dbitems.filter((item) => {
						return (
							item.model.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >
							-1
						);
					});
				}
			},
			qrExport() {
				// var d = new Date();
				var db = firebase.firestore();
				var m = moment().format("YYYY-MM-DD-HH-mm-SS");

				var csv = null;
				var line = null;
				var companyValue = "flapper3 Inc.";
				var telValue = "03-5817-4172";

				var resultFull = this.dbitems;
				var resultSearch = this.searchfilterdItems;
				var resultFullqrAccessoriesMode = [];

				// 全ての機材を書き出す場合
				if (
					this.selectedFruits == null ||
					this.selectedFruits == "" ||
					this.selectedFruits.length == 0
				) {
					if (!this.qrAccessoriesMode) {
						csv =
							"\ufeff" +
							'"model"' +
							"," +
							'"qrlink"' +
							"," +
							'"company"' +
							"," +
							'"tel"' +
							"," +
							'""' +
							"\n";
						resultFull.forEach((el) => {
							if (!el["size"]) {
								line =
									el["model"] +
									"," +
									el["qrlink"] +
									"," +
									companyValue +
									"," +
									telValue +
									"," +
									'""' +
									"\n";
								csv += line;
							} else if (el["size"]) {
								line =
									el["model"] +
									" " +
									el["size"] +
									"m" +
									"," +
									el["qrlink"] +
									"," +
									companyValue +
									"," +
									telValue +
									"," +
									'""' +
									"\n";
								csv += line;
							}

							db.collection("equipments")
								.doc(el["id"])
								.update({
									qrcheck: true,
								})
								.then(() => {})
								.catch(() => {});
						});
					} else if (this.qrAccessoriesMode) {
						resultFull.forEach((el) => {
							var dLength = el["description"].length;
							csv =
								"\ufeff" +
								'"model"' +
								"," +
								'"accessories"' +
								"," +
								'"number"' +
								"," +
								'"company"' +
								"," +
								'"tel"' +
								"," +
								'""' +
								"\n";
							for (let i = 0; i < dLength; i++) {
								var d =
									el["model"] +
									"," +
									el["description"][i] +
									"," +
									(i + 1) +
									"/" +
									dLength +
									"," +
									companyValue +
									"," +
									telValue +
									"," +
									'""' +
									"\n";
								resultFullqrAccessoriesMode.push(d);
							}
							csv += resultFullqrAccessoriesMode.join("");
						});
					}
				}

				// 一部の機材を書き出す場合
				else if (this.selectedFruits) {
					if (!this.qrAccessoriesMode) {
						csv =
							"\ufeff" +
							'"model"' +
							"," +
							'"qrlink"' +
							"," +
							'"company"' +
							"," +
							'"tel"' +
							"," +
							'""' +
							"\n";
						resultSearch.forEach((el) => {
							if (!el["size"]) {
								line =
									el["model"] +
									"," +
									el["qrlink"] +
									"," +
									companyValue +
									"," +
									telValue +
									"," +
									'""' +
									"\n";
								csv += line;
							} else if (el["size"]) {
								line =
									el["model"] +
									" " +
									el["size"] +
									"m" +
									"," +
									el["qrlink"] +
									"," +
									companyValue +
									"," +
									telValue +
									"," +
									'""' +
									"\n";
								csv += line;
							}

							db.collection("equipments")
								.doc(el["id"])
								.update({
									qrcheck: true,
								})
								.then(() => {})
								.catch(() => {});
						});
					} else if (this.qrAccessoriesMode) {
						resultSearch.forEach((el) => {
							console.log(resultSearch.length);
							var dLength = el["description"].length;
							console.log(dLength);
							csv =
								"\ufeff" +
								'"model"' +
								"," +
								'"accessories"' +
								"," +
								'"number"' +
								"," +
								'"company"' +
								"," +
								'"tel"' +
								"," +
								'""' +
								"\n";
							for (let i = 0; i < dLength; i++) {
								var d =
									el["model"] +
									"," +
									el["description"][i] +
									"," +
									(i + 1) +
									"/" +
									dLength +
									"," +
									companyValue +
									"," +
									telValue +
									"," +
									'""' +
									"\n";
								resultFullqrAccessoriesMode.push(d);
							}
							csv += resultFullqrAccessoriesMode.join("");
						});
					}
				}

				// ファイル生成および出力
				var removeCSV = csv.substring(0, csv.length - 3);
				let blob = new Blob([removeCSV], { type: "text/csv" });
				let link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				if (!this.qrAccessoriesMode) {
					link.download = m + "_" + "export_QR_LABEL.csv";
				} else if (this.qrAccessoriesMode) {
					link.download = m + "_" + "export_QR_LABEL_Accessories.csv";
				}

				link.click();
			},

			changeCategoryValue(item) {
				if (
					item.category == "video-cable" ||
					item.category == "audio-cable" ||
					item.category == "lan-cable" ||
					item.category == "power-cable" ||
					item.category == "other-cable"
				) {
					this.cableHide = true;
				} else {
					this.cableHide = false;
				}
			},

			// 送信ボタンをクリックしたら以下を実行
			setItems() {
				if (this.editIndex === -1) {
					// 配列に要素を追加
					this.descriptionItem.push(this.text);
				} else {
					// 編集（指定位置から指定した要素数を削除し新しい要素に入れ替え）
					this.descriptionItem.splice(this.editIndex, 1, this.text);
				}
				this.cancel();
			},

			// クリア
			cancel() {
				this.text = ""; //編集覧の状態をクリア
				this.editIndex = -1;
			},

			// 編集（指定した要素を編集状態に設定）
			edit(index) {
				this.editIndex = index;
				this.text = this.descriptionItem[index];
				this.$refs.editor.focus(); // フォーカスを設定
			},

			// 削除
			remove(index) {
				this.descriptionItem.splice(index, 1);
			},

			//テーブルデータを検索
			/**
			 * Filter for dessert names column.
			 * @param value Value to be tested.
			 * @returns {boolean}
			 */
			//テーブルデータの名称を検索
			nameFilter(value) {
				// If this filter has no value we just skip the entire filter.
				if (!this.dessertFilterValue) {
					return true;
				}
				// Check if the current loop value (The dessert name)
				// partially contains the searched word.
				return value
					.toLowerCase()
					.includes(this.dessertFilterValue.toLowerCase());
			},
			/**
			 * Filter for calories column.
			 * @param value Value to be tested.
			 * @returns {boolean}
			 */
			//テーブルデータのカテゴリを検索
			filterFilter(value) {
				// If this filter has no value we just skip the entire filter.
				if (!this.caloriesFilterValue) {
					return true;
				}
				// Check if the current loop value (The calories value)
				// equals to the selected value at the <v-select>.
				if (this.caloriesFilterValue === "All") {
					return true;
				} else if (this.caloriesFilterValue) {
					return value === this.caloriesFilterValue;
				}
				// return value === this.caloriesFilterValue;
			},

			clickRowInfo: function (item) {
				this.descriptionItem = item.description;
				this.categorycache = item.category;
				// this.itemModelCache = item.model;
				// console.log(this.itemModelCache);
			},

			clickRowUpdate: function (item) {
				var db = firebase.firestore();
				var storage = firebase.storage();

				let itemID = item.id;
				// let itemModel = item.model;
				let itemMaker = item.manufacturer;
				let itemSize = item.size;
				let item_Model_number = item.model_number;
				let itemCategory = this.categorycache;
				let item_Location_repo = item.location_repo;
				let item_Location_box = item.location_box;
				let item_Status = item.status;
				let item_Stock = item.stock;
				let item_description = this.descriptionItem;
				let item_rentalfee = item.rentalfee;

				var dt = new Date();
				var y = dt.getFullYear();
				var m = ("00" + (dt.getMonth() + 1)).slice(-2);
				var d = ("00" + dt.getDate()).slice(-2);
				var update_date = y + "-" + m + "-" + d;

				const self = this;

				function asynchronyFunction() {
					return new Promise((resolve) => {
						setTimeout(() => {
							db.collection("equipments")
								.doc(itemID)
								.update({
									// uniqueid: uniqueID,
									// hash: uniqueIDHash,
									// model: itemModel,
									manufacturer: itemMaker,
									size: itemSize,
									model_number: item_Model_number,
									stock: item_Stock,
									category: itemCategory,
									location_repo: item_Location_repo,
									location_box: item_Location_box,
									status: item_Status,
									description: item_description,
									rentalfee: item_rentalfee,
									// image: self.uploadURL,
									// image1: self.uploadURL1,
									// image2: self.uploadURL2,
									update_date: update_date,
								})
								.then(() => {})
								.catch(() => {});
							resolve();
						}, 100);
					})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									if (self.imageSelect == false && self.imageDelete == false) {
										// No Event.
									} else if (
										self.imageSelect == true &&
										self.imageDelete == false
									) {
										storage
											.ref()
											.child(`item_thumbnail/${itemID}/0`)
											.put(self.imageFile)
											.then((snapshot) => {
												snapshot.ref.getDownloadURL().then((downloadURL) => {
													firebase
														.firestore()
														.collection("equipments")
														.doc(itemID)
														.update({
															item_thumbnail_0: downloadURL,
														});
												});
											});
									} else if (
										self.imageSelect == false &&
										self.imageDelete == true
									) {
										storage
											.ref()
											.child(`item_thumbnail/${itemID}/0`)
											.delete()
											.then(() => {
												firebase
													.firestore()
													.collection("equipments")
													.doc(itemID)
													.update({
														item_thumbnail_0: "",
													});
											})
											.catch(() => {});
									}
									resolve();
								}, 100);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									if (
										self.imageSelect1 == false &&
										self.imageDelete1 == false
									) {
										// No Event.
									} else if (
										self.imageSelect1 == true &&
										self.imageDelete1 == false
									) {
										storage
											.ref()
											.child(`item_thumbnail/${itemID}/1`)
											.put(self.imageFile1)
											.then((snapshot) => {
												snapshot.ref.getDownloadURL().then((downloadURL) => {
													firebase
														.firestore()
														.collection("equipments")
														.doc(itemID)
														.update({
															item_thumbnail_1: downloadURL,
														});
												});
											});
									} else if (
										self.imageSelect1 == false &&
										self.imageDelete1 == true
									) {
										storage
											.ref()
											.child(`item_thumbnail/${itemID}/1`)
											.delete()
											.then(() => {
												firebase
													.firestore()
													.collection("equipments")
													.doc(itemID)
													.update({
														item_thumbnail_1: "",
													});
											})
											.catch(() => {});
									}
									resolve();
								}, 100);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									if (
										self.imageSelect2 == false &&
										self.imageDelete2 == false
									) {
										// No Event.
									} else if (
										self.imageSelect2 == true &&
										self.imageDelete2 == false
									) {
										storage
											.ref()
											.child(`item_thumbnail/${itemID}/2`)
											.put(self.imageFile2)
											.then((snapshot) => {
												snapshot.ref.getDownloadURL().then((downloadURL) => {
													firebase
														.firestore()
														.collection("equipments")
														.doc(itemID)
														.update({
															item_thumbnail_2: downloadURL,
														});
												});
											});
									} else if (
										self.imageSelect2 == false &&
										self.imageDelete2 == true
									) {
										storage
											.ref()
											.child(`item_thumbnail/${itemID}/2`)
											.delete()
											.then(() => {
												firebase
													.firestore()
													.collection("equipments")
													.doc(itemID)
													.update({
														item_thumbnail_2: "",
													});
											})
											.catch(() => {});
									}
									resolve();
								}, 100);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									self.Update_Item_Notification = true;
									resolve();
								}, 2000);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									self.$router.go({
										path: self.$router.currentRoute.path,
										force: true,
									});
									resolve();
								}, 2000);
							});
						})
						.catch(() => {
							self.Update_Item_Notification_err = true;
						});
				}
				asynchronyFunction();
			},

			clickRowDelete: function (item) {
				var self = this;
				var db = firebase.firestore();

				let itemID = item.id;

				function asynchronyFunction() {
					return new Promise((resolve) => {
						setTimeout(() => {
							db.collection("equipments").doc(itemID).delete();
							// .then(() => {
							// 	setTimeout(
							// 		this.$router.go({
							// 			path: self.$router.currentRoute.path,
							// 			force: true,
							// 		}),
							// 		5000
							// 	);
							// });
							resolve();
						}, 500);
					})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									self.Delete_Item_Notification = true;
									resolve();
								}, 100);
							});
						})
						.then(() => {
							return new Promise((resolve) => {
								setTimeout(() => {
									self.$router.go({
										path: self.$router.currentRoute.path,
										force: true,
									});
									resolve();
								}, 100);
							});
						})
						.catch(() => {
							self.Delete_Item_Notification_err = true;
						});
				}
				asynchronyFunction();

				// db.collection("equipments")
				// 	.doc(itemModel)
				// 	.delete()
				// 	.then(() => {
				// 		this.Delete_Item_Notification = true;
				// 		setTimeout(
				// 			this.$router.go({
				// 				path: this.$router.currentRoute.path,
				// 				force: true,
				// 			}),
				// 			5000
				// 		);
				// 	})
				// 	.catch(() => {
				// 		this.Delete_Item_Notification_err = true;
				// 	});
			},

			categoryChangeLabel: function () {
				this.categoryfilterNull.null = "All";
			},

			onFilePicked(file) {
				if (file !== undefined) {
					this.imageName = file.name;
					if (this.imageName.lastIndexOf(".") <= 0) {
						return;
					}
					const fr = new FileReader();
					// console.log(this.imagelocal);
					fr.readAsDataURL(file);
					fr.addEventListener("load", () => {
						this.imageUrl = fr.result;
						this.imageFile = file;
						this.imageRender = true;
						this.imageSelect = true;
						this.imagelocal = URL.createObjectURL(this.imageFile);
					});
				} else {
					this.imageName = "";
					this.imageFile = "";
					this.imageUrl = "";
					this.imagelocal = "";
					this.imageSelect = false;
					this.$refs.fileupload.value = null;
				}
			},

			clearFile() {
				this.imagelocal = "";
				this.imageRender = false;
				this.imageSelect = false;
			},

			onFilePicked1(file) {
				if (file !== undefined) {
					this.imageName1 = file.name;
					if (this.imageName1.lastIndexOf(".") <= 0) {
						return;
					}
					const fr = new FileReader();
					fr.readAsDataURL(file);
					fr.addEventListener("load", () => {
						this.imageUrl1 = fr.result;
						this.imageFile1 = file;
						this.imageRender1 = true;
						this.imageSelect1 = true;
						this.imagelocal1 = URL.createObjectURL(this.imageFile1);
					});
				} else {
					this.imageName1 = "";
					this.imageFile1 = "";
					this.imageUrl1 = "";
					this.imagelocal1 = "";
					this.imageSelect1 = false;
					this.$refs.fileupload1.value = null;
				}
			},

			clearFile1() {
				this.imagelocal1 = "";
				this.imageRender1 = false;
				this.imageSelect1 = false;
			},

			onFilePicked2(file) {
				if (file !== undefined) {
					this.imageName2 = file.name;
					if (this.imageName2.lastIndexOf(".") <= 0) {
						return;
					}
					const fr = new FileReader();
					fr.readAsDataURL(file);
					fr.addEventListener("load", () => {
						this.imageUrl2 = fr.result;
						this.imageFile2 = file;
						this.imageRender2 = true;
						this.imageSelect2 = true;
						this.imagelocal2 = URL.createObjectURL(this.imageFile2);
					});
				} else {
					this.imageName2 = "";
					this.imageFile2 = "";
					this.imageUrl2 = "";
					this.imagelocal2 = "";
					this.imageSelect2 = true;
					this.$refs.fileupload2.value = null;
				}
			},

			clearFile2() {
				this.imagelocal2 = "";
				this.imageRender2 = false;
				this.imageSelect2 = false;
			},
		},
	};
</script>
<style scoped>
	.mobileList {
		border-left: 2px solid #262626;
		padding: 2px 8px;
	}

	.mobileListContent {
		align-items: flex-start !important;
	}

	v-card v-card--flat v-sheet theme--light {
		padding: 0px;
	}
	.titleColor {
		background-color: #4c4c4c;
		color: #fff;
	}

	::v-deep .updateDialog {
		margin: 12px !important;
	}
	::v-deep .v-list-item__subtitle {
		white-space: normal;
	}
</style>
